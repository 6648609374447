import React, { useState } from 'react';
import { useFetch, usePost } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../context/ThemeContext';
import { useNavigate, useParams } from 'react-router-dom';
import AdminTopicsView from './AdminTopicsView';
import TaskModelModal from '../components/TaskModelModal';

const AdminTopicsContainer = () => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const { darkMode } = useTheme();
    const { t } = useTranslation();

    // Pobieramy tematy
    const {
        data,
        loading,
        error,
        fetchData: refreshTopics
    } = useFetch('ADMIN_COURSE_TOPICS', [courseId]);

    const { postData, loading: actionLoading, error: actionError } = usePost();

    // Stan dla formularza tematu (dodawanie/edycja)
    const [showTopicForm, setShowTopicForm] = useState(false);
    const [selectedTopicId, setSelectedTopicId] = useState(null);
    const [showModelModal, setShowModelModal] = useState(false);

    // Dodajemy stan dla wybranego tematu do modelu
    const [selectedModelTopicId, setSelectedModelTopicId] = useState(null);

    // Hook do pobierania modelu - teraz z ID
    const {
        data: modelData,
        loading: modelLoading,
        error: modelError
    } = useFetch(selectedModelTopicId ? 'ADMIN_TASK_MODEL' : null, [selectedModelTopicId]);

    const [topicFormData, setTopicFormData] = useState({
        name: '',
        description: '',
        topic_type: 'topic',
        language: '',
        is_visible: true,
        visible_from: '',
        visible_until: '',
        time_limit: '',
        attempts_allowed: 1,
        can_see_results: false
    });

    // Stan dla modala usuwania
    const [showDelete, setShowDelete] = useState(false);

    // Handlers dla modelu
    const handleShowModel = (topicId) => {
        if (!topicId) {
            console.error('Invalid topic ID');
            return;
        }
        console.log('Setting selected model topic ID:', topicId);
        setSelectedModelTopicId(topicId);
        setShowModelModal(true);
    };

    const handleCloseModel = () => {
        setShowModelModal(false);
        setSelectedModelTopicId(null);
    };

    // Handlers dla formularza tematu
    const handleShowTopicForm = (topic = null) => {
        if (topic) {
            setTopicFormData({
                id: topic.id,
                name: topic.name,
                description: topic.description,
                topic_type: topic.topic_type ? topic.topic_type.toLowerCase() : 'topic',
                language: topic.language || '',
                is_visible: topic.is_visible,
                visible_from: topic.visible_from ? topic.visible_from.slice(0, 16) : '',
                visible_until: topic.visible_until ? topic.visible_until.slice(0, 16) : '',
                time_limit: topic.exam_settings?.time_limit || '',
                attempts_allowed: topic.exam_settings?.attempts_allowed || 1,
                can_see_results: topic.exam_settings?.can_see_results || false
            });
            setSelectedTopicId(topic.id);
        } else {
            setTopicFormData({
                id: null,
                name: '',
                description: '',
                topic_type: 'topic',
                language: '',
                is_visible: true,
                visible_from: '',
                visible_until: '',
                time_limit: '',
                attempts_allowed: 1,
                can_see_results: false
            });
            setSelectedTopicId(null);
        }
        setShowTopicForm(true);
    };

    const handleCloseTopicForm = () => {
        setShowTopicForm(false);
        setSelectedTopicId(null);
        setTopicFormData({
            name: '',
            description: '',
            topic_type: 'topic',
            language: '',
            is_visible: true,
            visible_from: '',
            visible_until: '',
            time_limit: '',
            attempts_allowed: 1,
            can_see_results: false
        });
    };

    const handleTopicSubmit = async (event) => {
        event.preventDefault();
        try {
            if (selectedTopicId) {
                await postData(
                    'ADMIN_TOPIC_UPDATE',
                    topicFormData,
                    [selectedTopicId]
                );
            } else {
                // Przygotuj dane do wysyłki
                const submitData = {
                    ...topicFormData,
                    course_id: courseId,
                    visible_from: topicFormData.visible_from ? new Date(topicFormData.visible_from).toISOString() : null,
                    visible_until: topicFormData.visible_until ? new Date(topicFormData.visible_until).toISOString() : null
                };
                delete submitData.topic_type;  // usuń stare pole

                await postData(
                    'ADMIN_TOPIC_ADD',
                    submitData,
                    []
                );
            }
            handleCloseTopicForm();
            refreshTopics();
        } catch (err) {
            console.error('Failed to save topic:', err);
        }
    };

    // Handlers dla usuwania
    const handleShowDelete = (topicId) => {
        setSelectedTopicId(topicId);
        setShowDelete(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await postData('ADMIN_TOPIC_DELETE', null, [selectedTopicId]);
            setShowDelete(false);
            refreshTopics();
        } catch (err) {
            console.error('Failed to delete topic:', err);
        }
    };

    // Handlers dla zmiany kolejności
    const handleMoveUp = async (topicId) => {
        try {
            await postData('ADMIN_TOPIC_MOVE_UP', null, [topicId]);
            refreshTopics();
        } catch (err) {
            console.error('Failed to move topic up:', err);
        }
    };

    const handleMoveDown = async (topicId) => {
        try {
            await postData('ADMIN_TOPIC_MOVE_DOWN', null, [topicId]);
            refreshTopics();
        } catch (err) {
            console.error('Failed to move topic down:', err);
        }
    };

    const topicActions = {
        onAdd: () => handleShowTopicForm(),
        onEdit: (topic) => handleShowTopicForm(topic),
        onDelete: handleShowDelete,
        onMoveUp: handleMoveUp,
        onMoveDown: handleMoveDown,
        onViewTasks: (topicId) => navigate(`/admin/tasks/${topicId}`),
        onViewModel: handleShowModel,
        onBackToCourses: () => navigate('/admin/courses')
    };

    const modalTexts = {
        delete: {
            title: t('confirmDeletion'),
            body: t('areYouSureDeleteTopic'),
            confirm: t('delete'),
            cancel: t('cancel')
        }
    };

    return (
        <>
            <AdminTopicsView
                topics={data?.topics || []}
                loading={loading || actionLoading}
                error={error || actionError}
                darkMode={darkMode}
                actions={topicActions}
                showTopicForm={showTopicForm}
                topicFormData={topicFormData}
                onTopicFormChange={(e) => setTopicFormData({
                    ...topicFormData,
                    [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
                })}
                onTopicFormSubmit={handleTopicSubmit}
                onCloseTopicForm={handleCloseTopicForm}
                showDelete={showDelete}
                onCloseDelete={() => setShowDelete(false)}
                onConfirmDelete={handleConfirmDelete}
                modalTexts={modalTexts}
            />

            <TaskModelModal
                show={showModelModal}
                handleClose={handleCloseModel}
                modelData={modelData}
                darkMode={darkMode}
                loading={modelLoading}
                error={modelError}
            />
        </>
    );
};

export default AdminTopicsContainer;
