import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteModal = ({ show, handleClose, handleConfirm, texts }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{texts.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{texts.body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>{texts.cancel}</Button>
                <Button variant="danger" onClick={handleConfirm}>{texts.confirm}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;