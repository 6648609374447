const config = {
    development: {
        apiUrl: 'http://127.0.0.1:5000/api',
        //apiUrl: 'http://172.16.96.232:5000/api',
    },
    production: {
        apiUrl: 'https://api.bitwizards.pl/api',
    }
};

const environment = process.env.NODE_ENV || 'development';

export default config[environment];
