import React, { useState } from 'react';
import { useFetch, usePost } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import AdminCoursesView from './AdminCoursesView';

const AdminCoursesContainer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { darkMode } = useTheme();

    // Pobieramy kursy używając endpointu admina
    const { data: courses, loading, error, fetchData } = useFetch('ADMIN_COURSES');
    const { postData, loading: actionLoading, error: actionError } = usePost();

    // Stan dla modali
    const [showDelete, setShowDelete] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);

    // Stan formularza
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    // Obsługa formularza
    const handleShowForm = (course = null) => {
        if (course) {
            setFormData({
                name: course.name,
                description: course.description
            });
            setSelectedCourseId(course.id);
            setEditMode(true);
        } else {
            setFormData({ name: '', description: '' });
            setEditMode(false);
        }
        setShowForm(true);
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setEditMode(false);
        setFormData({ name: '', description: '' });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (editMode) {
                await postData('ADMIN_COURSE_UPDATE', formData, [selectedCourseId]);
            } else {
                await postData('ADMIN_COURSE_ADD', formData, []); // Zmiana z null na pustą tablicę
            }
            handleCloseForm();
            fetchData();
        } catch (err) {
            console.error('Failed to save course:', err);
        }
    };

    // Obsługa usuwania
    const handleDelete = async (courseId) => {
        try {
            await postData('ADMIN_COURSE_DELETE', null, [courseId]);
            setShowDelete(false);
            fetchData();
        } catch (err) {
            console.error('Failed to delete course:', err);
        }
    };

    // Obsługa przesuwania
    const handleMove = async (courseId, direction) => {
        try {
            if (direction === 'up') {
                await postData('ADMIN_COURSE_MOVE_UP', null, [courseId]);
            } else if (direction === 'down') {
                await postData('ADMIN_COURSE_MOVE_DOWN', null, [courseId]);
            }
            fetchData();
        } catch (err) {
            console.error(`Failed to move course ${direction}:`, err);
        }
    };

    const courseActions = {
        onAdd: () => handleShowForm(),
        onEdit: (course) => handleShowForm(course),
        onDelete: (courseId) => {
            setSelectedCourseId(courseId);
            setShowDelete(true);
        },
        onMoveUp: (courseId) => handleMove(courseId, 'up'),
        onMoveDown: (courseId) => handleMove(courseId, 'down'),
        onViewTopics: (courseId) => navigate(`/admin/topics/${courseId}`)
    };

    const modalTexts = {
        delete: {
            title: t('confirmDeletion'),
            body: t('areYouSureDeleteCourse'),
            cancel: t('cancel'),
            confirm: t('delete')
        }
    };

    return (
        <AdminCoursesView
            courses={courses}
            loading={loading || actionLoading}
            error={error || actionError}
            actionError={actionError}
            actionLoading={actionLoading}
            darkMode={darkMode}
            actions={courseActions}
            showDelete={showDelete}
            showForm={showForm}
            formData={formData}
            editMode={editMode}
            selectedCourseId={selectedCourseId}
            onCloseDelete={() => setShowDelete(false)}
            onConfirmDelete={() => handleDelete(selectedCourseId)}
            onCloseForm={handleCloseForm}
            onFormSubmit={handleSubmit}
            onFormChange={(e) => setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })}
            modalTexts={modalTexts}
        />
    );
};

export default AdminCoursesContainer;
