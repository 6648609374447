import React from 'react';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CourseForm = ({
                        show,
                        handleClose,
                        formData,
                        handleChange,
                        handleSubmit,
                        error,
                        loading,
                        isEdit,
                        darkMode
                    }) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            className={darkMode ? 'modal-dark' : ''}
        >
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? t('editCourse') : t('addCourse')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {error && <Alert variant="danger">{t(error)}</Alert>}
                    <Form.Group className="mb-3">
                        <Form.Label>{t('courseName')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('courseDescription')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {isEdit ? t('updateCourse') : t('addCourse')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CourseForm;
