import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetch, usePost } from '../hooks';
import ChangePasswordModal from './ChangePasswordModal';

const UserInfo = () => {
    const { t } = useTranslation();
    const { data: profileData, loading, error, fetchData: refreshProfile } = useFetch('PROFILE_GET');
    const { postData, loading: updateLoading, error: updateError } = usePost();

    const [isEditing, setIsEditing] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        surname: ''
    });
    const [fieldErrors, setFieldErrors] = useState({});

    useEffect(() => {
        if (profileData) {
            setFormData({
                email: profileData.email || '',
                name: profileData.name || '',
                surname: profileData.surname || ''
            });
        }
    }, [profileData]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        // Czyszczenie błędu dla pola przy zmianie wartości
        setFieldErrors({
            ...fieldErrors,
            [e.target.name]: null
        });
    };

    const handleSubmit = async () => {
        setFieldErrors({}); // Czyścimy poprzednie błędy

        // Podstawowa walidacja
        const errors = {};
        if (!formData.email?.trim()) {
            errors.email = t('emailRequired');
        }
        if (!formData.name?.trim()) {
            errors.name = t('nameRequired');
        }
        if (!formData.surname?.trim()) {
            errors.surname = t('surnameRequired');
        }

        // Jeśli są błędy walidacji, nie wysyłamy żądania
        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return;
        }

        try {
            const response = await postData('PROFILE_UPDATE', formData);

            if (response) {
                setIsEditing(false);
                refreshProfile();
            }
        } catch (err) {
            console.error('Update error:', err);

            if (err.response?.status === 409) {
                setFieldErrors({
                    email: t('emailAlreadyExists')
                });
            } else if (err.response?.data?.message) {
                setFieldErrors({
                    general: err.response.data.message
                });
            } else {
                setFieldErrors({
                    general: t('updateError')
                });
            }
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setFieldErrors({});
        // Przywracamy oryginalne wartości
        setFormData({
            email: profileData.email || '',
            name: profileData.name || '',
            surname: profileData.surname || ''
        });
    };

    const renderValue = (key, value, editable = true) => {
        if (isEditing && editable) {
            return (
                <>
                    <Form.Control
                        type={key === 'email' ? 'email' : 'text'}
                        name={key}
                        value={formData[key]}
                        onChange={handleChange}
                        isInvalid={!!fieldErrors[key]}
                    />
                    {fieldErrors[key] && (
                        <Form.Text className="text-danger">
                            {fieldErrors[key]}
                        </Form.Text>
                    )}
                </>
            );
        }
        return value;
    };

    if (loading) {
        return <Spinner animation="border" className="d-block mx-auto mt-4" />;
    }

    if (error) {
        return <Alert variant="danger" className="mt-4">{error}</Alert>;
    }

    return (
        <div className="container mt-4">
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <h5>{t('userInformation')}</h5>
                    <div className="d-flex gap-2">
                        {isEditing ? (
                            <>
                                <Button
                                    variant="secondary"
                                    onClick={handleCancel}
                                >
                                    {t('cancel')}
                                </Button>
                                <Button
                                    variant="success"
                                    onClick={handleSubmit}
                                    disabled={updateLoading}
                                >
                                    {t('save')}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="primary"
                                    onClick={() => setIsEditing(true)}
                                >
                                    {t('edit')}
                                </Button>
                                <Button
                                    variant="info"
                                    onClick={() => setShowPasswordModal(true)}
                                >
                                    {t('changePassword')}
                                </Button>
                            </>
                        )}
                    </div>
                </Card.Header>
                <Card.Body>
                    {updateError && <Alert variant="danger">{updateError}</Alert>}
                    {fieldErrors.general && (
                        <Alert variant="danger">{fieldErrors.general}</Alert>
                    )}

                    <Table responsive bordered>
                        <tbody>
                        <tr>
                            <th style={{width: '200px'}}>{t('username')}:</th>
                            <td>{profileData.username}</td>
                        </tr>
                        <tr>
                            <th>{t('role')}:</th>
                            <td>{profileData.role}</td>
                        </tr>
                        <tr>
                            <th>{t('department')}:</th>
                            <td>{profileData.department || t('noDepartment')}</td>
                        </tr>
                        <tr>
                            <th>{t('email')}:</th>
                            <td>
                                {renderValue('email', profileData.email)}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('name')}:</th>
                            <td>{renderValue('name', profileData.name)}</td>
                        </tr>
                        <tr>
                            <th>{t('surname')}:</th>
                            <td>{renderValue('surname', profileData.surname)}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <ChangePasswordModal
                show={showPasswordModal}
                handleClose={() => setShowPasswordModal(false)}
            />
        </div>
    );
};

export default UserInfo;
