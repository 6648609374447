import React, { useState } from 'react';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AdminAssignModal = ({
                              show,
                              handleClose,
                              handleAssign,
                              items,
                              columns,
                              title,
                              multiSelect = true
                          }) => {
    const { t } = useTranslation();
    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelect = (itemId) => {
        if (multiSelect) {
            setSelectedItems(prev =>
                prev.includes(itemId)
                    ? prev.filter(id => id !== itemId)
                    : [...prev, itemId]
            );
        } else {
            setSelectedItems([itemId]);
        }
    };

    const handleSubmit = () => {
        handleAssign(selectedItems);
        setSelectedItems([]);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t(title)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>
                            <Form.Check
                                type="checkbox"
                                onChange={(e) => {
                                    if (multiSelect && e.target.checked) {
                                        setSelectedItems(items.map(item => item.id));
                                    } else {
                                        setSelectedItems([]);
                                    }
                                }}
                                checked={
                                    multiSelect &&
                                    items.length > 0 &&
                                    items.length === selectedItems.length
                                }
                            />
                        </th>
                        {columns.map(column => (
                            <th key={column.key}>{t(column.label)}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(item => (
                        <tr key={item.id}>
                            <td>
                                <Form.Check
                                    type="checkbox"
                                    onChange={() => handleSelect(item.id)}
                                    checked={selectedItems.includes(item.id)}
                                />
                            </td>
                            {columns.map(column => (
                                <td key={column.key}>
                                    {column.render ? column.render(item) : item[column.key]}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={selectedItems.length === 0}
                >
                    {t('assign')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AdminAssignModal;
