import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../context/ThemeContext';

const TeacherExamScheduleModal = ({
                                      show,
                                      handleClose,
                                      examTopics,
                                      selectedExam,
                                      onSubmit
                                  }) => {
    const { t } = useTranslation();
    const { darkMode } = useTheme();

    const [formData, setFormData] = useState({
        topic_instance_id: '',
        start_date: '',
        end_date: '',
        is_active: true,
        can_see_results: false,
        time_limit: null
    });

    useEffect(() => {
        if (selectedExam) {
            setFormData({
                topic_instance_id: selectedExam.topic_instance_id,
                start_date: selectedExam.start_date.slice(0, 16),
                end_date: selectedExam.end_date.slice(0, 16),
                is_active: selectedExam.is_active,
                can_see_results: selectedExam.can_see_results,
                time_limit: selectedExam.time_limit
            });
        } else {
            setFormData({
                topic_instance_id: '',
                start_date: '',
                end_date: '',
                is_active: true,
                can_see_results: false,
                time_limit: null
            });
        }
    }, [selectedExam]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleTopicChange = (e) => {
        const selectedTopicId = e.target.value;
        const selectedTopic = examTopics.find(t => t.id === parseInt(selectedTopicId));

        setFormData(prev => ({
            ...prev,
            topic_instance_id: selectedTopicId,
            time_limit: selectedTopic?.time_limit || null,
            can_see_results: selectedTopic?.can_see_results || false
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const selectedTopic = examTopics.find(t => t.id === parseInt(formData.topic_instance_id));

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton className={darkMode ? 'bg-dark text-light' : ''}>
                <Modal.Title>
                    {selectedExam ? t('editExam') : t('addExam')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={darkMode ? 'bg-dark text-light' : ''}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label column={true}>{t('selectExam')}</Form.Label>
                        <Form.Select
                            name="topic_instance_id"
                            value={formData.topic_instance_id}
                            onChange={handleTopicChange}
                            required
                            disabled={!!selectedExam}
                        >
                            <option value="">{t('selectExam')}</option>
                            {examTopics.map(topic => (
                                <option key={topic.id} value={topic.id}>
                                    {topic.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label column={true}>{t('startDate')}</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="start_date"
                                    value={formData.start_date}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label column={true}>{t('endDate')}</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="end_date"
                                    value={formData.end_date}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group className="mb-3">
                        <Form.Label column={true}>{t('timeLimit')} ({t('minutes')})</Form.Label>
                        <Form.Control
                            type="number"
                            name="time_limit"
                            value={formData.time_limit || ''}
                            onChange={handleChange}
                            min="1"
                            placeholder={t('enterTimeLimit')}
                        />
                        {selectedTopic && (
                            <Form.Text className="text-muted">
                                {t('currentTimeLimit')}: {selectedTopic.time_limit || t('noTimeLimit')}
                            </Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check
                            type="switch"
                            name="is_active"
                            label={t('isActive')}
                            checked={formData.is_active}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check
                            type="switch"
                            name="can_see_results"
                            label={t('canSeeResults')}
                            checked={formData.can_see_results}
                            onChange={handleChange}
                        />
                        {selectedTopic && (
                            <Form.Text className="text-muted">
                                {t('currentCanSeeResults')}: {selectedTopic.can_see_results ? t('yes') : t('no')}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={darkMode ? 'bg-dark text-light' : ''}>
                <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {selectedExam ? t('save') : t('add')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TeacherExamScheduleModal;
