import React, { useState, useEffect } from 'react';
import { useTheme } from '../../../context/ThemeContext';

const TasksCodeOutput = ({
                             output,
                             loading,
                             error,
                             outputMode,
                             runCounter,
                             taskId,
                             showConsole,
                             setConsoleLogCount
                         }) => {
    const [consoleOutput, setConsoleOutput] = useState([]);
    const { darkMode } = useTheme();

    useEffect(() => {
        setConsoleOutput([]);
        setConsoleLogCount(0);
    }, [runCounter, taskId, setConsoleLogCount]);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'console') {
                setConsoleOutput(prev => {
                    const newOutput = [...prev, {
                        level: event.data.level || 'log',
                        content: event.data.content,
                        isTable: event.data.isTable,
                        tableData: event.data.tableData
                    }];
                    setConsoleLogCount(newOutput.length);
                    return newOutput;
                });
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [setConsoleLogCount]);

    const themes = {
        dark: {
            console: {
                background: '#1e1e1e',
                text: '#fff',
                border: '#333',
                headerText: '#888',
                table: {
                    headerBg: '#444',
                    headerText: '#fff',
                    cellBg: '#333',
                    cellText: '#fff',
                    border: '#666'
                },
                logs: {
                    error: '#ff4444',
                    warn: '#ffb86c',
                    info: '#63c5da',
                    log: '#fff'
                }
            },
            pre: {
                background: '#1e1e1e',
                text: '#fff'
            },
            iframe: {
                background: '#1e1e1e'
            }
        },
        light: {
            console: {
                background: '#f8f9fa',
                text: '#212529',
                border: '#dee2e6',
                headerText: '#6c757d',
                table: {
                    headerBg: '#e9ecef',
                    headerText: '#212529',
                    cellBg: '#ffffff',
                    cellText: '#212529',
                    border: '#dee2e6'
                },
                logs: {
                    error: '#dc3545',
                    warn: '#ffc107',
                    info: '#0dcaf0',
                    log: '#212529'
                }
            },
            pre: {
                background: '#ffffff',
                text: '#212529'
            },
            iframe: {
                background: '#ffffff'
            }
        }
    };

    const currentTheme = darkMode ? themes.dark : themes.light;

    const wrappedCode = `
    <!DOCTYPE html>
    <html ${darkMode ? 'data-bs-theme="dark"' : ''}>
    <head>
        <style>
            body {
                background-color: ${currentTheme.iframe.background};
                color: ${currentTheme.pre.text};
                margin: 0;
                padding: 8px;
                transition: background-color 0.3s ease, color 0.3s ease;
            }
        </style>
        <script>
            const originalConsole = {
                log: console.log,
                error: console.error,
                warn: console.warn,
                info: console.info,
                table: console.table
            };

            console.log = function(...args) {
                originalConsole.log.apply(console, args);
                window.parent.postMessage({
                    type: 'console',
                    level: 'log',
                    content: args.map(arg => 
                        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                    ).join(' ')
                }, '*');
            };

            console.error = function(...args) {
                originalConsole.error.apply(console, args);
                window.parent.postMessage({
                    type: 'console',
                    level: 'error',
                    content: args.map(arg => 
                        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                    ).join(' ')
                }, '*');
            };

            console.warn = function(...args) {
                originalConsole.warn.apply(console, args);
                window.parent.postMessage({
                    type: 'console',
                    level: 'warn',
                    content: args.map(arg => 
                        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                    ).join(' ')
                }, '*');
            };

            console.info = function(...args) {
                originalConsole.info.apply(console, args);
                window.parent.postMessage({
                    type: 'console',
                    level: 'info',
                    content: args.map(arg => 
                        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                    ).join(' ')
                }, '*');
            };

            console.table = function(data, columns) {
                originalConsole.table.apply(console, arguments);
                
                let tableData = [];
                let headers = [];
                
                if (Array.isArray(data)) {
                    if (data.length > 0) {
                        if (typeof data[0] === 'object' && data[0] !== null) {
                            headers = columns || Object.keys(data[0]);
                            tableData = data.map(item => {
                                let row = {};
                                headers.forEach(header => {
                                    row[header] = item[header];
                                });
                                return row;
                            });
                        } else {
                            headers = ['(index)', 'Value'];
                            tableData = data.map((item, index) => ({
                                '(index)': index,
                                'Value': item
                            }));
                        }
                    }
                } else if (typeof data === 'object' && data !== null) {
                    headers = ['Key', 'Value'];
                    tableData = Object.entries(data).map(([key, value]) => ({
                        'Key': key,
                        'Value': typeof value === 'object' ? JSON.stringify(value) : value
                    }));
                }

                window.parent.postMessage({
                    type: 'console',
                    level: 'table',
                    isTable: true,
                    tableData: {
                        headers,
                        rows: tableData
                    },
                    content: 'Table Output'
                }, '*');
            };

            window.onerror = function(msg, url, line, col, error) {
                window.parent.postMessage({
                    type: 'console',
                    level: 'error',
                    content: \`Error: \${msg} (line: \${line}, column: \${col})\`
                }, '*');
                return false;
            };

            window.addEventListener('unhandledrejection', function(event) {
                window.parent.postMessage({
                    type: 'console',
                    level: 'error',
                    content: \`Unhandled Promise rejection: \${event.reason}\`
                }, '*');
            });
        </script>
    </head>
    <body>
        ${output}
    </body>
    </html>
    `;

    const renderTable = (tableData) => {
        if (!tableData || !tableData.headers || !tableData.rows) return null;

        return (
            <table style={{
                width: '100%',
                borderCollapse: 'collapse',
                fontFamily: 'Consolas, monospace',
                fontSize: '12px',
                marginTop: '4px'
            }}>
                <thead>
                <tr>
                    {tableData.headers.map((header, index) => (
                        <th key={index} style={{
                            backgroundColor: currentTheme.console.table.headerBg,
                            color: currentTheme.console.table.headerText,
                            padding: '4px 8px',
                            textAlign: 'left',
                            border: `1px solid ${currentTheme.console.table.border}`,
                            transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease'
                        }}>
                            {header}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {tableData.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {tableData.headers.map((header, colIndex) => (
                            <td key={colIndex} style={{
                                padding: '4px 8px',
                                border: `1px solid ${currentTheme.console.table.border}`,
                                backgroundColor: currentTheme.console.table.cellBg,
                                color: currentTheme.console.table.cellText,
                                transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease'
                            }}>
                                {typeof row[header] === 'object'
                                    ? JSON.stringify(row[header])
                                    : String(row[header] ?? '')}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    const renderOutput = () => {
        if (outputMode === 'text') {
            return (
                <pre style={{
                    margin: 0,
                    padding: 10,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    fontFamily: 'monospace',
                    fontSize: '14px',
                    overflow: 'auto',
                    height: '100%',
                    backgroundColor: currentTheme.pre.background,
                    color: currentTheme.pre.text,
                    transition: 'background-color 0.3s ease, color 0.3s ease'
                }}>
                    {output}
                </pre>
            );
        }

        return (
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flex: showConsole ? 3 : 4 }}>
                    <iframe
                        key={runCounter}
                        srcDoc={wrappedCode}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            backgroundColor: currentTheme.iframe.background,
                            transition: 'background-color 0.3s ease'
                        }}
                        title="Code Output"
                    />
                </div>

                <div style={{
                    flex: showConsole ? 1 : 0,
                    width: showConsole ? 'auto' : '0px',
                    overflow: 'hidden',
                    transition: 'all 0.3s ease, background-color 0.3s ease',
                    backgroundColor: currentTheme.console.background,
                    borderLeft: showConsole ? `1px solid ${currentTheme.console.border}` : 'none',
                }}>
                    {showConsole && (
                        <div style={{
                            padding: '10px',
                            fontFamily: 'Consolas, monospace',
                            fontSize: '12px',
                            overflowY: 'auto',
                            height: '100%',
                            color: currentTheme.console.text,
                            transition: 'color 0.3s ease'
                        }}>
                            <div style={{
                                marginBottom: '8px',
                                color: currentTheme.console.headerText,
                                borderBottom: `1px solid ${currentTheme.console.border}`,
                                paddingBottom: '4px',
                                transition: 'color 0.3s ease, border-color 0.3s ease'
                            }}>
                                Console
                            </div>
                            {consoleOutput.map((log, index) => (
                                <div
                                    key={index}
                                    style={{
                                        padding: '4px 0',
                                        borderBottom: `1px solid ${currentTheme.console.border}`,
                                        color: log.level === 'error' ? currentTheme.console.logs.error :
                                            log.level === 'warn' ? currentTheme.console.logs.warn :
                                                log.level === 'info' ? currentTheme.console.logs.info :
                                                    currentTheme.console.logs.log,
                                        fontFamily: 'Consolas, monospace',
                                        transition: 'color 0.3s ease, border-color 0.3s ease'
                                    }}
                                >
                                    {log.isTable ? renderTable(log.tableData) : log.content}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div style={{
            height: '33%',
            overflow: 'hidden',
            backgroundColor: currentTheme.console.background,
            color: currentTheme.console.text,
            transition: 'background-color 0.3s ease, color 0.3s ease'
        }}>
            {loading && <p>Wykonywanie kodu...</p>}
            {error && <p style={{ color: currentTheme.console.logs.error }}>Błąd: {error}</p>}
            {!loading && !error && renderOutput()}
        </div>
    );
};

export default TasksCodeOutput;
