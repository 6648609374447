import React from 'react';
import CourseTable from '../components/CourseTable';

const StudentCoursesView = ({
                                courses,
                                loading,
                                error,
                                darkMode,
                                actions
                            }) => {
    const studentCourses = courses?.filter(course => course.role_access.is_enrolled) || [];

    return (
        <div className="container mt-4">
            <CourseTable
                courses={studentCourses}
                loading={loading}
                error={error}
                darkMode={darkMode}
                userRole="student"
                actions={actions}
                userCourses={studentCourses.map(course => course.id)}
            />
        </div>
    );
};

export default StudentCoursesView;
