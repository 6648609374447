import React from 'react';
import { Container, Form, Button, Card, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ThemeToggle } from '../components/ThemeToggle';

const LoginPageView = ({
                           username,
                           password,
                           onUsernameChange,
                           onPasswordChange,
                           onSubmit,
                           onLanguageChange,
                           authLoading,
                           authError,
                           apiError,
                           apiVersion,
                           darkMode
                       }) => {
    const { t } = useTranslation();

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
            {/* Navigation controls */}
            <div className="position-fixed top-0 end-0 p-3 d-flex gap-2 align-items-center">
                <ThemeToggle />
                <NavDropdown
                    title={t('Language')}
                    id="language-nav-dropdown"
                    className={darkMode ? 'text-light' : ''}
                >
                    <NavDropdown.Item onClick={() => onLanguageChange('pl')}>
                        {t('Polski')}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => onLanguageChange('en')}>
                        {t('English')}
                    </NavDropdown.Item>
                </NavDropdown>
            </div>

            {/* Login Form */}
            <Card
                className="w-100 position-relative"
                style={{
                    maxWidth: "400px",
                    backgroundColor: darkMode ? '#212529' : 'white',
                }}
            >
                <Card.Body className={`${darkMode ? 'text-light' : ''} pb-5`}>
                    <h2 className="text-center mb-4">{t('loginTitle')}</h2>
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicUsername">
                            <Form.Label>{t('username')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('enterUsername')}
                                value={username}
                                onChange={(e) => onUsernameChange(e.target.value)}
                                className={darkMode ? 'bg-dark text-light border-secondary' : ''}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>{t('password')}</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('enterPassword')}
                                value={password}
                                onChange={(e) => onPasswordChange(e.target.value)}
                                className={darkMode ? 'bg-dark text-light border-secondary' : ''}
                            />
                        </Form.Group>
                        <Button
                            variant={darkMode ? "outline-light" : "primary"}
                            type="submit"
                            className={`w-100 ${darkMode ? "btn-dark-custom" : ""}`}
                            disabled={authLoading}
                            style={darkMode ? {
                                backgroundColor: '#212529',
                                borderColor: '#dee2e6',
                                color: '#dee2e6'
                            } : undefined}
                            onMouseEnter={(e) => {
                                if (darkMode) {
                                    e.currentTarget.style.backgroundColor = '#212529';
                                    e.currentTarget.style.borderColor = '#0d6efd';
                                    e.currentTarget.style.color = '#0d6efd';
                                }
                            }}
                            onMouseLeave={(e) => {
                                if (darkMode) {
                                    e.currentTarget.style.backgroundColor = '#212529';
                                    e.currentTarget.style.borderColor = '#dee2e6';
                                    e.currentTarget.style.color = '#dee2e6';
                                }
                            }}
                        >
                            {authLoading ? t('loggingIn') : t('login')}
                        </Button>
                    </Form>

                    {/* Errors */}
                    {(authError || apiError) && (
                        <div className={`mt-3 p-2 rounded ${darkMode ? 'bg-danger bg-opacity-10' : 'bg-danger-subtle'}`}>
                            <p className={`${darkMode ? 'text-danger-emphasis' : 'text-danger'} mb-0`}>
                                {apiError || authError}
                            </p>
                        </div>
                    )}
                </Card.Body>

                {/* API Version */}
                {apiVersion && (
                    <div className="position-absolute bottom-0 end-0 p-2">
                        <small style={{ color: darkMode ? '#6c757d' : '#adb5bd' }}>
                            API v{apiVersion}
                        </small>
                    </div>
                )}
            </Card>
        </Container>
    );
};

export default LoginPageView;
