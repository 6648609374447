import React from 'react';
import { useFetch } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import StudentCoursesView from './StudentCoursesView';

const StudentCoursesContainer = () => {
    const navigate = useNavigate();
    const { darkMode } = useTheme();

    // Pobieramy kursy używając endpointu dla studenta
    const {
        data: courses,
        loading,
        error
    } = useFetch('STUDENT_COURSES');

    const courseActions = {
        onViewTopics: (courseId) => navigate(`/student/topics/${courseId}`)
    };

    return (
        <StudentCoursesView
            courses={courses}
            loading={loading}
            error={error}
            darkMode={darkMode}
            actions={courseActions}
        />
    );
};

export default StudentCoursesContainer;
