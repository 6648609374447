import React from 'react';
import styled from 'styled-components';

const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
`;

const MenuWrapper = styled.div`
    flex: 0 0 auto;
`;

const ContentWrapper = styled.div`
    flex: 1;
    overflow: hidden;
    position: relative;
`;

const PageLayout = ({ menu, children }) => {
    return (
        <LayoutWrapper>
            <MenuWrapper>
                {menu}
            </MenuWrapper>
            <ContentWrapper>
                {children}
            </ContentWrapper>
        </LayoutWrapper>
    );
};

export default PageLayout;
