import { useState, useCallback } from 'react';
import axios from 'axios';
import { buildApiUrl, API_ENDPOINTS } from '../config/endpoints';

const useApiStatus = () => {
    const [apiStatus, setApiStatus] = useState(null);
    const [apiVersion, setApiVersion] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const checkApiStatus = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const url = buildApiUrl(API_ENDPOINTS.STATUS);
            const response = await axios.get(url);
            setApiStatus(response.data.status);
            setApiVersion(response.data.version);
            return response.data.status === 'ok';
        } catch (err) {
            setError('Unable to connect to the server');
            setApiStatus('error');
            return false;
        } finally {
            setLoading(false);
        }
    }, []);

    return { apiStatus, apiVersion, loading, error, checkApiStatus };
};

export default useApiStatus;
