import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import { useSnapshotSender } from '../../../hooks/useSnapshotSender';
import StudentTasksView from './StudentTasksView';

const StudentTasksContainer = () => {
    const { topicId } = useParams();
    const { darkMode } = useTheme();
    const [theme, setTheme] = useState(() => darkMode ? 'monokai' : 'github');
    const [editorContent, setEditorContent] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [outputMode, setOutputMode] = useState('html');
    const [codeOutput, setCodeOutput] = useState('');
    const [runCounter, setRunCounter] = useState(0);
    const [showConsole, setShowConsole] = useState(false);
    const [consoleLogCount, setConsoleLogCount] = useState(0);

    // Hooks do komunikacji z API
    const { data: tasksList, fetchData: refreshTasksList } = useFetch(
        'STUDENT_TOPIC_INSTANCE_TASKS',
        topicId ? [topicId] : undefined
    );

    const {
        data: taskDetails,
        loading: taskLoading,
        error: taskError,
        fetchData: refreshTaskDetails
    } = useFetch(
        selectedTaskId ? 'STUDENT_TASK_INSTANCE_DETAILS' : null,
        selectedTaskId ? [selectedTaskId] : undefined
    );

    const { postData } = usePost();

    useSnapshotSender(selectedTaskId, editorContent, false, taskDetails?.type, false);

    // Obsługa resetu kodu
    const handleResetCode = async () => {
        if (!selectedTaskId) return;

        try {
            const response = await postData(
                'STUDENT_TASK_INSTANCE_ORIGINAL',
                null,
                [selectedTaskId]
            );

            if (response?.code !== undefined) {  // Zmienione z response?.code
                setEditorContent(response.code);
                setCodeOutput('');
                setConsoleLogCount(0);
            }
        } catch (error) {
            console.error('Błąd podczas resetowania kodu:', error);
            setCodeOutput('Błąd: Nie udało się zresetować kodu');
        }
    };

    // Obsługa uruchomienia kodu
    const handleRunCode = async () => {
        setConsoleLogCount(0);

        const isJavaScript = taskDetails?.language === 'javascript';

        try {
            if (isJavaScript) {
                setCodeOutput(editorContent);
                setRunCounter(prev => prev + 1);
            } else {
                const response = await postData('EXECUTE_CODE', {
                    code: editorContent,
                    language: taskDetails?.language
                });

                if (response.output) {
                    setCodeOutput(response.output);
                } else if (response.error) {
                    setCodeOutput(`Błąd: ${response.error}`);
                } else {
                    setCodeOutput('Brak wyniku lub wystąpił nieznany błąd.');
                }
                setRunCounter(prev => prev + 1);
            }
        } catch (err) {
            console.error('Błąd wykonania kodu:', err);
            if (err.response?.data?.error) {
                setCodeOutput(`Błąd: ${err.response.data.error}`);
            } else {
                setCodeOutput(`Wystąpił błąd podczas wykonywania kodu: ${err.message}`);
            }
            setRunCounter(prev => prev + 1);
        }
    };

    // Obsługa zmiany zadania
    const handleTaskSelect = (taskId) => {
        // Jeśli klikamy w to samo zadanie, nie robimy nic
        if (taskId === selectedTaskId) {
            return;
        }
        setSelectedTaskId(taskId);
        setCodeOutput('');
        setConsoleLogCount(0);
    };

    // Efekt zmiany zadania
    React.useEffect(() => {
        if (selectedTaskId) {
            refreshTaskDetails();
        }
    }, [selectedTaskId, refreshTaskDetails]);

    // Efekt ustawiania kodu z zadania - zmodyfikowany
    React.useEffect(() => {
        // Sprawdzamy czy taskDetails istnieje i czy ma właściwość code
        if (taskDetails && 'code' in taskDetails) {
            setEditorContent(taskDetails.code || ''); // Jeśli code jest pusty, ustawiamy pusty string
        }
    }, [taskDetails]);

    // Efekt zmiany motywu
    React.useEffect(() => {
        setTheme(darkMode ? 'monokai' : 'github');
    }, [darkMode]);

    // Czyszczenie stanu przy zmianie tematu
    React.useEffect(() => {
        if (topicId) {
            setSelectedTaskId(null);
            setEditorContent('');
            setCodeOutput('');
            setConsoleLogCount(0);
            refreshTasksList();
        }
    }, [topicId, refreshTasksList]);

    return (
        <StudentTasksView
            tasksList={tasksList}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={handleTaskSelect}
            taskDetails={taskDetails}
            taskLoading={taskLoading}
            taskError={taskError}
            theme={theme}
            setTheme={setTheme}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            outputMode={outputMode}
            setOutputMode={setOutputMode}
            codeOutput={codeOutput}
            onRunCode={handleRunCode}
            onResetCode={handleResetCode}
            runCounter={runCounter}
            showConsole={showConsole}
            setShowConsole={setShowConsole}
            consoleLogCount={consoleLogCount}
            setConsoleLogCount={setConsoleLogCount}
        />
    );
};

export default StudentTasksContainer;
