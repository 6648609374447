import React from 'react';
import TasksContentLayout from '../components/TasksContentLayout';
import ExamTimer from '../../exams/components/ExamTimer';
import styled from 'styled-components';

const NoTaskMessage = styled.p`
    padding: 1rem;
`;

const StudentTaskContent = ({
                                loading,
                                error,
                                task,
                                isExam,
                                examStartTime,
                                examTimeLimit,
                                onExamTimeEnd,
                                onExamEnd
                            }) => {
    // Tworzymy timer niezależnie od tego czy jest wybrane zadanie
    const examTimerComponent = isExam && (
        <ExamTimer
            startTime={examStartTime}
            timeLimit={examTimeLimit}
            onTimeEnd={onExamTimeEnd}
            onExamEnd={onExamEnd}
        />
    );

    // Jeśli nie ma wybranego zadania, pokazujemy timer i wiadomość
    if (!task) {
        return (
            <TasksContentLayout
                examTimer={examTimerComponent}
            >
                <NoTaskMessage>Wybierz zadanie, aby zobaczyć jego treść.</NoTaskMessage>
            </TasksContentLayout>
        );
    }

    // Jeśli jest wybrane zadanie, pokazujemy timer i treść zadania
    return (
        <TasksContentLayout
            title={task.name}
            language={task.language}
            description={task.description}
            content={task.content}
            isEditing={false}
            examTimer={examTimerComponent}
        />
    );
};

export default StudentTaskContent;
