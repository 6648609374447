import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ExamEndModal = ({ show, onClose, onConfirm }) => {
    const { t } = useTranslation();

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('endExamConfirmation')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('endExamConfirmationMessage')}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    {t('endExam')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExamEndModal;
