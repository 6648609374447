import React from 'react';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TopicInstanceForm = ({
                               show,
                               handleClose,
                               formData,
                               handleChange,
                               handleSubmit,
                               error,
                               loading,
                               darkMode,
                               isExam = false // czy temat jest typu exam
                           }) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            className={darkMode ? 'modal-dark' : ''}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('editTopicInstance')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('visibility')}</Form.Label>
                        <Form.Check
                            type="switch"
                            id="visibility-switch"
                            label={t('isVisible')}
                            checked={formData.is_visible}
                            onChange={(e) => handleChange({
                                target: {
                                    name: 'is_visible',
                                    value: e.target.checked
                                }
                            })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t('visibleFrom')}</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            name="visible_from"
                            value={formData.visible_from || ''}
                            onChange={handleChange}
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t('visibleUntil')}</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            name="visible_until"
                            value={formData.visible_until || ''}
                            onChange={handleChange}
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                    </Form.Group>

                    {isExam && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('customTimeLimit')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="custom_time_limit"
                                    value={formData.custom_time_limit || ''}
                                    onChange={handleChange}
                                    placeholder={t('defaultFromTopic')}
                                    className={darkMode ? 'bg-dark text-light' : ''}
                                />
                                <Form.Text className="text-muted">
                                    {t('minutesLeaveEmptyForDefault')}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t('customAttemptsAllowed')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="custom_attempts_allowed"
                                    value={formData.custom_attempts_allowed || ''}
                                    onChange={handleChange}
                                    placeholder={t('defaultFromTopic')}
                                    className={darkMode ? 'bg-dark text-light' : ''}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="switch"
                                    id="can-see-results-switch"
                                    label={t('customCanSeeResults')}
                                    checked={formData.custom_can_see_results}
                                    onChange={(e) => handleChange({
                                        target: {
                                            name: 'custom_can_see_results',
                                            value: e.target.checked
                                        }
                                    })}
                                />
                            </Form.Group>
                        </>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {t('save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TopicInstanceForm;
