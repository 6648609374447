import React, { useState } from 'react';
import { useFetch, usePost } from '../../../hooks';
import { useNavigate } from "react-router-dom";
import TeacherExamScheduleModal from './TeacherExamScheduleModal';
import TeacherExamStudentsModal from './TeacherExamStudentsModal';
import ExamsView from '../components/ExamsView';

const TeacherExamsContainer = () => {
    const { data, loading, error, fetchData } = useFetch('TEACHER_EXAM_SCHEDULES');
    const { data: examTopics, loading: topicsLoading } = useFetch('TEACHER_EXAM_TOPICS');
    const { postData, error: actionError } = usePost();

    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [showStudentsModal, setShowStudentsModal] = useState(false);
    const [selectedExam, setSelectedExam] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterType, setFilterType] = useState('all');
    const navigate = useNavigate();

    const handleShowScheduleModal = (exam = null) => {
        setSelectedExam(exam);
        setShowScheduleModal(true);
    };

    const handleShowStudentsModal = (exam) => {
        setSelectedExam(exam);
        setShowStudentsModal(true);
    };

    const handleSubmit = async (formData) => {
        const endpoint = selectedExam
            ? 'TEACHER_EXAM_SCHEDULE_UPDATE'
            : 'TEACHER_EXAM_SCHEDULE_CREATE';

        try {
            await postData(endpoint, formData, selectedExam ? [selectedExam.id] : []);
            setShowScheduleModal(false);
            fetchData();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCloseStudentsModal = () => {
        setShowStudentsModal(false);
        setSelectedExam(null);
        fetchData(); // Odświeżamy dane po zamknięciu modalu
    };

    const handleViewTasks = (exam) => {
        navigate(`/teacher/tasks/${exam.topic_instance_id}`, {
            state: {
                isExam: true,
                examId: exam.id
            }
        });
    };

    const filteredExams = data?.schedules?.filter(exam => {
        // Najpierw filtruj po wyszukiwaniu
        if (searchQuery) {
            const searchLower = searchQuery.toLowerCase();
            if (!exam.topic_name.toLowerCase().includes(searchLower)) {
                return false;
            }
        }

        const now = new Date();
        const startDate = new Date(exam.start_date);
        const endDate = new Date(exam.end_date);

        // Następnie filtruj po typie
        switch (filterType) {
            case 'current':
                return exam.is_active && startDate <= now && endDate >= now;
            case 'upcoming':
                return exam.is_active && startDate > now;
            case 'past':
                return exam.is_active && endDate < now;
            default:
                return true;
        }
    });

    return (
        <>
            <ExamsView
                loading={loading || topicsLoading}
                error={error || actionError}
                exams={filteredExams || []}
                searchQuery={searchQuery}
                filterType={filterType}
                onSearchChange={setSearchQuery}
                onFilterChange={setFilterType}
                onAddExam={() => handleShowScheduleModal()}
                onEditExam={handleShowScheduleModal}
                onManageStudents={handleShowStudentsModal}
                onViewTasks={handleViewTasks}
                isTeacher={true}
            />

            <TeacherExamScheduleModal
                show={showScheduleModal}
                handleClose={() => setShowScheduleModal(false)}
                examTopics={examTopics?.topics || []}
                selectedExam={selectedExam}
                onSubmit={handleSubmit}
            />

            <TeacherExamStudentsModal
                show={showStudentsModal}
                handleClose={handleCloseStudentsModal}
                examId={selectedExam?.id}
            />
        </>
    );
};

export default TeacherExamsContainer;
