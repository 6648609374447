import { useEffect, useCallback, useRef, useState } from 'react';
import usePost from './usePost';

export const useExamSnapshotSender = (attemptId, taskId, progress, options = {}) => {
    const { postData } = usePost();
    const [lastSentContent, setLastSentContent] = useState(progress);
    const [lastSentTime, setLastSentTime] = useState(0);

    const tabSwitchesRef = useRef(0);
    const windowFocusedRef = useRef(document.hasFocus());
    const visibilityRef = useRef(document.visibilityState);

    const {
        enabled = true
    } = options;

    // Zbieranie danych o zachowaniu użytkownika
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                tabSwitchesRef.current += 1;
            }
            visibilityRef.current = document.visibilityState;
        };

        const handleFocus = () => {
            windowFocusedRef.current = true;
        };

        const handleBlur = () => {
            windowFocusedRef.current = false;
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);

    const sendSnapshot = useCallback(async (force = false) => {
        if (!attemptId || !taskId || !enabled) return;

        const now = new Date();
        const currentTime = now.getTime() / 1000;

        // Wysyłamy tylko jeśli progress się zmienił i minęła sekunda lub wymuszono wysłanie
        //if (force || (progress !== lastSentContent && (currentTime - lastSentTime) >= 1)) {
        if (force || ((currentTime - lastSentTime) >= 1)) {
            try {
                const clientData = {
                    ipAddress: '', // IP jest dodawane po stronie serwera
                    tabSwitches: tabSwitchesRef.current,
                    windowFocused: windowFocusedRef.current,
                    screenSize: `${window.screen.width}x${window.screen.height}`,
                    windowSize: `${window.innerWidth}x${window.innerHeight}`,
                    tabVisibility: visibilityRef.current,
                    userAgent: navigator.userAgent
                };

                await postData('STUDENT_EXAM_SNAPSHOT', {
                    taskId,
                    progress,
                    clientData
                }, [attemptId]);

                setLastSentContent(progress);
                setLastSentTime(currentTime);
            } catch (error) {
                console.error('Error sending exam snapshot:', error);
            }
        }
    }, [attemptId, taskId, progress, enabled, postData, lastSentContent, lastSentTime]);

    // Automatyczne wysyłanie co 100ms (sprawdzanie warunku)
    useEffect(() => {
        if (!enabled) return;

        const interval = setInterval(() => {
            sendSnapshot(false);
        }, 100);

        return () => clearInterval(interval);
    }, [sendSnapshot, enabled]);

    // Wysyłanie przy zamykaniu karty/okna
    useEffect(() => {
        if (!enabled) return;

        const handleBeforeUnload = () => {
            sendSnapshot(true);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [sendSnapshot, enabled]);

    return { sendSnapshot };
};

export default useExamSnapshotSender;
