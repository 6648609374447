import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminDataTable from '../../components/generic/AdminDataTable';
import AdminEntityFormModal from '../../components/generic/AdminEntityFormModal';

const SchoolsView = ({
                         loading,
                         error,
                         deleteError,
                         deleteResponse,
                         data,
                         showDelete,
                         showForm,
                         editMode,
                         formData,
                         handleCloseForm,
                         handleShowForm,
                         handleShowDelete,
                         handleCloseDelete,
                         confirmDelete,
                         handleSubmit,
                         handleChange,
                         deleteModalTexts,
                         sortConfig,
                         onSort,
                         statusOptions
                     }) => {
    const navigate = useNavigate();

    const columns = [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'name', sortable: true },
        { key: 'code', label: 'code', sortable: true },
        { key: 'description', label: 'description', sortable: true },
        { key: 'address', label: 'address', sortable: true },
        { key: 'school_year', label: 'schoolYear', sortable: true },
        { key: 'capacity', label: 'capacity', sortable: true },
        { key: 'phone', label: 'phone', sortable: true },
        { key: 'email', label: 'email', sortable: true },
        { key: 'status', label: 'status', sortable: true },
        { key: 'departments_count', label: 'departmentsCount', sortable: true }
    ];

    const handleViewSchool = (schoolId) => {
        navigate(`/admin/schools/${schoolId}/departments`);
    };

    const actions = [
        { label: 'info', variant: 'info', onClick: (school) => handleViewSchool(school.id) },
        { label: 'edit', variant: 'secondary', onClick: handleShowForm },
        { label: 'delete', variant: 'danger', onClick: (school) => handleShowDelete(school.id) }
    ];

    const headerButtons = [
        { label: 'addSchool', variant: 'primary', onClick: () => handleShowForm() }
    ];

    const formFields = [
        { name: 'name', type: 'text', label: 'schoolName', required: true },
        { name: 'code', type: 'text', label: 'schoolCode', required: true },
        { name: 'description', type: 'text', label: 'schoolDescription' },
        { name: 'address', type: 'text', label: 'schoolAddress' },
        { name: 'school_year', type: 'number', label: 'schoolYear', required: true },
        { name: 'capacity', type: 'number', label: 'capacity' },
        { name: 'phone', type: 'text', label: 'phone' },
        { name: 'email', type: 'email', label: 'email' },
        {
            name: 'status',
            type: 'select',
            label: 'status',
            options: statusOptions
        }
    ];

    return (
        <>
            <AdminDataTable
                loading={loading}
                error={error}
                actionError={deleteError}
                actionResponse={deleteResponse}
                title="schools"
                data={data}
                columns={columns}
                actions={actions}
                headerButtons={headerButtons}
                sortConfig={sortConfig}
                onSort={onSort}
            />

            <AdminEntityFormModal
                show={showForm}
                handleClose={handleCloseForm}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                error={error}
                loading={loading}
                isEdit={editMode}
                title="School"
                fields={formFields}
            />

            <DeleteModal
                show={showDelete}
                handleClose={handleCloseDelete}
                handleConfirm={confirmDelete}
                texts={deleteModalTexts}
            />
        </>
    );
};

export default SchoolsView;
