import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import TeacherTasksView from './TeacherTasksView';

const TeacherTasksContainer = () => {
    const { topicId } = useParams();
    const location = useLocation();
    const { isExam, examId } = location.state || {};
    const { darkMode } = useTheme();
    const [theme, setTheme] = useState(() => darkMode ? 'monokai' : 'github');
    const [editorContent, setEditorContent] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [outputMode, setOutputMode] = useState('html');
    const [codeOutput, setCodeOutput] = useState('');
    const [runCounter, setRunCounter] = useState(0);
    const [showConsole, setShowConsole] = useState(false);
    const [consoleLogCount, setConsoleLogCount] = useState(0);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const { data: tasksList, fetchData: refreshTasksList } = useFetch(
        isExam ? 'TEACHER_EXAM_TASKS' : 'TEACHER_TOPIC_TASKS',
        isExam ? [examId] : (topicId ? [topicId] : undefined)
    );

    const {
        data: taskDetails,
        loading: taskLoading,
        error: taskError,
        fetchData: refreshTaskDetails
    } = useFetch(
        selectedTaskId && !isExam ? 'TEACHER_TASK_DETAILS' : null,
        selectedTaskId && !isExam ? [selectedTaskId] : undefined
    );

    const {
        data: examTaskDetails,
        loading: examTaskLoading,
        error: examTaskError,
        fetchData: refreshExamTaskDetails
    } = useFetch(
        selectedTaskId && isExam && selectedStudent ? 'TEACHER_EXAM_STUDENT_TASK' : null,
        selectedTaskId && isExam && selectedStudent ? [examId, selectedStudent.id, selectedTaskId] : undefined
    );

    const {
        data: examStudentsData,
        loading: examStudentsLoading,
        error: examStudentsError,
        fetchData: refreshExamStudents
    } = useFetch(
        isExam ? 'TEACHER_EXAM_SCHEDULE_STUDENTS' : null,
        isExam ? [examId] : undefined
    );

    const {
        data: courseStudentsData,
        loading: studentsLoading,
        error: studentsError,
        fetchData: refreshStudents
    } = useFetch(
        !isExam && taskDetails?.course_instance_id
            ? 'TEACHER_COURSE_STUDENTS'
            : null,
        !isExam && taskDetails?.course_instance_id
            ? [taskDetails.course_instance_id]
            : undefined
    );

    const { postData } = usePost();

    // Przygotowanie danych o studentach
    const studentData = isExam
        ? examStudentsData
        : (courseStudentsData?.filter(student => student.enrolled) || []);

    const handleStudentSelect = async (student) => {
        if (!student || !student.id) return;

        setSelectedStudent(student);
        setCodeOutput('');
        setConsoleLogCount(0);

        if (!selectedTaskId) return;

        try {
            if (isExam) {
                await refreshExamTaskDetails();
            } else {
                const response = await postData('TEACHER_STUDENT_PROGRESS', null, [selectedTaskId, student.id]);
                if (response?.snapshots?.length > 0) {
                    const lastSnapshot = response.snapshots[response.snapshots.length - 1];
                    setEditorContent(lastSnapshot.progress || '');
                }
            }
        } catch (error) {
            console.error('Error fetching student code:', error);
        }
    };

    const handleResetCode = async () => {
        if (!selectedTaskId) return;

        try {
            const response = await postData(
                'TEACHER_TASK_RESET',
                null,
                [selectedTaskId]
            );

            if (response?.code !== undefined) {
                setEditorContent(response.code);
                setCodeOutput('');
                setConsoleLogCount(0);
            }
        } catch (error) {
            console.error('Błąd podczas resetowania kodu:', error);
            setCodeOutput('Błąd: Nie udało się zresetować kodu');
        }
    };

    const handleRunCode = async () => {
        setConsoleLogCount(0);
        const currentTaskDetails = isExam ? examTaskDetails : taskDetails;
        const isJavaScript = currentTaskDetails?.language === 'javascript';

        try {
            if (isJavaScript) {
                setCodeOutput(editorContent);
                setRunCounter(prev => prev + 1);
            } else {
                const response = await postData('EXECUTE_CODE', {
                    code: editorContent,
                    language: currentTaskDetails?.language
                });

                if (response.output) {
                    setCodeOutput(response.output);
                } else if (response.error) {
                    setCodeOutput(`Błąd: ${response.error}`);
                } else {
                    setCodeOutput('Brak wyniku lub wystąpił nieznany błąd.');
                }
                setRunCounter(prev => prev + 1);
            }
        } catch (err) {
            console.error('Błąd wykonania kodu:', err);
            setCodeOutput(`Wystąpił błąd podczas wykonywania kodu: ${err.message}`);
            setRunCounter(prev => prev + 1);
        }
    };

    const handleTaskSelect = (taskId) => {
        if (taskId === selectedTaskId) return;
        setSelectedTaskId(taskId);
        setCodeOutput('');
        setConsoleLogCount(0);
        setSelectedStudent(null);

        if (isExam) {
            refreshExamStudents();
        }
    };

    React.useEffect(() => {
        if (selectedTaskId) {
            if (isExam && selectedStudent) {
                refreshExamTaskDetails();
            } else if (!isExam) {
                refreshTaskDetails();
            }
        }
    }, [selectedTaskId, isExam, selectedStudent, refreshExamTaskDetails, refreshTaskDetails]);

    React.useEffect(() => {
        if (!isExam && taskDetails?.topic_instance?.course_instance_id) {
            refreshStudents();
        }
    }, [isExam, taskDetails, refreshStudents]);

    React.useEffect(() => {
        if (isExam && examTaskDetails && selectedStudent) {
            setEditorContent(examTaskDetails.code || '');
        } else if (taskDetails) {
            setEditorContent(taskDetails.code || '');
        }
    }, [taskDetails, isExam, examTaskDetails, selectedStudent]);

    React.useEffect(() => {
        setTheme(darkMode ? 'monokai' : 'github');
    }, [darkMode]);

    React.useEffect(() => {
        if (topicId || examId) {
            setSelectedTaskId(null);
            setSelectedStudent(null);
            setEditorContent('');
            setCodeOutput('');
            setConsoleLogCount(0);
            refreshTasksList();
            if (isExam) {
                refreshExamStudents();
            }
        }
    }, [topicId, examId, refreshTasksList, isExam, refreshExamStudents]);

    return (
        <TeacherTasksView
            tasksList={tasksList}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={handleTaskSelect}
            taskDetails={isExam && !selectedStudent
                ? tasksList?.find(task => task.id === selectedTaskId)
                : isExam && selectedStudent
                    ? examTaskDetails
                    : taskDetails}
            taskLoading={isExam ? examTaskLoading : taskLoading}
            taskError={isExam ? examTaskError : taskError}
            theme={theme}
            setTheme={setTheme}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            outputMode={outputMode}
            setOutputMode={setOutputMode}
            codeOutput={codeOutput}
            onRunCode={handleRunCode}
            onResetCode={handleResetCode}
            runCounter={runCounter}
            showConsole={showConsole}
            setShowConsole={setShowConsole}
            consoleLogCount={consoleLogCount}
            setConsoleLogCount={setConsoleLogCount}
            courseStudents={studentData}
            studentsLoading={isExam ? examStudentsLoading : studentsLoading}
            studentsError={isExam ? examStudentsError : studentsError}
            selectedStudent={selectedStudent}
            onStudentSelect={handleStudentSelect}
            isExam={isExam}
            examId={examId}
        />
    );
};

export default TeacherTasksContainer;
