import React, { useState, useEffect } from 'react';
import { useFetch, usePost } from '../../hooks';
import { useTranslation } from 'react-i18next';
import DepartmentsView from './DepartmentsView';

const DepartmentsContainer = () => {
    const { t } = useTranslation();
    const { data, loading, error, fetchData } = useFetch('ADMIN_DEPARTMENTS');
    const { postData, response: deleteResponse, error: deleteError, loading: deleteLoading } = usePost();

    const [showDelete, setShowDelete] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        year: ''
    });

    const handleCloseForm = () => {
        setShowForm(false);
        setEditMode(false);
        setFormData({
            name: '',
            description: '',
            year: ''
        });
    };

    const handleShowForm = (department = null) => {
        if (department) {
            setFormData({
                name: department.name,
                description: department.description,
                year: department.year
            });
            setSelectedDepartmentId(department.id);
            setEditMode(true);
        }
        setShowForm(true);
    };

    const handleShowDelete = (departmentId) => {
        setSelectedDepartmentId(departmentId);
        setShowDelete(true);
    };

    const handleCloseDelete = () => setShowDelete(false);

    const confirmDelete = async () => {
        try {
            await postData('ADMIN_DEPARTMENT_DELETE', null, [selectedDepartmentId]);
            fetchData();
            setShowDelete(false);
        } catch (err) {
            console.error('Failed to delete department:', err);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (editMode) {
                await postData('ADMIN_DEPARTMENT_UPDATE', formData, [selectedDepartmentId]);
            } else {
                await postData('ADMIN_DEPARTMENT_ADD', formData);
            }
            fetchData();
            handleCloseForm();
        } catch (err) {
            console.error('Failed to save department:', err);
        }
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        if (deleteResponse) fetchData();
    }, [deleteResponse, fetchData]);

    const deleteModalTexts = {
        title: t('confirmDeletion'),
        body: t('department_confirmDeletionMessage'),
        cancel: t('cancel'),
        confirm: t('delete'),
    };

    return (
        <DepartmentsView
            loading={loading || deleteLoading}
            error={error}
            deleteError={deleteError}
            deleteResponse={deleteResponse}
            data={data}
            showDelete={showDelete}
            showForm={showForm}
            editMode={editMode}
            formData={formData}
            handleCloseForm={handleCloseForm}
            handleShowForm={handleShowForm}
            handleShowDelete={handleShowDelete}
            handleCloseDelete={handleCloseDelete}
            confirmDelete={confirmDelete}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            deleteModalTexts={deleteModalTexts}
        />
    );
};

export default DepartmentsContainer;
