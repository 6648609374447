import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminDataTable from '../../components/generic/AdminDataTable';
import AdminEntityFormModal from '../../components/generic/AdminEntityFormModal';
import AdminAssignModal from '../../components/generic/AdminAssignModal';

const SchoolDepartmentsView = ({
                                   loading,
                                   error,
                                   actionError,
                                   actionResponse,
                                   schoolName,
                                   departments,
                                   unassignedDepartments,
                                   showDelete,
                                   showForm,
                                   showAssign,
                                   editMode,
                                   formData,
                                   sortConfig,
                                   deleteModalTexts,
                                   onSort,
                                   onShowForm,
                                   onCloseForm,
                                   onShowAssign,
                                   onCloseAssign,
                                   onAssignDepartments,
                                   onShowDelete,
                                   onCloseDelete,
                                   onConfirmDelete,
                                   onSubmit,
                                   onChange,
                                   onNavigateBack
                               }) => {
    const navigate = useNavigate();
    const { schoolId } = useParams(); // Dodajemy pobranie schoolId z parametrów URL

    const columns = [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'name', sortable: true },
        { key: 'description', label: 'description', sortable: true },
        { key: 'year', label: 'year', sortable: true },
        { key: 'users_count', label: 'usersCount', sortable: true }
    ];

    const handleViewDepartment = (departmentId) => {
        // Modyfikujemy URL tak, aby zawierał informację o szkole
        navigate(`/admin/schools/${schoolId}/departments/${departmentId}/users`);
    };

    const actions = [
        { label: 'info', variant: 'info', onClick: (dep) => handleViewDepartment(dep.id) },
        { label: 'edit', variant: 'secondary', onClick: onShowForm },
        { label: 'delete', variant: 'danger', onClick: (dep) => onShowDelete(dep.id) }
    ];

    const headerButtons = [
        { label: 'addDepartment', variant: 'primary', onClick: () => onShowForm() },
        { label: 'assignExisting', variant: 'success', onClick: onShowAssign },
        { label: 'back', variant: 'secondary', onClick: onNavigateBack }
    ];

    const formFields = [
        { name: 'name', type: 'text', label: 'department_name', required: true },
        { name: 'description', type: 'text', label: 'department_description' },
        { name: 'year', type: 'number', label: 'department_year', required: true }
    ];

    const assignColumns = [
        { key: 'name', label: 'name' },
        { key: 'description', label: 'description' },
        { key: 'year', label: 'year' }
    ];

    return (
        <>
            <AdminDataTable
                loading={loading}
                error={error}
                actionError={actionError}
                actionResponse={actionResponse}
                title="schoolDepartments"
                subtitle={schoolName}
                data={departments}
                columns={columns}
                actions={actions}
                headerButtons={headerButtons}
                sortConfig={sortConfig}
                onSort={onSort}
            />

            <AdminEntityFormModal
                show={showForm}
                handleClose={onCloseForm}
                formData={formData}
                handleChange={onChange}
                handleSubmit={onSubmit}
                error={error}
                loading={loading}
                isEdit={editMode}
                title="Department"
                fields={formFields}
            />

            <AdminAssignModal
                show={showAssign}
                handleClose={onCloseAssign}
                handleAssign={onAssignDepartments}
                items={unassignedDepartments}
                columns={assignColumns}
                title="assignDepartmentsToSchool"
                multiSelect={true}
            />

            <DeleteModal
                show={showDelete}
                handleClose={onCloseDelete}
                handleConfirm={onConfirmDelete}
                texts={deleteModalTexts}
            />
        </>
    );
};

export default SchoolDepartmentsView;
