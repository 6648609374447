import React, { useState } from 'react';
import { Modal, Button, Alert, Spinner, Badge } from 'react-bootstrap';
import { useTheme } from '../../../context/ThemeContext';
import { useFetch } from '../../../hooks';

const StudentExamResultsModal = ({ show, handleClose, examId }) => {
    const { darkMode } = useTheme();
    const [currentTaskIndex, setCurrentTaskIndex] = useState(0);

    const { data, loading, error } = useFetch(
        show ? 'STUDENT_EXAM_DETAILED_RESULTS' : null,
        show ? [examId] : undefined
    );

    if (!show) return null;

    const renderGradeInfo = (grade) => {
        if (!grade) return <Badge bg="secondary">Brak oceny</Badge>;

        return (
            <div className="border-start ps-3 mt-3">
                <div className="d-flex align-items-center">
                    <h6 className="mb-0">Ocena zadania: </h6>
                    <Badge bg="primary" className="ms-2">
                        {grade.score.toFixed(1)}
                    </Badge>
                </div>
                {grade.comment && (
                    <div className="mt-2">
                        <strong>Komentarz prowadzącego:</strong>
                        <p className="mb-1">{grade.comment}</p>
                    </div>
                )}
                <small className="text-muted">
                    Ostatnia aktualizacja: {new Date(grade.updated_at).toLocaleString()}
                </small>
            </div>
        );
    };

    const renderModalContent = () => {
        if (loading) {
            return (
                <Modal.Body className="text-center py-5">
                    <Spinner animation="border" />
                </Modal.Body>
            );
        }

        if (error || !data) {
            return (
                <>
                    <Modal.Body>
                        <Alert variant="danger">
                            Nie udało się pobrać wyników egzaminu.
                        </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Zamknij
                        </Button>
                    </Modal.Footer>
                </>
            );
        }

        const { exam_details, tasks_results } = data;
        const currentTask = tasks_results[currentTaskIndex];

        return (
            <>
                <Modal.Body>
                    {/* Ogólne informacje o egzaminie */}
                    <div className="mb-4">
                        <h6>Informacje ogólne</h6>
                        <p className="mb-1">Rozpoczęcie: {new Date(exam_details.start_time).toLocaleString()}</p>
                        <p className="mb-1">Zakończenie: {new Date(exam_details.end_time).toLocaleString()}</p>
                        <div className="d-flex align-items-center mb-1">
                            <span className="me-2">Ocena końcowa:</span>
                            <Badge bg="primary">{exam_details.score ? exam_details.score.toFixed(1) : 'Brak'}</Badge>
                        </div>
                        {exam_details.teacher_comment && (
                            <Alert variant="info" className="mt-2">
                                <strong>Komentarz ogólny prowadzącego:</strong><br/>
                                {exam_details.teacher_comment}
                            </Alert>
                        )}
                    </div>

                    {/* Panel nawigacji między zadaniami */}
                    <div className="border-top pt-3">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="d-flex align-items-center">
                                <h6 className="mb-0 me-3">
                                    Zadanie {currentTaskIndex + 1} z {tasks_results.length}
                                </h6>
                                {currentTask.grade?.score && (
                                    <Badge bg="primary">
                                        Ocena: {currentTask.grade.score.toFixed(1)}
                                    </Badge>
                                )}
                            </div>
                            <div>
                                <Button
                                    variant={darkMode ? "outline-light" : "outline-dark"}
                                    size="sm"
                                    onClick={() => setCurrentTaskIndex(prev => prev - 1)}
                                    disabled={currentTaskIndex === 0}
                                    className="me-2"
                                >
                                    Poprzednie
                                </Button>
                                <Button
                                    variant={darkMode ? "outline-light" : "outline-dark"}
                                    size="sm"
                                    onClick={() => setCurrentTaskIndex(prev => prev + 1)}
                                    disabled={currentTaskIndex === tasks_results.length - 1}
                                >
                                    Następne
                                </Button>
                            </div>
                        </div>

                        {/* Treść zadania */}
                        <div className="mb-3">
                            <h5>{currentTask.task_name}</h5>
                            <div className="mb-3">
                                <strong>Opis zadania:</strong>
                                <p>{currentTask.task_description}</p>
                                <strong>Treść zadania:</strong>
                                <p>{currentTask.task_content}</p>
                            </div>
                        </div>

                        {/* Rozwiązanie studenta */}
                        <div className="mb-3">
                            <strong>Twoje rozwiązanie:</strong>
                            <pre className={`p-3 rounded ${darkMode ? 'bg-dark text-light' : 'bg-light'}`}
                                 style={{maxHeight: '300px', overflow: 'auto'}}>
                                <code>{currentTask.last_solution || 'Brak rozwiązania'}</code>
                            </pre>
                            {currentTask.last_solution_time && (
                                <small className="text-muted d-block mb-3">
                                    Ostatnia modyfikacja: {new Date(currentTask.last_solution_time).toLocaleString()}
                                </small>
                            )}
                        </div>

                        {/* Ocena zadania */}
                        {renderGradeInfo(currentTask.grade)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </>
        );
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            contentClassName={darkMode ? 'bg-dark text-light' : ''}
        >
            <Modal.Header closeButton closeVariant={darkMode ? 'white' : undefined}>
                <Modal.Title>
                    {data?.exam_details?.name ? `${data.exam_details.name} - ` : ''}Wyniki egzaminu
                </Modal.Title>
            </Modal.Header>
            {renderModalContent()}
        </Modal>
    );
};

export default StudentExamResultsModal;
