import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

const StyledDropdownItem = styled(Dropdown.Item)`
    &.not-started {
        color: ${props => props.theme.danger || '#dc3545'};
    }

    &.in-progress {
        color: ${props => props.theme.warning || '#ffc107'};
    }

    &.completed {
        color: ${props => props.theme.success || '#28a745'};
    }
`;

const ExamStudentDropdown = ({ examStudents, selectedStudent, onStudentSelect }) => {
    // Sprawdzamy czy examStudents jest tablicą czy obiektem
    const students = Array.isArray(examStudents) ? examStudents : examStudents?.students || [];
    const availableStudents = Array.isArray(examStudents) ? [] : examStudents?.available_students || [];

    if (!students.length && !availableStudents.length) return null;

    return (
        <Dropdown className="me-2">
            <Dropdown.Toggle variant={selectedStudent ? "primary" : "light"}>
                {selectedStudent ? `${selectedStudent.name}` : 'Lista studentów'}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {selectedStudent && (
                    <>
                        <Dropdown.Item onClick={() => onStudentSelect(null)}>
                            Powrót do zadania
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </>
                )}
                <Dropdown.Header>Studenci z dostępem do egzaminu</Dropdown.Header>
                {students.map(student => (
                    <StyledDropdownItem
                        key={student.id}
                        onClick={() => onStudentSelect(student)}
                        active={selectedStudent?.id === student.id}
                        className={student.has_started ? 'completed' : 'not-started'}
                    >
                        {student.name} {student.attempts_used > 0 ? `(${student.attempts_used} podejść)` : ''}
                    </StyledDropdownItem>
                ))}
                {availableStudents.length > 0 && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Header>Studenci bez dostępu</Dropdown.Header>
                        {availableStudents.map(student => (
                            <StyledDropdownItem
                                key={student.id}
                                className="not-started"
                                disabled
                            >
                                {student.name} {student.surname}
                            </StyledDropdownItem>
                        ))}
                    </>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ExamStudentDropdown;
