import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import TasksContentLayout from '../components/TasksContentLayout';
import { usePost, useFetch } from '../../../hooks';

const TeacherTaskContent = ({
                                loading,
                                error,
                                task,
                                isExam,
                                examId,
                                selectedStudent
                            }) => {
    const [comment, setComment] = useState('');
    const [score, setScore] = useState('');
    const [formError, setFormError] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    // Hook do pobierania istniejącej oceny zadania
    const {
        data: gradeData,
        loading: gradeLoading,
        error: loadError,
        fetchData: refreshGrade
    } = useFetch(
        isExam && selectedStudent && task ? 'TEACHER_EXAM_TASK_GRADE_GET' : null,
        isExam && selectedStudent && task ? [examId, selectedStudent.id, task.id] : undefined
    );

    const { postData } = usePost();

    // Efekt do ustawiania danych z istniejącej oceny
    useEffect(() => {
        if (gradeData?.task_grade) {
            const { score, comment } = gradeData.task_grade;
            setScore(score?.toString() || '');
            setComment(comment || '');
            setIsEditing(false);
        } else {
            setScore('');
            setComment('');
            setIsEditing(true);
        }
    }, [gradeData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!score) {
            setFormError('Proszę wybrać ocenę');
            return;
        }

        setIsSaving(true);
        try {
            await postData(
                'TEACHER_EXAM_TASK_GRADE_POST',
                {
                    score: parseFloat(score),
                    comment: comment
                },
                [examId, selectedStudent.id, task.id]
            );

            await refreshGrade();
            setFormError('');
            setIsEditing(false);
        } catch (error) {
            setFormError('Wystąpił błąd podczas zapisywania oceny');
            console.error('Error saving grade:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const grades = [
        1, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6
    ];

    if (!task) {
        return <p>Wybierz zadanie, aby zobaczyć jego treść.</p>;
    }

    const renderGradingForm = () => (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Label>Ocena zadania</Form.Label>
                <Form.Select
                    value={score}
                    onChange={(e) => setScore(e.target.value)}
                    isInvalid={!!formError}
                >
                    <option value="">Wybierz ocenę</option>
                    {grades.map((grade) => (
                        <option key={grade} value={grade}>
                            {grade.toFixed(1)}
                        </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {formError}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Komentarz do zadania</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Wprowadź komentarz do zadania..."
                />
            </Form.Group>

            <div className="d-flex gap-2">
                {gradeData?.task_grade && (
                    <Button
                        variant="secondary"
                        onClick={() => setIsEditing(false)}
                        disabled={isSaving}
                    >
                        Anuluj
                    </Button>
                )}
                <Button
                    type="submit"
                    variant="primary"
                    disabled={isSaving}
                >
                    {isSaving ? 'Zapisywanie...' : 'Zapisz ocenę'}
                </Button>
            </div>
        </Form>
    );

    const renderSavedGrade = () => (
        <div className="p-3 border rounded">
            <h6>Wystawiona ocena za zadanie</h6>
            <p><strong>Ocena:</strong> {gradeData.task_grade.score.toFixed(1)}</p>
            {gradeData.task_grade.comment && (
                <p>
                    <strong>Komentarz:</strong><br />
                    {gradeData.task_grade.comment}
                </p>
            )}
            <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setIsEditing(true)}
            >
                Edytuj ocenę zadania
            </Button>
        </div>
    );

    return (
        <div className="d-flex flex-column h-100">
            <div className="flex-grow-1 overflow-auto border-bottom">
                <TasksContentLayout
                    title={task.name}
                    language={task.language}
                    description={task.description}
                    content={task.content}
                    isEditing={false}
                />
            </div>

            {isExam && selectedStudent && (
                <div className="p-3 border-top">
                    <h5>Ocena zadania</h5>
                    {gradeLoading ? (
                        <div className="text-center py-3">
                            <Spinner animation="border" size="sm" /> Ładowanie oceny...
                        </div>
                    ) : loadError ? (
                        <Alert variant="danger">
                            Błąd podczas ładowania oceny: {loadError}
                        </Alert>
                    ) : (
                        <div className="mt-3">
                            {isEditing ? renderGradingForm() : (
                                gradeData?.task_grade ? renderSavedGrade() : (
                                    <div>
                                        <p>Zadanie nie zostało jeszcze ocenione.</p>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            onClick={() => setIsEditing(true)}
                                        >
                                            Oceń zadanie
                                        </Button>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default TeacherTaskContent;
