import React from 'react';
import withAuth from '../hooks/withAuth';

function Component0() {
    return (
        <div>
            <h1>Strona główna</h1>
        </div>
    );
}

export default withAuth(Component0);
