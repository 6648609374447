import React from 'react';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminDataTable from '../../components/generic/AdminDataTable';
import AdminEntityFormModal from '../../components/generic/AdminEntityFormModal';

const UsersView = ({
                       loading,
                       error,
                       deleteError,
                       deleteResponse,
                       data,
                       showDelete,
                       showForm,
                       editMode,
                       formData,
                       handleCloseForm,
                       handleShowForm,
                       handleShowDelete,
                       handleCloseDelete,
                       confirmDelete,
                       handleSubmit,
                       handleChange,
                       deleteModalTexts,
                       departments,
                       roles,
                       schools,
                       sortConfig,
                       onSort,
                       onSchoolChange,
                       departmentsForSchool,
                       currentRole,
                       statusOptions
                   }) => {
    const columns = [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'username', label: 'username', sortable: true },
        { key: 'name', label: 'name', sortable: true },
        { key: 'surname', label: 'surname', sortable: true },
        { key: 'email', label: 'email', sortable: true },
        { key: 'role', label: 'role', sortable: true },
        {
            key: 'status',
            label: 'status',
            sortable: true,
            render: (user) => user.status
        },
        { key: 'school', label: 'school', sortable: true },
        {
            key: 'departments',
            label: 'departments',
            sortable: true,
            render: (user) => user.departments?.map(d => d.name).join(', ') || '-'
        }
    ];

    const actions = [
        { label: 'edit', variant: 'secondary', onClick: handleShowForm },
        { label: 'delete', variant: 'danger', onClick: (user) => handleShowDelete(user.id) }
    ];

    const headerButtons = [
        { label: 'addUser', variant: 'primary', onClick: () => handleShowForm() }
    ];

    const formFields = [
        { name: 'username', type: 'text', label: 'username', required: true },
        { name: 'password', type: 'password', label: 'password', required: true, hideOnEdit: true },
        { name: 'email', type: 'email', label: 'email', required: true },
        { name: 'name', type: 'text', label: 'name', required: true },
        { name: 'surname', type: 'text', label: 'surname', required: true },
        {
            name: 'role_id',
            type: 'select',
            label: 'role',
            required: true,
            options: roles,
            placeholder: 'selectRole'
        },
        {
            name: 'status',
            type: 'select',
            label: 'status',
            required: true,
            options: statusOptions,
            placeholder: 'selectStatus'
        },
        {
            name: 'is_super',
            type: 'checkbox',
            label: 'isSuper',
            disabled: !currentRole || !['admin', 'teacher'].includes(currentRole),
            hideIf: !currentRole || !['admin', 'teacher'].includes(currentRole)
        },
        {
            name: 'school_id',
            type: 'select',
            label: 'school',
            required: true,
            options: schools,
            placeholder: 'selectSchool',
            onChange: onSchoolChange
        },
        {
            name: 'department_ids',
            type: 'checkboxGroup',
            label: 'departments',
            options: departmentsForSchool,
            placeholder: 'selectDepartments',
            disabled: !formData.school_id
        },
    ];

    const getRowStyle = (item) => {
        if (item.is_super) {
            return { backgroundColor: 'rgba(0, 123, 255, 0.1)' };
        }
        return {};
    };

    return (
        <>
            <AdminDataTable
                loading={loading}
                error={error}
                actionError={deleteError}
                actionResponse={deleteResponse}
                title="users"
                data={data}
                columns={columns}
                actions={actions}
                headerButtons={headerButtons}
                sortConfig={sortConfig}
                onSort={onSort}
                getRowStyle={getRowStyle}
            />

            <AdminEntityFormModal
                show={showForm}
                handleClose={handleCloseForm}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                error={error}
                loading={loading}
                isEdit={editMode}
                title="User"
                fields={formFields}
            />

            <DeleteModal
                show={showDelete}
                handleClose={handleCloseDelete}
                handleConfirm={confirmDelete}
                texts={deleteModalTexts}
            />
        </>
    );
};

export default UsersView;
