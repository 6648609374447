import React from 'react';
import { useFetch } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import { useNavigate, useParams } from 'react-router-dom';
import StudentTopicsView from './StudentTopicsView';

const StudentTopicsContainer = () => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const { darkMode } = useTheme();

    // Pobieramy tematy używając endpointu dla studenta
    const { data, loading, error } = useFetch('STUDENT_COURSE_TOPICS', [courseId]);

    const topicActions = {
        onViewTasks: (topicId) => navigate(`/student/tasks/${topicId}`),
        onBackToCourses: () => navigate('/student/courses')
    };

    return (
        <StudentTopicsView
            topics={data?.topics || []}
            loading={loading}
            error={error}
            darkMode={darkMode}
            actions={topicActions}
        />
    );
};

export default StudentTopicsContainer;
