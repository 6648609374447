import React from 'react';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminDataTable from '../../components/generic/AdminDataTable';
import AdminEntityFormModal from '../../components/generic/AdminEntityFormModal';

const DepartmentUsersView = ({
                                 loading,
                                 error,
                                 actionError,
                                 actionResponse,
                                 departmentName,
                                 users,
                                 showDelete,
                                 showForm,
                                 editMode,
                                 formData,
                                 roles,
                                 sortConfig,
                                 deleteModalTexts,
                                 onSort,
                                 onShowForm,
                                 onCloseForm,
                                 onShowDelete,
                                 onCloseDelete,
                                 onConfirmDelete,
                                 onSubmit,
                                 onChange,
                                 onNavigateBack,
                                 departmentId
                             }) => {
    const columns = [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'username', label: 'username', sortable: true },
        { key: 'name', label: 'name', sortable: true },
        { key: 'surname', label: 'surname', sortable: true },
        { key: 'email', label: 'email', sortable: true },
        { key: 'role', label: 'role', sortable: true },
        { key: 'active', label: 'active', sortable: true, render: (user) => user.active ? 'yes' : 'no' }
    ];

    const actions = [
        { label: 'edit', variant: 'secondary', onClick: onShowForm },
        { label: 'delete', variant: 'danger', onClick: (user) => onShowDelete(user.id) }
    ];

    const headerButtons = [
        { label: 'addUser', variant: 'primary', onClick: () => onShowForm() },
        { label: 'back', variant: 'secondary', onClick: onNavigateBack }
    ];

    const formFields = [
        { name: 'username', type: 'text', label: 'username', required: true },
        { name: 'password', type: 'password', label: 'password', required: true, hideOnEdit: true },
        { name: 'email', type: 'email', label: 'email', required: true },
        { name: 'name', type: 'text', label: 'name', required: true },
        { name: 'surname', type: 'text', label: 'surname', required: true },
        {
            name: 'role_id',
            type: 'select',
            label: 'role',
            required: true,
            options: roles,
            placeholder: 'selectRole'
        },
        { name: 'active', type: 'checkbox', label: 'active' }
    ];

    return (
        <>
            <AdminDataTable
                loading={loading}
                error={error}
                actionError={actionError}
                actionResponse={actionResponse}
                title="departmentUsers"
                subtitle={departmentName}
                data={users}
                columns={columns}
                actions={actions}
                headerButtons={headerButtons}
                sortConfig={sortConfig}
                onSort={onSort}
            />

            <AdminEntityFormModal
                show={showForm}
                handleClose={onCloseForm}
                formData={formData}
                handleChange={onChange}
                handleSubmit={onSubmit}
                error={error}
                loading={loading}
                isEdit={editMode}
                title="User"
                fields={formFields}
            />

            <DeleteModal
                show={showDelete}
                handleClose={onCloseDelete}
                handleConfirm={onConfirmDelete}
                texts={deleteModalTexts}
            />
        </>
    );
};

export default DepartmentUsersView;
