import React, { useState, useEffect } from 'react';
import { useFetch, usePost } from '../../hooks';
import { useTranslation } from 'react-i18next';
import UsersView from './UsersView';

const UsersContainer = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        name: '',
        surname: '',
        role_id: '',
        status: 'active',
        is_super: false,
        school_id: '',
        department_ids: []
    });

    const { data, loading, error, fetchData } = useFetch('ADMIN_USERS');
    const { data: statusesData } = useFetch('STATUSES');
    const { postData, response: deleteResponse, error: deleteError, loading: deleteLoading } = usePost();
    const { data: rolesData, loading: rolesLoading } = useFetch('ADMIN_ROLES');
    const { data: schoolsData, loading: schoolsLoading } = useFetch('ADMIN_SCHOOLS');

    // Stan sortowania
    const [sortConfig, setSortConfig] = useState({
        key: 'id',
        direction: 'asc'
    });

    // Stan departamentów dla wybranej szkoły
    const [departmentsForSchool, setDepartmentsForSchool] = useState([]);
    const [loadingDepartments, setLoadingDepartments] = useState(false);
    const [currentRole, setCurrentRole] = useState(null);

    // Pozostałe stany
    const [showDelete, setShowDelete] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    // Efekt do śledzenia zmiany roli
    useEffect(() => {
        if (formData.role_id && rolesData?.roles) {
            const role = rolesData.roles.find(r => r.id.toString() === formData.role_id);
            setCurrentRole(role?.name);
            if (!['admin', 'teacher'].includes(role?.name)) {
                setFormData(prev => ({
                    ...prev,
                    is_super: false
                }));
            }
        } else {
            setCurrentRole(null);
        }
    }, [formData.role_id, rolesData?.roles]);

    // Efekt do pobierania departamentów gdy zmienia się school_id
    useEffect(() => {
        const fetchDepartments = async () => {
            if (!formData.school_id) {
                setDepartmentsForSchool([]);
                return;
            }

            setLoadingDepartments(true);
            try {
                const response = await postData('ADMIN_SCHOOL_DEPARTMENTS', null, [formData.school_id]);
                setDepartmentsForSchool(response.departments || []);
            } catch (error) {
                console.error('Failed to fetch departments:', error);
                setDepartmentsForSchool([]);
            } finally {
                setLoadingDepartments(false);
            }
        };

        fetchDepartments();
    }, [formData.school_id, postData]);

    const sortData = (users, sortKey, direction) => {
        if (!users || !Array.isArray(users)) return [];

        return [...users].sort((a, b) => {
            let aValue = a[sortKey];
            let bValue = b[sortKey];

            if (aValue === undefined || aValue === null) aValue = '';
            if (bValue === undefined || bValue === null) bValue = '';

            if (sortKey === 'active') {
                return direction === 'asc' ?
                    Number(aValue) - Number(bValue) :
                    Number(bValue) - Number(aValue);
            }

            if (sortKey === 'departments') {
                aValue = (a.departments || []).map(d => d.name).join(', ');
                bValue = (b.departments || []).map(d => d.name).join(', ');
            }

            if (typeof aValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setEditMode(false);
        setFormData({
            username: '',
            password: '',
            email: '',
            name: '',
            surname: '',
            role_id: '',
            status: 'active',
            is_super: false,
            school_id: '',
            department_ids: []
        });
        setDepartmentsForSchool([]);
        setCurrentRole(null);
    };

    const handleShowForm = (user = null) => {
        if (user) {
            setFormData({
                username: user.username,
                email: user.email,
                name: user.name,
                surname: user.surname,
                role_id: user.role_id.toString(),
                status: user.status,
                is_super: user.is_super,
                school_id: user.school_id ? user.school_id.toString() : '',
                department_ids: user.departments.map(d => d.id.toString())
            });
            setSelectedUserId(user.id);
            setEditMode(true);

            if (user.school_id) {
                const fetchDepartments = async () => {
                    setLoadingDepartments(true);
                    try {
                        const response = await postData('ADMIN_SCHOOL_DEPARTMENTS', null, [user.school_id]);
                        setDepartmentsForSchool(response.departments || []);
                    } catch (error) {
                        console.error('Failed to fetch departments:', error);
                        setDepartmentsForSchool([]);
                    } finally {
                        setLoadingDepartments(false);
                    }
                };
                fetchDepartments();
            }
        } else {
            setEditMode(false);
            setFormData({
                username: '',
                password: '',
                email: '',
                name: '',
                surname: '',
                role_id: '',
                status: 'active',
                is_super: false,
                school_id: '',
                department_ids: []
            });
        }
        setShowForm(true);
    };

    const handleShowDelete = (userId) => {
        setSelectedUserId(userId);
        setShowDelete(true);
    };

    const handleCloseDelete = () => setShowDelete(false);

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const confirmDelete = async () => {
        try {
            await postData('ADMIN_USER_DELETE', null, [selectedUserId]);
            fetchData();
            setShowDelete(false);
        } catch (error) {
            console.error('Failed to delete user:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const dataToSubmit = {
                ...formData,
                role_id: parseInt(formData.role_id),
                school_id: formData.school_id ? parseInt(formData.school_id) : null,
                department_ids: formData.department_ids.map(id => parseInt(id))
            };

            if (editMode) {
                await postData('ADMIN_USER_UPDATE', dataToSubmit, [selectedUserId]);
            } else {
                await postData('ADMIN_USER_ADD', dataToSubmit);
            }
            fetchData();
            handleCloseForm();
        } catch (error) {
            console.error('Failed to save user:', error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSchoolChange = async (schoolId) => {
        setFormData(prev => ({
            ...prev,
            school_id: schoolId,
            department_ids: editMode ? prev.department_ids : []
        }));
    };

    useEffect(() => {
        if (deleteResponse) fetchData();
    }, [deleteResponse, fetchData]);

    const deleteModalTexts = {
        title: t('confirmDeletion'),
        body: t('confirmDeletionMessage'),
        cancel: t('cancel'),
        confirm: t('delete'),
    };

    const sortedData = sortData(data, sortConfig.key, sortConfig.direction);

    const userStatusOptions = statusesData?.user?.map(status => ({
        id: status.value,
        name: status.label
    })) || [];

    return (
        <UsersView
            loading={loading || deleteLoading || rolesLoading || schoolsLoading || loadingDepartments}
            error={error}
            deleteError={deleteError}
            deleteResponse={deleteResponse}
            data={sortedData}
            showDelete={showDelete}
            showForm={showForm}
            editMode={editMode}
            formData={formData}
            handleCloseForm={handleCloseForm}
            handleShowForm={handleShowForm}
            handleShowDelete={handleShowDelete}
            handleCloseDelete={handleCloseDelete}
            confirmDelete={confirmDelete}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            deleteModalTexts={deleteModalTexts}
            departments={departmentsForSchool}
            roles={rolesData?.roles || []}
            schools={schoolsData?.schools || []}
            sortConfig={sortConfig}
            onSort={handleSort}
            onSchoolChange={handleSchoolChange}
            departmentsForSchool={departmentsForSchool}
            currentRole={currentRole}
            statusOptions={userStatusOptions}
        />
    );
};

export default UsersContainer;
