import React from 'react';
import CourseTable from '../components/CourseTable';
import CourseForm from '../components/CourseForm';
import DeleteModal from '../../../components/modals/DeleteModal';

const AdminCoursesView = ({
                              courses,
                              loading,
                              error,
                              darkMode,
                              actions,
                              showDelete,
                              showForm,
                              formData,
                              editMode,
                              selectedCourseId,
                              onCloseDelete,
                              onConfirmDelete,
                              onCloseForm,
                              onFormSubmit,
                              onFormChange,
                              modalTexts,
                              actionError,
                              actionLoading,
                          }) => {
    return (
        <div className="container mt-4">
            <CourseTable
                courses={courses}
                loading={loading}
                error={error}
                darkMode={darkMode}
                userRole="admin"
                actions={actions}
            />

            <CourseForm
                show={showForm}
                handleClose={onCloseForm}
                formData={formData}
                handleChange={onFormChange}
                handleSubmit={onFormSubmit}
                error={error || actionError}
                loading={loading || actionLoading}
                isEdit={editMode}
                darkMode={darkMode}
            />

            <DeleteModal
                show={showDelete}
                handleClose={onCloseDelete}
                handleConfirm={onConfirmDelete}
                texts={modalTexts.delete}
                darkMode={darkMode}
            />
        </div>
    );
};

export default AdminCoursesView;
