import React, { useState } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { usePost } from '../hooks';

const ChangePasswordModal = ({ show, handleClose }) => {
    const { t } = useTranslation();
    const { postData, loading } = usePost();

    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [fieldErrors, setFieldErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const resetState = () => {
        setFormData({
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        });
        setFieldErrors({});
        setSuccess(false);
    };

    const handleModalClose = () => {
        resetState();
        handleClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setFieldErrors(prev => ({
            ...prev,
            [name]: null
        }));
    };

    const handleSubmit = async () => {
        setFieldErrors({});
        setSuccess(false);

        // Walidacja
        const errors = {};
        if (!formData.currentPassword) {
            errors.currentPassword = t('currentPasswordRequired');
        }
        if (!formData.newPassword) {
            errors.newPassword = t('newPasswordRequired');
        }
        if (!formData.confirmPassword) {
            errors.confirmPassword = t('confirmPasswordRequired');
        }
        if (formData.newPassword !== formData.confirmPassword) {
            errors.confirmPassword = t('passwordsDoNotMatch');
        }
        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return;
        }

        try {
            const response = await postData('PROFILE_CHANGE_PASSWORD', {
                currentPassword: formData.currentPassword,
                newPassword: formData.newPassword
            });

            if (response && response.message) {
                setSuccess(true);
                setFormData({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });

                setTimeout(() => {
                    handleModalClose();
                }, 2000);
            }
        } catch (err) {
            console.error('Password change error:', err);

            if (err.response) {
                console.log('Error response:', err.response);

                if (err.response.status === 401) {
                    setFieldErrors({
                        currentPassword: t('incorrectCurrentPassword')
                    });
                } else if (err.response.data && err.response.data.message) {
                    setFieldErrors({
                        general: err.response.data.message
                    });
                } else {
                    setFieldErrors({
                        general: t('errorChangingPassword')
                    });
                }
            } else {
                setFieldErrors({
                    general: t('errorChangingPassword')
                });
            }
        }
    };

    return (
        <Modal show={show} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('changePassword')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {success && (
                    <Alert variant="success">
                        {t('passwordChangedSuccessfully')}
                    </Alert>
                )}
                {fieldErrors.general && (
                    <Alert variant="danger">
                        {fieldErrors.general}
                    </Alert>
                )}

                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('currentPassword')}</Form.Label>
                        <Form.Control
                            type="password"
                            name="currentPassword"
                            value={formData.currentPassword}
                            onChange={handleChange}
                            isInvalid={!!fieldErrors.currentPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                            {fieldErrors.currentPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t('newPassword')}</Form.Label>
                        <Form.Control
                            type="password"
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleChange}
                            isInvalid={!!fieldErrors.newPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                            {fieldErrors.newPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t('confirmPassword')}</Form.Label>
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            isInvalid={!!fieldErrors.confirmPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                            {fieldErrors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    {t('cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {t('change')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangePasswordModal;
