import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import AdminTasksView from './AdminTasksView';
import styled from 'styled-components';

const ViewContainer = styled.div`
    height: calc(100vh - 120px);  // Wysokość viewportu minus wysokość menu
    position: relative;
    z-index: 1;  // Niższy z-index niż menu
`;

const AdminTasksContainer = () => {
    const { topicId } = useParams();
    const { darkMode } = useTheme();
    const [theme, setTheme] = useState(() => darkMode ? 'monokai' : 'github');
    const [editorContent, setEditorContent] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [outputMode, setOutputMode] = useState('html');
    const [codeOutput, setCodeOutput] = useState('');
    const [runCounter, setRunCounter] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [showConsole, setShowConsole] = useState(false);
    const [consoleLogCount, setConsoleLogCount] = useState(0);

    // Fetch tasks list
    const {
        data: tasksList,
        loading: tasksLoading,
        error: tasksError,
        fetchData: refreshTasksList
    } = useFetch(
        'ADMIN_TOPIC_TASKS',
        topicId ? [topicId] : null
    );

    // Fetch task details
    const {
        data: taskDetails,
        loading: taskLoading,
        error: taskError,
        fetchData: refreshTaskDetails
    } = useFetch(
        selectedTaskId ? 'ADMIN_TASK_DETAILS' : null,
        selectedTaskId ? [selectedTaskId] : null
    );

    const { postData } = usePost();

    // Handle running code
    const handleRunCode = async () => {
        setConsoleLogCount(0);
        const isJavaScript = taskDetails?.language === 'javascript';

        try {
            if (isJavaScript) {
                // Bezpośrednie wykonanie kodu JavaScript w przeglądarce
                setCodeOutput(editorContent);
                setRunCounter(prev => prev + 1);
            } else {
                // Wykonanie innych języków przez backend
                const response = await postData('EXECUTE_CODE', {
                    code: editorContent,
                    language: taskDetails?.language
                });

                if (response.output) {
                    setCodeOutput(response.output);
                } else if (response.error) {
                    setCodeOutput(`Error: ${response.error}`);
                }
                setRunCounter(prev => prev + 1);
            }
        } catch (error) {
            console.error('Error executing code:', error);
            setCodeOutput(`Error executing code: ${error.message}`);
            setRunCounter(prev => prev + 1);
        }
    };

    // Dodana funkcja powrotu
    const handleBackToTopics = () => {
        // Pobranie courseId z danych tematu
        const courseId = tasksList?.course_id || localStorage.getItem('lastCourseId');
        if (courseId) {
            //navigate(`/admin/topics/${courseId}`);
        } else {
            //navigate('/admin/courses');
        }
    };

    // Handle task selection
    const handleTaskSelect = (taskId) => {
        //console.log('AdminTasksContainer - handleTaskSelect');
        if (taskId !== null) {  // Dodaj warunek
            setSelectedTaskId(taskId);
            setIsEditing(false);
            setIsAdding(false);
            setCodeOutput('');
            setConsoleLogCount(0);
        }
    };

    // Handle saving (create/update)
    const handleSave = async (formData) => {
        try {
            if (isAdding) {
                await postData('ADMIN_TASK_ADD', {
                    ...formData,
                    topic_id: topicId
                });
            } else {
                await postData('ADMIN_TASK_UPDATE', formData, [selectedTaskId]);
            }

            setIsEditing(false);
            setIsAdding(false);
            refreshTasksList();
            if (selectedTaskId) {
                refreshTaskDetails();
            }
        } catch (error) {
            console.error('Error saving task:', error);
        }
    };

    // Effect do aktualizacji kodu w edytorze gdy zmienią się szczegóły zadania
    React.useEffect(() => {
        if (taskDetails) {
            setEditorContent(taskDetails.code || '');
        }
    }, [taskDetails]);

    React.useEffect(() => {
        if (selectedTaskId === null) {
            setEditorContent('');
        }
    }, [selectedTaskId]);

    // Effect for theme changes
    React.useEffect(() => {
        setTheme(darkMode ? 'monokai' : 'github');
    }, [darkMode]);

    // Effect for topic changes
    React.useEffect(() => {
        if (topicId) {
            setSelectedTaskId(null);
            setEditorContent('');
            setCodeOutput('');
            setConsoleLogCount(0);
            setIsEditing(false);
            setIsAdding(false);
            refreshTasksList();
        }
    }, [topicId, refreshTasksList]);

    //console.log('AdminTasksContainer - render, isAdding:', isAdding, 'isEditing:', isEditing);

    return (
        <ViewContainer>
            <AdminTasksView
                tasksList={tasksList}
                tasksLoading={tasksLoading}
                tasksError={tasksError}
                selectedTaskId={selectedTaskId}
                setSelectedTaskId={handleTaskSelect}
                taskDetails={taskDetails}
                taskLoading={taskLoading}
                taskError={taskError}
                theme={theme}
                setTheme={setTheme}
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                outputMode={outputMode}
                setOutputMode={setOutputMode}
                codeOutput={codeOutput}
                onRunCode={handleRunCode}
                runCounter={runCounter}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isAdding={isAdding}
                setIsAdding={setIsAdding}
                onSave={handleSave}
                showConsole={showConsole}
                setShowConsole={setShowConsole}
                consoleLogCount={consoleLogCount}
                setConsoleLogCount={setConsoleLogCount}
                onRefreshTasks={refreshTasksList}
                onBackToTopics={handleBackToTopics}
            />
        </ViewContainer>
    );
};

export default AdminTasksContainer;
