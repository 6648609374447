import config from './config';

export const API_ENDPOINTS = {
    // Auth
    LOGIN: { path: 'auth/login', method: 'POST' },
    LOGOUT: { path: 'auth/logout', method: 'POST' },
    REFRESH_TOKEN: { path: 'auth/refresh', method: 'POST' },

    // Profile endpoints
    PROFILE_GET: { path: 'profile/profile', method: 'GET' },
    PROFILE_UPDATE: { path: 'profile/profile', method: 'PUT' },
    PROFILE_CHANGE_PASSWORD: { path: 'profile/change-password', method: 'POST' },

    // Common endpoints
    LANGUAGES: { path: 'common/languages', method: 'GET' },
    STATUSES: { path: 'common/statuses', method: 'GET' },
    STATUS: { path: 'status', method: 'GET' },
    EXECUTE_CODE: { path: 'execute_code', method: 'POST' },

    // Student endpoints
    // -- Courses
    STUDENT_COURSES: { path: 'student/courses', method: 'GET' },
    STUDENT_COURSE_TOPICS: { path: (id) => `student/courses/${id}/topics`, method: 'GET' },

    // -- Tasks
    STUDENT_TOPIC_INSTANCE_TASKS: { path: (topicId) => `student/tasks/topic/${topicId}`, method: 'GET' },
    STUDENT_TASK_INSTANCE_DETAILS: { path: (taskId) => `student/tasks/${taskId}/details`, method: 'GET' },
    STUDENT_TASK_INSTANCE_ORIGINAL: { path: (taskId) => `student/tasks/${taskId}/original`, method: 'GET' },

    // -- Progress
    STUDENT_SEND_SNAPSHOTS: { path: (taskId) => `student_progress/snapshot`, method: 'POST' },
    STUDENT_PROGRESS_SNAPSHOTS: { path: (taskId) => `student_progress/snapshots/${taskId}`, method: 'GET' },

    // -- Exams
    STUDENT_EXAM_SCHEDULES: { path: 'student/exams/exam-schedules', method: 'GET' },
    STUDENT_EXAM_START: { path: (id) => `student/exams/exam-schedules/${id}/attempt`, method: 'POST' },
    STUDENT_EXAM_RESULTS: { path: (id) => `student/exams/exam-schedules/${id}/results`, method: 'GET' },
    STUDENT_ACTIVE_EXAM_STATUS: { path: 'student/exams/active-exam-status', method: 'GET' },
    STUDENT_EXAM_DETAILS: { path: (examId) => `student/exams/exam-schedules/${examId}/details`, method: 'GET' },
    STUDENT_EXAM_TASKS: { path: (examId) => `student/exams/exam-schedules/${examId}/tasks`, method: 'GET' },
    STUDENT_EXAM_TASK_DETAILS: { path: (examId, taskId) => `student/exams/exam-schedules/${examId}/tasks/${taskId}`, method: 'GET' },
    STUDENT_EXAM_ATTEMPT_DETAILS: { path: (attemptId) => `student/exams/attempts/${attemptId}`, method: 'GET' },
    STUDENT_EXAM_COMPLETE: { path: (attemptId) => `student/exams/attempts/${attemptId}/complete`, method: 'POST' },
    STUDENT_EXAM_SNAPSHOT: { path: (attemptId) => `student/exams/attempt/${attemptId}/snapshot`, method: 'POST' },
    STUDENT_EXAM_DETAILED_RESULTS: { path: (id) => `student/exams/exam-schedules/${id}/detailed-results`, method: 'GET' },
    EXAM_SNAPSHOTS: { path: (examId, attemptId, taskId) => `student/exams/exam-schedules/${examId}/attempt/${attemptId}/snapshots/${taskId}`, method: 'GET' },
    EXAM_LAST_SNAPSHOT: { path: (examId, attemptId, taskId) => `student/exams/exam-schedules/${examId}/attempt/${attemptId}/snapshots/${taskId}/last`, method: 'GET' },

    // Teacher endpoints
    // -- Courses
    TEACHER_COURSES: { path: 'teacher/courses', method: 'GET' },
    TEACHER_COURSES_STUDENTS_GET: { path: (id) => `teacher/courses/${id}/students`, method: 'GET' },
    TEACHER_COURSES_STUDENTS_PUT: { path: (id) => `teacher/courses/${id}/students`, method: 'PUT' },
    TEACHER_COURSE_INSTANCE_UPDATE: { path: (id) => `teacher/courses/${id}`, method: 'PUT' },
    TEACHER_COURSE_INSTANCE_TOPICS: { path: (id) => `teacher/courses/${id}/topics`, method: 'GET' },
    TEACHER_COURSE_STUDENTS: { path: (courseId) => `teacher/courses/${courseId}/students`, method: 'GET' },

    // -- Topics
    TEACHER_TOPIC_INSTANCE_UPDATE: { path: (id) => `teacher/topics/${id}`, method: 'PUT' },

    // -- Tasks
    TEACHER_TOPIC_TASKS: { path: (topicId) => `teacher/tasks/topic/${topicId}`, method: 'GET' },
    TEACHER_TASK_DETAILS: { path: (taskId) => `teacher/tasks/${taskId}`, method: 'GET' },
    TEACHER_TASK_UPDATE: { path: (taskId) => `teacher/tasks/${taskId}`, method: 'PUT' },
    TEACHER_TASK_RESET: { path: (taskId) => `teacher/tasks/${taskId}/reset`, method: 'POST' },
    TEACHER_STUDENT_PROGRESS: { path: (taskId, studentId) => `student_progress/snapshots/${taskId}/${studentId}`, method: 'GET' },

    // -- Exams
    TEACHER_EXAM_SCHEDULES: { path: 'teacher/exams/exam-schedules', method: 'GET' },
    TEACHER_EXAM_TOPICS: { path: 'teacher/exams/topics/exam', method: 'GET' },
    TEACHER_EXAM_SCHEDULE_CREATE: { path: 'teacher/exams/exam-schedules', method: 'POST' },
    TEACHER_EXAM_SCHEDULE_UPDATE: { path: (id) => `teacher/exams/exam-schedules/${id}`, method: 'PUT' },
    TEACHER_EXAM_SCHEDULE_DELETE: { path: (id) => `teacher/exams/exam-schedules/${id}`, method: 'DELETE' },
    TEACHER_EXAM_SCHEDULE_STUDENTS: { path: (id) => `teacher/exams/exam-schedules/${id}/students`, method: 'GET' },
    TEACHER_EXAM_SCHEDULE_ADD_STUDENTS: { path: (id) => `teacher/exams/exam-schedules/${id}/students`, method: 'POST' },
    TEACHER_EXAM_SCHEDULE_REMOVE_STUDENT: { path: (id, studentId) => `teacher/exams/exam-schedules/${id}/students/${studentId}`, method: 'DELETE' },
    TEACHER_EXAM_RESULTS: { path: (id) => `teacher/exams/exam-schedules/${id}/results`, method: 'GET' },
    TEACHER_EXAM_STUDENT_SNAPSHOTS: { path: (examId, studentId, taskId) => `teacher/exams/exam-schedules/${examId}/students/${studentId}/tasks/${taskId}/snapshots`, method: 'GET' },
    TEACHER_EXAM_STUDENT_TASK: { path: (examId, studentId, taskId) => `teacher/exams/exam-schedules/${examId}/students/${studentId}/tasks/${taskId}`, method: 'GET' },
    TEACHER_EXAM_TASKS: { path: (examId) => `teacher/exams/exam-schedules/${examId}/tasks`, method: 'GET' },
    TEACHER_EXAM_TASK_GRADE_GET: { path: (examId, studentId, taskId) => `teacher/exams/exam-schedules/${examId}/students/${studentId}/tasks/${taskId}/grade`, method: 'GET' },
    TEACHER_EXAM_TASK_GRADE_POST: { path: (examId, studentId, taskId) => `teacher/exams/exam-schedules/${examId}/students/${studentId}/tasks/${taskId}/grade`, method: 'POST' },

    // -- Sync
    TEACHER_SYNC_CHECK_CHANGES: { path: (courseId) => `teacher/sync/course/${courseId}/changes`, method: 'GET' },
    TEACHER_SYNC_APPLY: { path: (courseId) => `teacher/sync/course/${courseId}/sync`, method: 'POST' },
    TEACHER_SYNC_CHECK_ALL_CHANGES: { path: 'teacher/sync/courses/changes', method: 'GET' },

    // Admin endpoints
    // -- Content Management
    // -- -- Courses
    ADMIN_COURSES: { path: 'admin/courses', method: 'GET' },
    ADMIN_COURSE_DETAILS: { path: (id) => `admin/courses/${id}`, method: 'GET' },
    ADMIN_COURSE_DELETE: { path: (id) => `admin/courses/${id}`, method: 'DELETE' },
    ADMIN_COURSE_ADD: { path: 'admin/courses', method: 'POST' },
    ADMIN_COURSE_UPDATE: { path: (id) => `admin/courses/${id}`, method: 'PUT' },
    ADMIN_COURSE_MOVE_UP: { path: (id) => `admin/courses/${id}/move/up`, method: 'POST' },
    ADMIN_COURSE_MOVE_DOWN: { path: (id) => `admin/courses/${id}/move/down`, method: 'POST' },
    ADMIN_COURSE_TOPICS: { path: (id) => `admin/courses/${id}/topics`, method: 'GET' },

    // -- -- Topics
    ADMIN_TOPICS: { path: 'admin/topics', method: 'GET' },
    ADMIN_TOPIC_DETAILS: { path: (id) => `admin/topics/${id}`, method: 'GET' },
    ADMIN_TOPIC_ADD: { path: 'admin/topics', method: 'POST' },
    ADMIN_TOPIC_UPDATE: { path: (id) => `admin/topics/${id}`, method: 'PUT' },
    ADMIN_TOPIC_DELETE: { path: (id) => `admin/topics/${id}`, method: 'DELETE' },
    ADMIN_TOPIC_MOVE_UP: { path: (id) => `admin/topics/${id}/move/up`, method: 'POST' },
    ADMIN_TOPIC_MOVE_DOWN: { path: (id) => `admin/topics/${id}/move/down`, method: 'POST' },

    // -- -- Tasks
    ADMIN_TOPIC_TASKS: { path: (topicId) => `admin/tasks/topic/${topicId}`, method: 'GET' },
    ADMIN_TASK_DETAILS: { path: (taskId) => `admin/tasks/${taskId}`, method: 'GET' },
    ADMIN_TASK_ADD: { path: 'admin/tasks', method: 'POST' },
    ADMIN_TASK_UPDATE: { path: (taskId) => `admin/tasks/${taskId}`, method: 'PUT' },
    ADMIN_TASK_DELETE: { path: (taskId) => `admin/tasks/${taskId}`, method: 'DELETE' },
    ADMIN_TASK_MOVE_UP: { path: (taskId) => `admin/tasks/${taskId}/move/up`, method: 'POST' },
    ADMIN_TASK_MOVE_DOWN: { path: (taskId) => `admin/tasks/${taskId}/move/down`, method: 'POST' },
    ADMIN_TASK_MODEL: { path: (topicId) => `admin/tasks/topic/${topicId}/model`, method: 'GET' },

    // -- Organization Management
    // -- -- Users
    ADMIN_USERS: { path: 'organization/admin/users', method: 'GET' },
    ADMIN_USER_ADD: { path: 'organization/admin/users', method: 'POST' },
    ADMIN_USER_UPDATE: { path: (id) => `organization/admin/users/${id}`, method: 'PUT' },
    ADMIN_USER_DELETE: { path: (id) => `organization/admin/users/${id}`, method: 'DELETE' },
    ADMIN_ROLES: { path: 'organization/admin/users/roles', method: 'GET' },

    // -- -- Departments
    ADMIN_DEPARTMENTS: { path: 'organization/admin/departments', method: 'GET' },
    ADMIN_DEPARTMENT_ADD: { path: 'organization/admin/departments', method: 'POST' },
    ADMIN_DEPARTMENT_UPDATE: { path: (id) => `organization/admin/departments/${id}`, method: 'PUT' },
    ADMIN_DEPARTMENT_DELETE: { path: (id) => `organization/admin/departments/${id}`, method: 'DELETE' },
    ADMIN_DEPARTMENT_USERS: { path: (departmentId) => `organization/admin/departments/${departmentId}/users`, method: 'GET' },
    ADMIN_DEPARTMENTS_UNASSIGNED: { path: 'organization/admin/departments/unassigned', method: 'GET' },

    // -- -- Schools
    ADMIN_SCHOOLS: { path: 'organization/admin/schools', method: 'GET' },
    ADMIN_SCHOOL_ADD: { path: 'organization/admin/schools', method: 'POST' },
    ADMIN_SCHOOL_UPDATE: { path: (id) => `organization/admin/schools/${id}`, method: 'PUT' },
    ADMIN_SCHOOL_DELETE: { path: (id) => `organization/admin/schools/${id}`, method: 'DELETE' },
    ADMIN_SCHOOL_DEPARTMENTS: { path: (schoolId) => `organization/admin/schools/${schoolId}/departments`, method: 'GET' },
    ADMIN_SCHOOL_DEPARTMENTS_ASSIGN: { path: (schoolId) => `organization/admin/schools/${schoolId}/departments/assign`, method: 'POST' },
};

export const buildApiUrl = (endpoint, ...params) => {
    const baseUrl = config.apiUrl;
    const path = typeof endpoint.path === 'function' ? endpoint.path(...params) : endpoint.path;
    return `${baseUrl}/${path}`;
};

const endpoints = {
    API_ENDPOINTS,
    buildApiUrl
};

export default endpoints;
