import React from 'react';
import { Alert, Spinner, Button, Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AdminDataTable = ({
                            loading,
                            error,
                            actionError,
                            actionResponse,
                            title,
                            subtitle,
                            data,
                            columns,
                            actions,
                            headerButtons,
                            sortConfig,
                            onSort
                        }) => {
    const { t } = useTranslation();

    if (loading) {
        return <Spinner animation="border" className="d-block mx-auto mt-4" />;
    }
    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    const sortableHeaderStyle = {
        cursor: 'pointer',
        userSelect: 'none'
    };

    const getSortIcon = (key) => {
        if (sortConfig?.key !== key) return '↕️';
        return sortConfig.direction === 'asc' ? '↓' : '↑';
    };

    const renderSortableHeader = (column) => (
        <th
            key={column.key}
            onClick={() => onSort?.(column.key)}
            style={column.sortable ? sortableHeaderStyle : undefined}
            className="position-relative"
        >
            {t(column.label)} {column.sortable && getSortIcon(column.key)}
        </th>
    );

    return (
        <div className="container mt-4">
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div>
                        <h5>{t(title)}{subtitle && `: ${subtitle}`}</h5>
                    </div>
                    <div className="d-flex gap-2">
                        {headerButtons.map((button, index) => (
                            <Button
                                key={index}
                                variant={button.variant}
                                onClick={button.onClick}
                            >
                                {t(button.label)}
                            </Button>
                        ))}
                    </div>
                </Card.Header>
                <Card.Body>
                    {actionError && <Alert variant="danger">{t(actionError)}</Alert>}
                    {actionResponse && <Alert variant="success">{t(actionResponse.message)}</Alert>}

                    <div style={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}>
                        <Table responsive bordered>
                            <thead style={{ position: 'sticky', top: 0, backgroundColor: 'var(--bs-table-bg)' }}>
                            <tr>
                                {columns.map(column => renderSortableHeader(column))}
                                {actions && <th style={{ width: '200px' }}>{t('actions')}</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {data.map(item => (
                                <tr key={item.id}>
                                    {columns.map(column => (
                                        <td key={column.key}>
                                            {column.render ? column.render(item) : item[column.key]}
                                        </td>
                                    ))}
                                    {actions && (
                                        <td>
                                            <div className="d-flex gap-2">
                                                {actions.map((action, index) => (
                                                    <Button
                                                        key={index}
                                                        size="sm"
                                                        variant={action.variant}
                                                        onClick={() => action.onClick(item)}
                                                    >
                                                        {t(action.label)}
                                                    </Button>
                                                ))}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default AdminDataTable;
