import React from 'react';
import { Card, Table, Alert, Spinner, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CourseActions from './CourseActions';

const CourseTable = ({
                         courses = [],
                         actions = {},
                         userRole,
                         loading = false,
                         error = null,
                         userCourses = [],
                         darkMode = false
                     }) => {
    const { t } = useTranslation();

    if (loading) {
        return <Spinner animation="border" className="d-block mx-auto mt-4" />;
    }

    if (error) {
        return <Alert variant="danger" className="mt-4">{error}</Alert>;
    }

    return (
        <Card className={darkMode ? 'bg-dark text-light' : ''}>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <h5>{t('courses')}</h5>
                {userRole === 'admin' && (
                    <Button
                        variant={darkMode ? "outline-primary" : "primary"}
                        onClick={actions.onAdd}
                    >
                        {t('addCourse')}
                    </Button>
                )}
            </Card.Header>
            <Card.Body>
                {courses.length === 0 ? (
                    <Alert variant="info">{t('noCourses')}</Alert>
                ) : (
                    <div style={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}>
                        <Table responsive bordered className={darkMode ? 'table-dark' : ''}>
                            <thead style={{ position: 'sticky', top: 0, backgroundColor: darkMode ? 'var(--bs-dark)' : 'var(--bs-table-bg)' }}>
                            <tr>
                                <th>{t('Lp')}</th>
                                <th>{t('name')}</th>
                                <th>{t('description')}</th>
                                <th style={{ width: '400px' }}>{t('actions')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {courses.map((course, index) => (
                                <tr key={course.id}>
                                    <td>{index + 1}</td>
                                    <td>{course.name}</td>
                                    <td>{course.description}</td>
                                    <td>
                                        <CourseActions
                                            course={course}
                                            index={index}
                                            totalCourses={courses.length}
                                            userRole={userRole}
                                            actions={actions}
                                            userCourses={userCourses}
                                            darkMode={darkMode}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default CourseTable;
