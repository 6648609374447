import React from 'react';
import { useTheme } from '../context/ThemeContext';
import { Button } from 'react-bootstrap';

export const ThemeToggle = () => {
    const { darkMode, setDarkMode } = useTheme();

    return (
        <Button
            variant={darkMode ? "outline-light" : "outline-dark"}
            size="sm"
            onClick={() => setDarkMode(!darkMode)}
            className="d-flex align-items-center"
        >
            {darkMode ? '☀️' : '🌙'}
        </Button>
    );
};

