import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS, buildApiUrl } from '../config/endpoints';

const useFetch = (endpointKey, params) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        if (!endpointKey || !API_ENDPOINTS[endpointKey]) {
            setLoading(false);
            setData(null);
            return;
        }

        setLoading(true);
        try {
            const endpoint = API_ENDPOINTS[endpointKey];
            const url = params ? buildApiUrl(endpoint, ...params) : buildApiUrl(endpoint);

            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });
            setData(response.data);
            setError(null);
        } catch (err) {
            setError(err.message);
            setData(null);

            if (err.response?.status === 401) {
                sessionStorage.removeItem('access_token');
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    }, [endpointKey, params && JSON.stringify(params)]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, loading, error, fetchData };
};

export default useFetch;
