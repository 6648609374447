import React, { useState } from 'react';
import { Modal, Form, Button, Table, Alert, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../context/ThemeContext';
import { useFetch, usePost } from '../../../hooks';

const TeacherExamStudentsModal = ({
                                      show,
                                      handleClose,
                                      examId
                                  }) => {
    const { t } = useTranslation();
    const { darkMode } = useTheme();
    const { data, error, fetchData } = useFetch(
        examId ? 'TEACHER_EXAM_SCHEDULE_STUDENTS' : null,
        examId ? [examId] : []
    );
    const { postData, loading: actionLoading, error: actionError } = usePost();
    const [selectedStudents, setSelectedStudents] = useState([]);

    const handleAddStudents = async () => {
        try {
            await postData(
                'TEACHER_EXAM_SCHEDULE_ADD_STUDENTS',
                { student_ids: selectedStudents },
                [examId]
            );
            fetchData();
            setSelectedStudents([]);
        } catch (error) {
            console.error('Error adding students:', error);
        }
    };

    const handleRemoveStudent = async (studentId) => {
        try {
            await postData(
                'TEACHER_EXAM_SCHEDULE_REMOVE_STUDENT',
                null,
                [examId, studentId],
                'DELETE'
            );
            fetchData();
        } catch (error) {
            console.error('Error removing student:', error);
        }
    };

    if (!examId) return null;

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton className={darkMode ? 'bg-dark text-light' : ''}>
                <Modal.Title>{t('manageExamStudents')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={darkMode ? 'bg-dark text-light' : ''}>
                {error && <Alert variant="danger">{error}</Alert>}
                {actionError && <Alert variant="danger">{actionError}</Alert>}

                <div className="mb-3">
                    <h6>{t('currentStudents')}</h6>
                    <Table responsive bordered className={darkMode ? 'table-dark' : ''}>
                        <thead>
                        <tr>
                            <th>{t('student')}</th>
                            <th>{t('attempts')}</th>
                            <th>{t('status')}</th>
                            <th>{t('actions')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.students?.map(student => (
                            <tr key={student.id}>
                                <td>{student.name}</td>
                                <td>
                                    <Badge bg={student.attempts_used >= student.attempts_allowed ? 'danger' : 'info'}>
                                        {student.attempts_used}/{student.attempts_allowed}
                                    </Badge>
                                </td>
                                <td>
                                    <Badge bg={student.has_started ? 'warning' : 'success'}>
                                        {student.has_started ? t('started') : t('notStarted')}
                                    </Badge>
                                </td>
                                <td>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => handleRemoveStudent(student.id)}
                                        disabled={student.has_started}
                                    >
                                        {t('remove')}
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>

                <div className="mb-3">
                    <h6>{t('availableStudents')}</h6>
                    <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                        {data?.available_students?.map(student => (
                            <Form.Check
                                key={student.id}
                                type="checkbox"
                                label={`${student.name} ${student.surname}`}
                                checked={selectedStudents.includes(student.id)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedStudents([...selectedStudents, student.id]);
                                    } else {
                                        setSelectedStudents(selectedStudents.filter(id => id !== student.id));
                                    }
                                }}
                            />
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className={darkMode ? 'bg-dark text-light' : ''}>
                <Button variant="secondary" onClick={handleClose}>
                    {t('close')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleAddStudents}
                    disabled={selectedStudents.length === 0 || actionLoading}
                >
                    {t('addSelectedStudents')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TeacherExamStudentsModal;
