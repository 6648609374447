import React from 'react';
import { Dropdown } from 'react-bootstrap';

const CourseStudentDropdown = ({ students, selectedStudent, onStudentSelect }) => {
    if (!students || students.length === 0) return null;

    return (
        <Dropdown className="me-2">
            <Dropdown.Toggle variant={selectedStudent ? "primary" : "light"}>
                {selectedStudent ? `${selectedStudent.name} ${selectedStudent.surname}` : 'Lista studentów'}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {selectedStudent && (
                    <>
                        <Dropdown.Item onClick={() => onStudentSelect(null)}>
                            Powrót do swojego kodu
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </>
                )}
                {students.map(student => (
                    <Dropdown.Item
                        key={student.id}
                        onClick={() => onStudentSelect(student)}
                        active={selectedStudent?.id === student.id}
                    >
                        {student.name} {student.surname}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CourseStudentDropdown;
