import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch, usePost } from '../../hooks';
import SchoolDepartmentsView from './SchoolDepartmentsView';

const SchoolDepartmentsContainer = () => {
    const { schoolId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Fetche
    const { data, loading, error, fetchData } = useFetch('ADMIN_SCHOOL_DEPARTMENTS', [schoolId]);
    const { postData, response: actionResponse, error: actionError, loading: actionLoading } = usePost();
    const { data: unassignedData, loading: unassignedLoading } = useFetch('ADMIN_DEPARTMENTS_UNASSIGNED');

    // Stan sortowania
    const [sortConfig, setSortConfig] = useState({
        key: 'id',
        direction: 'asc'
    });

    // Stany modala i formularza
    const [showDelete, setShowDelete] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
    const [showAssign, setShowAssign] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        year: '',
        school_id: schoolId
    });

    // Funkcja sortująca
    const sortData = (departments, sortKey, direction) => {
        if (!departments) return [];

        return [...departments].sort((a, b) => {
            let aValue = a[sortKey];
            let bValue = b[sortKey];

            if (typeof aValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setEditMode(false);
        setFormData({
            name: '',
            description: '',
            year: '',
            school_id: schoolId
        });
    };

    const handleShowForm = (department = null) => {
        if (department) {
            setFormData({
                name: department.name,
                description: department.description,
                year: department.year,
                school_id: schoolId
            });
            setSelectedDepartmentId(department.id);
            setEditMode(true);
        }
        setShowForm(true);
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (editMode) {
                await postData('ADMIN_DEPARTMENT_UPDATE', formData, [selectedDepartmentId]);
            } else {
                await postData('ADMIN_DEPARTMENT_ADD', formData);
            }
            handleCloseForm();
            fetchData();
        } catch (error) {
            console.error('Błąd podczas zapisywania:', error);
        }
    };

    const handleShowDelete = (departmentId) => {
        setSelectedDepartmentId(departmentId);
        setShowDelete(true);
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
        setSelectedDepartmentId(null);
    };

    const confirmDelete = async () => {
        try {
            await postData('ADMIN_DEPARTMENT_DELETE', null, [selectedDepartmentId]);
            handleCloseDelete();
            fetchData();
        } catch (error) {
            console.error('Błąd podczas usuwania:', error);
        }
    };

    useEffect(() => {
        if (actionResponse) {
            fetchData();
        }
    }, [actionResponse, fetchData]);

    const deleteModalTexts = {
        title: t('confirmDeletion'),
        body: t('department_confirmDeletionMessage'),
        cancel: t('cancel'),
        confirm: t('delete'),
    };

    const sortedDepartments = sortData(data?.departments, sortConfig.key, sortConfig.direction);

    const handleShowAssign = () => setShowAssign(true);
    const handleCloseAssign = () => setShowAssign(false);

    const handleAssignDepartments = async (departmentIds) => {
        try {
            await postData('ADMIN_SCHOOL_DEPARTMENTS_ASSIGN',
                { department_ids: departmentIds },
                [schoolId]
            );
            handleCloseAssign();
            fetchData();
        } catch (error) {
            console.error('Błąd podczas przypisywania oddziałów:', error);
        }
    };

    return (
        <SchoolDepartmentsView
            loading={loading || actionLoading || unassignedLoading}
            error={error}
            actionError={actionError}
            actionResponse={actionResponse}
            schoolName={data?.school?.name}
            departments={sortedDepartments}
            showDelete={showDelete}
            showForm={showForm}
            editMode={editMode}
            formData={formData}
            sortConfig={sortConfig}
            deleteModalTexts={deleteModalTexts}
            onSort={handleSort}
            onShowForm={handleShowForm}
            onCloseForm={handleCloseForm}
            onShowDelete={handleShowDelete}
            onCloseDelete={handleCloseDelete}
            onConfirmDelete={confirmDelete}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onNavigateBack={() => navigate('/admin/schools-table')}
            showAssign={showAssign}
            unassignedDepartments={unassignedData?.departments || []}
            onShowAssign={handleShowAssign}
            onCloseAssign={handleCloseAssign}
            onAssignDepartments={handleAssignDepartments}
        />
    );
};

export default SchoolDepartmentsContainer;
