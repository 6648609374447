import React, { useState, useEffect } from 'react';
import { Modal, Button, Accordion, Badge, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TeacherSyncModal = ({
                              show,
                              onHide,
                              courses = [],
                              loading = false,
                              error = null,
                              onSync,
                              isSyncing = false,
                              darkMode = false
                          }) => {
    const { t } = useTranslation();
    const [selectedCourses, setSelectedCourses] = useState(new Set());
    const hasChanges = courses.some(course => course.has_changes);

    // Reset selectedCourses when modal is closed
    useEffect(() => {
        if (!show) {
            setSelectedCourses(new Set());
        }
    }, [show]);

    const handleToggleCourse = (courseId) => {
        const newSelected = new Set(selectedCourses);
        if (newSelected.has(courseId)) {
            newSelected.delete(courseId);
        } else {
            newSelected.add(courseId);
        }
        setSelectedCourses(newSelected);
    };

    const handleSyncChanges = () => {
        onSync(Array.from(selectedCourses));
        setSelectedCourses(new Set());
    };

    const modalClasses = darkMode ? {
        content: { backgroundColor: '#212529', color: '#fff' },
        header: {
            backgroundColor: '#2c3034',
            borderBottom: '1px solid #373b3e',
            color: '#fff'
        },
        footer: {
            backgroundColor: '#2c3034',
            borderTop: '1px solid #373b3e'
        }
    } : {};

    if (loading) {
        return (
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Body className="text-center p-5">
                    <Spinner animation="border" />
                    <p className="mt-3">{t('checkingChanges')}</p>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            contentClassName={darkMode ? 'bg-dark text-light' : ''}
        >
            <Modal.Header
                closeButton
                closeVariant={darkMode ? 'white' : undefined}
                style={modalClasses.header}
            >
                <Modal.Title>{t('syncChanges')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={modalClasses.content}>
                {error && (
                    <Alert variant="danger" className="mb-3">
                        {error}
                    </Alert>
                )}

                {!hasChanges ? (
                    <Alert variant="success">
                        {t('allCoursesSync')}
                    </Alert>
                ) : (
                    <Accordion className={darkMode ? 'dark' : ''}>
                        {courses.map(course => {
                            if (!course.has_changes) return null;

                            return (
                                <Accordion.Item key={course.course_instance_id} eventKey={course.course_instance_id}>
                                    <Accordion.Header>
                                        <div className="d-flex align-items-center w-100">
                                            <input
                                                type="checkbox"
                                                className="me-2"
                                                checked={selectedCourses.has(course.course_instance_id)}
                                                onChange={() => handleToggleCourse(course.course_instance_id)}
                                                onClick={e => e.stopPropagation()}
                                            />
                                            <span>{course.course_name}</span>
                                            <Badge bg="warning" text="dark" className="ms-2">
                                                {t('syncRequired')}
                                            </Badge>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className={darkMode ? 'bg-dark text-light' : ''}>
                                        {/* Nowe tematy */}
                                        <div className="mb-3">
                                            <h6>{t('newTopics')}:</h6>
                                            {course.changes.added_topics.length > 0 ? (
                                                <ul className="list-unstyled">
                                                    {course.changes.added_topics.map(topic => (
                                                        <li key={topic.id} className="mb-2">
                                                            <div className="d-flex align-items-center">
                                                                <span>{topic.name}</span>
                                                                <Badge bg="info" className="ms-2">
                                                                    {t('tasksCount', { count: topic.tasks_count })}
                                                                </Badge>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p className="text-muted mb-0">{t('noNewTopics')}</p>
                                            )}
                                        </div>

                                        {/* Zmiany w istniejących tematach */}
                                        {course.changes.topics_with_task_changes.length > 0 && (
                                            <div>
                                                <h6>{t('changesInTopics')}:</h6>
                                                <ul className="list-unstyled">
                                                    {course.changes.topics_with_task_changes.map(topic => (
                                                        <li key={topic.topic_id} className="mb-3">
                                                            <div className="ps-3 border-start">
                                                                <strong>{topic.topic_name}</strong>
                                                                {topic.added_tasks.length > 0 && (
                                                                    <div className="mt-2">
                                                                        <span className="text-muted">{t('newTasks')}:</span>
                                                                        <ul>
                                                                            {topic.added_tasks.map(task => (
                                                                                <li key={task.id}>
                                                                                    {task.name}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                )}
            </Modal.Body>
            <Modal.Footer style={modalClasses.footer}>
                <Button
                    variant={darkMode ? "outline-light" : "secondary"}
                    onClick={onHide}
                >
                    {t('close')}
                </Button>
                {hasChanges && (
                    <Button
                        variant="primary"
                        onClick={handleSyncChanges}
                        disabled={selectedCourses.size === 0 || isSyncing}
                    >
                        {isSyncing ? t('syncing') : t('syncSelected')}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default TeacherSyncModal;
