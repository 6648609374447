import React, { useState, useEffect } from 'react';
import { useFetch, usePost } from '../../hooks';
import { useTranslation } from 'react-i18next';
import SchoolsView from './SchoolsView';

const SchoolsContainer = () => {
    const { t } = useTranslation();
    const { data, loading, error, fetchData } = useFetch('ADMIN_SCHOOLS');
    const { data: statusesData } = useFetch('STATUSES');
    const { postData, response: deleteResponse, error: deleteError, loading: deleteLoading } = usePost();

    const [sortConfig, setSortConfig] = useState({
        key: 'id',
        direction: 'asc'
    });

    const [showDelete, setShowDelete] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedSchoolId, setSelectedSchoolId] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        description: '',
        address: '',
        school_year: new Date().getFullYear(),
        capacity: '',
        phone: '',
        email: '',
        status: 'active'
    });

    const handleCloseForm = () => {
        setShowForm(false);
        setEditMode(false);
        setFormData({
            name: '',
            code: '',
            description: '',
            address: '',
            school_year: new Date().getFullYear(),
            capacity: '',
            phone: '',
            email: '',
            status: 'active'
        });
    };

    const handleShowForm = (school = null) => {
        if (school) {
            setFormData({
                name: school.name,
                code: school.code,
                description: school.description,
                address: school.address,
                school_year: school.school_year,
                capacity: school.capacity,
                phone: school.phone,
                email: school.email,
                status: school.status
            });
            setSelectedSchoolId(school.id);
            setEditMode(true);
        } else {
            setFormData({
                name: '',
                code: '',
                description: '',
                address: '',
                school_year: new Date().getFullYear(),
                capacity: '',
                phone: '',
                email: '',
                status: 'active'
            });
        }
        setShowForm(true);
    };

    const handleShowDelete = (schoolId) => {
        setSelectedSchoolId(schoolId);
        setShowDelete(true);
    };

    const handleCloseDelete = () => setShowDelete(false);

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const confirmDelete = async () => {
        try {
            await postData('ADMIN_SCHOOL_DELETE', null, [selectedSchoolId]);
            fetchData();
            setShowDelete(false);
        } catch (error) {
            console.error('Failed to delete school:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (editMode) {
                await postData('ADMIN_SCHOOL_UPDATE', formData, [selectedSchoolId]);
            } else {
                await postData('ADMIN_SCHOOL_ADD', formData);
            }
            fetchData();
            handleCloseForm();
        } catch (error) {
            console.error('Failed to save school:', error);
        }
    };

    const handleChange = (event) => {
        const value = event.target.type === 'number' ?
            (event.target.value === '' ? '' : parseInt(event.target.value, 10)) :
            event.target.value;
        setFormData({ ...formData, [event.target.name]: value });
    };

    useEffect(() => {
        if (deleteResponse) fetchData();
    }, [deleteResponse, fetchData]);

    const deleteModalTexts = {
        title: t('confirmDeletion'),
        body: t('school_confirmDeletionMessage'),
        cancel: t('cancel'),
        confirm: t('delete'),
    };

    // Sortowanie danych przed przekazaniem do widoku
    const sortedData = data?.schools ? [...data.schools].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
    }) : [];

    const statusOptions = statusesData?.school?.map(status => ({
        id: status.value,    // zmiana z value na id
        name: status.label   // zmiana z label na name
    })) || [];

    return (
        <SchoolsView
            loading={loading || deleteLoading}
            error={error}
            deleteError={deleteError}
            deleteResponse={deleteResponse}
            data={sortedData}
            showDelete={showDelete}
            showForm={showForm}
            editMode={editMode}
            formData={formData}
            handleCloseForm={handleCloseForm}
            handleShowForm={handleShowForm}
            handleShowDelete={handleShowDelete}
            handleCloseDelete={handleCloseDelete}
            confirmDelete={confirmDelete}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            deleteModalTexts={deleteModalTexts}
            sortConfig={sortConfig}
            onSort={handleSort}
            statusOptions={statusOptions}
        />
    );
};

export default SchoolsContainer;
