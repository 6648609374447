import React, { useState } from 'react';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import { useNavigate, useParams } from 'react-router-dom';
import TeacherTopicsView from './TeacherTopicsView';

const TeacherTopicsContainer = () => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const { darkMode } = useTheme();

    // Pobieramy tematy używając endpointu dla studenta (który zwraca też info o prawach)
    const {
        data,
        loading,
        error,
        fetchData: refreshTopics
    } = useFetch('TEACHER_COURSE_INSTANCE_TOPICS', [courseId]);

    const { postData, loading: actionLoading, error: actionError } = usePost();

    // Stan dla modalu edycji instancji tematu
    const [showInstanceForm, setShowInstanceForm] = useState(false);
    const [selectedTopicId, setSelectedTopicId] = useState(null);
    const [instanceFormData, setInstanceFormData] = useState({
        is_visible: true,
        visible_from: '',
        visible_until: '',
        custom_time_limit: '',
        custom_attempts_allowed: '',
        custom_can_see_results: false
    });

    // Handlers dla formularza instancji
    const handleShowInstanceForm = (topic) => {
        setSelectedTopicId(topic.id);
        setInstanceFormData({
            is_visible: topic.is_visible,
            visible_from: topic.visible_from ? topic.visible_from.slice(0, 16) : '',
            visible_until: topic.visible_until ? topic.visible_until.slice(0, 16) : '',
            custom_time_limit: topic.custom_time_limit || '',
            custom_attempts_allowed: topic.custom_attempts_allowed || '',
            custom_can_see_results: topic.custom_can_see_results ?? false
        });
        setShowInstanceForm(true);
    };

    const handleCloseInstanceForm = () => {
        setShowInstanceForm(false);
        setSelectedTopicId(null);
        setInstanceFormData({
            is_visible: true,
            visible_from: '',
            visible_until: '',
            custom_time_limit: '',
            custom_attempts_allowed: '',
            custom_can_see_results: false
        });
    };

    const handleInstanceSubmit = async (event) => {
        event.preventDefault();
        if (!selectedTopicId) return;

        try {
            await postData(
                'TEACHER_TOPIC_INSTANCE_UPDATE',
                instanceFormData,
                [selectedTopicId]
            );
            handleCloseInstanceForm();
            refreshTopics();
        } catch (err) {
            console.error('Failed to update topic instance:', err);
        }
    };

    const handleInstanceFormChange = (e) => {
        const { name, value } = e.target;
        setInstanceFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const topicActions = {
        onViewTasks: (topicId) => navigate(`/teacher/tasks/${topicId}`),
        onBackToCourses: () => navigate('/teacher/courses'),
        onEdit: handleShowInstanceForm
    };

    return (
        <TeacherTopicsView
            topics={data?.topics || []}
            loading={loading || actionLoading}
            error={error || actionError}
            darkMode={darkMode}
            actions={topicActions}
            showInstanceForm={showInstanceForm}
            instanceFormData={instanceFormData}
            selectedTopicId={selectedTopicId}
            onCloseInstanceForm={handleCloseInstanceForm}
            onInstanceFormChange={handleInstanceFormChange}
            onInstanceFormSubmit={handleInstanceSubmit}
        />
    );
};

export default TeacherTopicsContainer;
