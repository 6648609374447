import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const StudentExamStartModal = ({ show, onClose, onConfirm, exam }) => {
    const { t } = useTranslation();

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('startExamConfirmation')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <h5 className="text-primary">{exam?.topic_name}</h5>
                    <hr />
                    <div className="mb-3">
                        <strong>{t('timeSettings')}:</strong>
                        <ul className="list-unstyled mt-2">
                            <li>
                                <i className="bi bi-clock me-2"></i>
                                {exam?.time_limit
                                    ? t('examTimeLimit', { minutes: exam.time_limit })
                                    : t('noTimeLimit')}
                            </li>
                            <li>
                                <i className="bi bi-calendar-event me-2"></i>
                                {t('examEndTime')}: {new Date(exam?.end_date).toLocaleString()}
                            </li>
                        </ul>
                    </div>
                    <div className="mb-3">
                        <strong>{t('examRules')}:</strong>
                        <ul className="mt-2">
                            <li>{t('cannotPauseExam')}</li>
                            <li>
                                {exam?.can_see_results
                                    ? t('resultsAvailableAfterCompletion')
                                    : t('resultsAvailableLater')}
                            </li>
                        </ul>
                    </div>
                    <div className="alert alert-warning">
                        {t('examStartWarning')}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    {t('startExam')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default StudentExamStartModal;
