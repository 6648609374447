import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Table, Spinner, Badge, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ParticipantsModal = ({
                               show,
                               handleClose,
                               loading,
                               error,
                               students = [],
                               selectedStudents,
                               onStudentSelect,
                               onSelectAll,
                               onSave,
                               darkMode
                           }) => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');

    // Reset search when modal closes
    useEffect(() => {
        if (!show) setSearchQuery('');
    }, [show]);

    const filteredStudents = students.filter(student => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        const studentName = `${student.name} ${student.surname}`.toLowerCase();
        const departmentName = (student.department || '').toLowerCase();
        return studentName.includes(searchLower) || departmentName.includes(searchLower);
    });

    const allSelected = filteredStudents.length > 0 &&
        filteredStudents.every(student => selectedStudents.includes(student.id));

    if (loading) {
        return (
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            className={darkMode ? 'modal-dark' : ''}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('participants')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}

                <div className="mb-3">
                    <InputGroup className="w-auto">
                        <Form.Control
                            placeholder={t('searchStudents')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                    </InputGroup>
                </div>

                <Table responsive bordered className={darkMode ? 'table-dark' : ''}>
                    <thead>
                    <tr>
                        <th>
                            <Form.Check
                                type="checkbox"
                                onChange={() => onSelectAll(filteredStudents)}
                                checked={allSelected}
                            />
                        </th>
                        <th>{t('name')}</th>
                        <th>{t('department')}</th>
                        <th>{t('status')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredStudents.map(student => (
                        <tr key={student.id}>
                            <td>
                                <Form.Check
                                    type="checkbox"
                                    checked={selectedStudents.includes(student.id)}
                                    onChange={() => onStudentSelect(student.id)}
                                />
                            </td>
                            <td>{student.name} {student.surname}</td>
                            <td>{student.department}</td>
                            <td>
                                <Badge bg={student.enrolled ? "success" : "secondary"}>
                                    {student.enrolled ? t('enrolled') : t('notEnrolled')}
                                </Badge>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button variant="primary" onClick={onSave}>
                    {t('save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ParticipantsModal;
