import React from 'react';
import TopicsTable from '../components/TopicsTable';
import TopicInstanceForm from '../components/TopicInstanceForm';

const TeacherTopicsView = ({
                               topics,
                               loading,
                               error,
                               darkMode,
                               actions,
                               showInstanceForm,
                               instanceFormData,
                               selectedTopicId,
                               onCloseInstanceForm,
                               onInstanceFormChange,
                               onInstanceFormSubmit
                           }) => {
    // Znajdź wybrany temat do sprawdzenia typu
    const selectedTopic = topics.find(t => t.id === selectedTopicId);

    return (
        <div className="container mt-4">
            <TopicsTable
                topics={topics}
                loading={loading}
                error={error}
                darkMode={darkMode}
                userRole="teacher"
                actions={actions}
            />

            <TopicInstanceForm
                show={showInstanceForm}
                handleClose={onCloseInstanceForm}
                formData={instanceFormData}
                handleChange={onInstanceFormChange}
                handleSubmit={onInstanceFormSubmit}
                error={error}
                loading={loading}
                darkMode={darkMode}
                isExam={selectedTopic?.type === 'exam'}
            />
        </div>
    );
};

export default TeacherTopicsView;
