import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminDataTable from '../../components/generic/AdminDataTable';
import AdminEntityFormModal from '../../components/generic/AdminEntityFormModal';

const DepartmentsView = ({
                             loading,
                             error,
                             deleteError,
                             deleteResponse,
                             data,
                             showDelete,
                             showForm,
                             editMode,
                             formData,
                             handleCloseForm,
                             handleShowForm,
                             handleShowDelete,
                             handleCloseDelete,
                             confirmDelete,
                             handleSubmit,
                             handleChange,
                             deleteModalTexts
                         }) => {
    const navigate = useNavigate();

    const departments = data?.departments || [];

    const columns = [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'name', sortable: true },
        { key: 'description', label: 'description', sortable: true },
        { key: 'year', label: 'year', sortable: true }
    ];

    const handleViewDepartment = (departmentId) => {
        navigate(`/admin/departments/${departmentId}/users`);
    };

    const actions = [
        { label: 'info', variant: 'info', onClick: (dep) => handleViewDepartment(dep.id) },
        { label: 'edit', variant: 'secondary', onClick: handleShowForm },
        { label: 'delete', variant: 'danger', onClick: (dep) => handleShowDelete(dep.id) }
    ];

    const headerButtons = [
        { label: 'addDepartment', variant: 'primary', onClick: () => handleShowForm() }
    ];

    const formFields = [
        { name: 'name', type: 'text', label: 'department_name', required: true },
        { name: 'description', type: 'text', label: 'department_description' },
        { name: 'year', type: 'number', label: 'department_year', required: true }
    ];

    return (
        <>
            <AdminDataTable
                loading={loading}
                error={error}
                actionError={deleteError}
                actionResponse={deleteResponse}
                title="departments"
                data={departments}
                columns={columns}
                actions={actions}
                headerButtons={headerButtons}
                sortConfig={null}
            />

            <AdminEntityFormModal
                show={showForm}
                handleClose={handleCloseForm}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                error={error}
                loading={loading}
                isEdit={editMode}
                title="Department"
                fields={formFields}
            />

            <DeleteModal
                show={showDelete}
                handleClose={handleCloseDelete}
                handleConfirm={confirmDelete}
                texts={deleteModalTexts}
            />
        </>
    );
};

export default DepartmentsView;
