import { useState, useEffect, useCallback } from 'react';
import usePost from './usePost';

export const useSnapshotSender = (taskId, content, isExample = false, taskType = null, isExam = false) => {
    const { postData } = usePost();
    const [lastSentContent, setLastSentContent] = useState(content);
    const [lastSentTime, setLastSentTime] = useState(0);

    const sendSnapshot = useCallback(async (taskId, content) => {
        if (!taskId) return;

        try {
            await postData(
                'STUDENT_SEND_SNAPSHOTS',
                {
                    taskInstanceId: taskId, 
                    progress: content
                },
                [taskId]
            );
        } catch (error) {
            console.error('Błąd podczas wysyłania snapshotu:', error);
        }
    }, [postData]);

    // Automatyczne wysyłanie co jakiś czas
    useEffect(() => {
        // Sprawdzamy czy to jest zadanie typu 'task' i czy to nie jest egzamin
        if (isExample || !taskId || taskType !== 'task' || isExam) return;

        const interval = setInterval(() => {
            const now = new Date();
            const currentTime = now.getTime() / 1000;

            // Wysyłamy tylko jeśli zawartość się zmieniła i minęła sekunda
            if (content !== lastSentContent && (currentTime - lastSentTime) >= 1) {
                sendSnapshot(taskId, content);
                setLastSentContent(content);
                setLastSentTime(currentTime);
            }
        }, 100); // Sprawdzamy co 100ms

        return () => clearInterval(interval);
    }, [taskId, content, lastSentContent, lastSentTime, sendSnapshot, isExample, taskType, isExam]);

    return { sendSnapshot };
};

export default useSnapshotSender;
