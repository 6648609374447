import React from 'react';
import { Navbar, Nav, NavDropdown, Button, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { ThemeToggle } from './ThemeToggle';

const MenuView = ({
                      username,
                      role,
                      examStatus,
                      examStatusError,
                      timeLeft,
                      shouldShowCourses,
                      onNavigate,
                      onLogout,
                      onChangeLanguage,
                      onCheckChanges
                  }) => {
    const { t } = useTranslation();

    const showTokenInfo = role === 'admin' || timeLeft === 'Token expired' || timeLeft === 'No token';
    const tokenInfoClass = (timeLeft === 'Token expired' || timeLeft === 'No token') ? 'text-danger' : '';

    return (
        <div className="menu-container h-auto min-h-fit-content">
            <Navbar expand={true} className="bg-body-tertiary px-3">
                <LinkContainer to="/">
                    <Navbar.Brand>BitWizards</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {(role === 'student' || role === 'teacher') && (
                            <>
                                {shouldShowCourses && (
                                    <Button
                                        variant="btn"
                                        onClick={() => onNavigate(role === 'teacher' ? '/teacher/courses' : '/student/courses')}
                                    >
                                        {t('courses')}
                                    </Button>
                                )}
                                <Button
                                    variant="btn"
                                    onClick={() => onNavigate(role === 'teacher' ? '/teacher/exams' : '/student/exams')}
                                >
                                    {t('exams')}
                                </Button>
                                {role === 'teacher' && (
                                    <Button
                                        variant="btn"
                                        onClick={onCheckChanges}
                                    >
                                        {t('checkForChanges')}
                                    </Button>
                                )}
                            </>
                        )}

                        {role === 'admin' && (
                            <>
                                <Button
                                    variant="btn"
                                    onClick={() => onNavigate('/admin/courses')}
                                >
                                    {t('courses')}
                                </Button>
                                <NavDropdown
                                    title={t('adminTools')}
                                    id="admin-nav-dropdown"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <NavDropdown.Item onClick={() => onNavigate('/admin/users-table')}>
                                        {t('users')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => onNavigate('/admin/departments-table')}>
                                        {t('departments')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => onNavigate('/admin/schools-table')}>
                                        {t('schools')}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </>
                        )}
                    </Nav>

                    <Nav className="d-flex align-items-center">
                        {showTokenInfo && (
                            <Nav.Item className="me-3">
                                <span className={tokenInfoClass}>
                                    {t('tokenExpiresIn', { time: timeLeft })}
                                </span>
                            </Nav.Item>
                        )}

                        <Nav.Item className="me-2">
                            <ThemeToggle />
                        </Nav.Item>
                        <NavDropdown title={t('Language')} id="language-nav-dropdown">
                            <NavDropdown.Item onClick={() => onChangeLanguage('pl')}>
                                {t('Polski')}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => onChangeLanguage('en')}>
                                {t('English')}
                            </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title={username} id="user-nav-dropdown" align="end">
                            <NavDropdown.Item onClick={() => onNavigate('/user-info')}>
                                {t('userInfo')}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={onLogout}>
                                {t('logout')}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default MenuView;
