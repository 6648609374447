import React, { useEffect, useState } from 'react';
import { Nav, Button, Form, Modal, Alert } from 'react-bootstrap';
import { useSnapshotSender } from '../../../hooks/useSnapshotSender';
import ProgressModal from './ProgressModal';
import CourseStudentDropdown from '../teacher/components/dropdowns/CourseStudentDropdown';
import ExamStudentDropdown from '../teacher/components/dropdowns/ExamStudentDropdown';
import styled, { keyframes, css } from 'styled-components';

const THEME_IMPORTS = [
    'github', 'monokai', 'solarized_dark', 'solarized_light', 'twilight',
    'tomorrow', 'kuroir', 'xcode', 'textmate', 'terminal'
];

// Animacja pulsowania
const pulse = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const pulseAnimation = css`
    animation: ${pulse} 1s ease-in-out 3;
`;

const dotAnimation = css`
    animation: ${pulse} 2s infinite;
`;

const ConsoleButton = styled(Button)`
    position: relative;
    transition: all 0.3s ease;

    ${props => props.$hasNewLogs && !props.$showConsole && css`
        ${pulseAnimation}

        &::after {
            content: '';
            position: absolute;
            top: -5px;
            right: -5px;
            width: 10px;
            height: 10px;
            background-color: #dc3545;
            border-radius: 50%;
            ${dotAnimation}
        }
    `}
`;

const TasksTopNavbar = ({
                            theme,
                            setTheme,
                            onRunCode,
                            outputMode,
                            setOutputMode,
                            onResetCode,
                            userRole,
                            canEdit,
                            saveLoading,
                            isEditing,
                            isAdding,
                            onEditClick,
                            fontSize,
                            onFontSizeChange,
                            taskId,
                            editorContent,
                            taskDetails,
                            showConsole,
                            setShowConsole,
                            consoleLogCount,
                            courseStudents = [],
                            onStudentSelect,
                            selectedStudent,
                            isExam,
                            examId,
                            attemptId
                        }) => {
    const [showResetModal, setShowResetModal] = React.useState(false);
    const [showProgressModal, setShowProgressModal] = React.useState(false);
    const [hasNewLogs, setHasNewLogs] = useState(false);
    const [prevLogCount, setPrevLogCount] = useState(consoleLogCount);
    const { sendSnapshot } = useSnapshotSender(taskId, editorContent);

    useEffect(() => {
        setHasNewLogs(false);
        setPrevLogCount(0);
    }, [taskId]);

    useEffect(() => {
        if (consoleLogCount > prevLogCount) {
            setHasNewLogs(true);
            setPrevLogCount(consoleLogCount);

            if (showConsole) {
                setHasNewLogs(false);
            }
        }
    }, [consoleLogCount, prevLogCount, showConsole]);

    useEffect(() => {
        if (showConsole) {
            setHasNewLogs(false);
        }
    }, [showConsole]);

    const handleIncreaseFontSize = () => {
        onFontSizeChange(fontSize + 1);
    };

    const handleDecreaseFontSize = () => {
        onFontSizeChange(Math.max(8, fontSize - 1));
    };

    const handleReset = async () => {
        setShowResetModal(false);
        await onResetCode();

        if (taskId) {
            await sendSnapshot(taskId, editorContent);
        }
    };

    const handleRunClick = () => {
        setHasNewLogs(false);
        setPrevLogCount(0);

        onRunCode();
        if (taskId) {
            sendSnapshot(taskId, editorContent);
        }
    };

    const handleStudentSelect = (student) => {
        setShowProgressModal(false);
        onStudentSelect(student);
    };

    const handleConsoleClick = () => {
        setShowConsole(!showConsole);
        if (!showConsole) {
            setHasNewLogs(false);
        }
    };

    const isTask = taskDetails?.type === 'task';
    const showStudentList = (userRole === 'admin' || userRole === 'teacher') && (
        isExam
            ? (courseStudents?.students?.length > 0 || courseStudents?.available_students?.length > 0)
            : (Array.isArray(courseStudents) && courseStudents.length > 0)
    );

    const renderStudentDropdown = () => {
        if (!showStudentList) return null;

        if (isExam) {
            return (
                <ExamStudentDropdown
                    examStudents={courseStudents || { students: [], available_students: [] }}
                    selectedStudent={selectedStudent}
                    onStudentSelect={handleStudentSelect}
                />
            );
        }

        return (
            <CourseStudentDropdown
                students={Array.isArray(courseStudents) ? courseStudents : []}
                selectedStudent={selectedStudent}
                onStudentSelect={handleStudentSelect}
            />
        );
    };

    return (
        <>
            {selectedStudent && (
                <Alert variant="info" className="m-0 py-2">
                    Przeglądasz kod studenta: {selectedStudent.name} {selectedStudent.surname}
                </Alert>
            )}
            <Nav className="bg-body-tertiary p-2 align-items-center">
                <Nav.Item>
                    <Button variant="light" className="me-2" onClick={handleRunClick}>
                        Uruchom
                    </Button>
                </Nav.Item>
                <Nav.Item>
                    <ConsoleButton
                        variant={showConsole ? "primary" : "light"}
                        className="me-2"
                        onClick={handleConsoleClick}
                        $hasNewLogs={hasNewLogs}
                        $showConsole={showConsole}
                    >
                        Konsola ({consoleLogCount})
                    </ConsoleButton>
                </Nav.Item>
                {isTask && !isEditing && !isAdding && (
                    <>
                        <Nav.Item>
                            <Button variant="light" className="me-2" onClick={() => setShowProgressModal(true)}>
                                Historia zmian
                            </Button>
                        </Nav.Item>
                        <Nav.Item>
                            <Button variant="light" className="me-2" onClick={() => setShowResetModal(true)} disabled={isEditing || isAdding || selectedStudent}>
                                Reset
                            </Button>
                        </Nav.Item>
                        <Nav.Item>
                            {renderStudentDropdown()}
                        </Nav.Item>
                    </>
                )}
                {canEdit && !selectedStudent && taskDetails && (
                    <Nav.Item>
                        <Button
                            variant="light"
                            className="me-2"
                            disabled={saveLoading}
                            onClick={onEditClick}
                        >
                            Edytuj
                        </Button>
                    </Nav.Item>
                )}
                <Nav.Item className="ms-auto d-flex align-items-center me-2">
                    <Button
                        variant={outputMode === 'html' ? "primary" : "light"}
                        className="me-2"
                        onClick={() => setOutputMode(outputMode === 'text' ? 'html' : 'text')}
                    >
                        {outputMode === 'html' ? 'Tryb HTML' : 'Tryb TEXT'}
                    </Button>
                    <Button
                        variant="light"
                        className="me-1 px-2 py-1"
                        onClick={handleDecreaseFontSize}
                        title={`Zmniejsz czcionkę (obecnie: ${fontSize}px)`}
                    >
                        A-
                    </Button>
                    <Button
                        variant="light"
                        className="px-2 py-1"
                        onClick={handleIncreaseFontSize}
                        title={`Zwiększ czcionkę (obecnie: ${fontSize}px)`}
                    >
                        A+
                    </Button>
                </Nav.Item>
                <Nav.Item>
                    <Form.Select
                        value={theme}
                        onChange={(e) => setTheme(e.target.value)}
                        className="me-2"
                    >
                        {THEME_IMPORTS.map(theme => (
                            <option key={theme} value={theme}>
                                {theme.charAt(0).toUpperCase() + theme.slice(1)}
                            </option>
                        ))}
                    </Form.Select>
                </Nav.Item>

                {/* Modals */}
                <Modal show={showResetModal} onHide={() => setShowResetModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Potwierdź reset</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Czy na pewno chcesz zresetować swój kod? Ta operacja nie może być cofnięta.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowResetModal(false)}>
                            Anuluj
                        </Button>
                        <Button variant="danger" onClick={handleReset}>
                            Reset
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ProgressModal
                    show={showProgressModal}
                    handleClose={() => setShowProgressModal(false)}
                    taskId={taskId}
                    studentId={selectedStudent?.id}
                    userRole={userRole}
                    isExam={isExam}
                    examId={examId}
                    attemptId={attemptId}
                />
            </Nav>
        </>
    );
};

export default TasksTopNavbar;
