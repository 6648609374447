import React from 'react';
import TopicsTable from '../components/TopicsTable';
import TopicForm from '../components/TopicForm';
import DeleteModal from '../../../components/modals/DeleteModal';

const AdminTopicsView = ({
                             topics,
                             loading,
                             error,
                             darkMode,
                             actions,
                             showTopicForm,
                             topicFormData,
                             onTopicFormChange,
                             onTopicFormSubmit,
                             onCloseTopicForm,
                             showDelete,
                             onCloseDelete,
                             onConfirmDelete,
                             modalTexts
                         }) => {
    return (
        <div className="container mt-4">
            <TopicsTable
                topics={topics}
                loading={loading}
                error={error}
                darkMode={darkMode}
                userRole="admin"
                actions={actions}
            />

            <TopicForm
                show={showTopicForm}
                handleClose={onCloseTopicForm}
                formData={topicFormData}
                handleChange={onTopicFormChange}
                handleSubmit={onTopicFormSubmit}
                error={error}
                loading={loading}
                darkMode={darkMode}
            />

            <DeleteModal
                show={showDelete}
                handleClose={onCloseDelete}
                handleConfirm={onConfirmDelete}
                texts={modalTexts.delete}
                darkMode={darkMode}
            />
        </div>
    );
};

export default AdminTopicsView;
