import React from 'react';

const withAuth = (Component) => {
    return (props) => {
        if (!sessionStorage.getItem('access_token')) {
            // Przekierowanie do strony logowania
            window.location = '/';
        }

        // Renderowanie komponentu, jeśli użytkownik jest zalogowany
        return <Component {...props} />;
    };
};

export default withAuth;
