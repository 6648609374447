import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS, buildApiUrl } from '../config/endpoints';

const usePost = () => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const postData = useCallback(async (endpointKey, data = null, params = []) => {
        if (!API_ENDPOINTS[endpointKey]) {
            throw new Error(`Endpoint ${endpointKey} not found`);
        }

        setLoading(true);
        setError(null);

        try {
            const endpoint = API_ENDPOINTS[endpointKey];
            const url = buildApiUrl(endpoint, ...params);

            const result = await axios({
                method: endpoint.method,
                url,
                data,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });

            setResponse(result.data);
            return result.data;
        } catch (err) {
            const errorMessage = err.response?.data?.message || err.message;
            setError(errorMessage);
            if (err.response?.status === 401) {
                sessionStorage.removeItem('access_token');
                navigate('/');
            }
            throw err;
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    return { response, loading, error, postData };
};

export default usePost;
