import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import { useExamSnapshotSender } from '../../../hooks/useExamSnapshotSender';
import StudentTasksView from '../../tasks/student/StudentTasksView';

const StudentExamTasksContainer = () => {
    const { examId, attemptId } = useParams();
    const navigate = useNavigate();
    const { darkMode } = useTheme();
    const [theme, setTheme] = useState(() => darkMode ? 'monokai' : 'github');
    const [editorContent, setEditorContent] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [outputMode, setOutputMode] = useState('html');
    const [codeOutput, setCodeOutput] = useState('');
    const [runCounter, setRunCounter] = useState(0);
    const [showConsole, setShowConsole] = useState(false);
    const [consoleLogCount, setConsoleLogCount] = useState(0);

    const { data: examData } = useFetch(
        'STUDENT_EXAM_DETAILS',
        examId ? [examId] : null
    );

    const { data: attemptData } = useFetch(
        'STUDENT_EXAM_ATTEMPT_DETAILS',
        attemptId ? [attemptId] : null
    );

    const { data: tasksList, fetchData: refreshTasksList } = useFetch(
        'STUDENT_EXAM_TASKS',
        examId ? [examId] : null
    );

    const {
        data: taskDetails,
        loading: taskLoading,
        error: taskError,
        fetchData: refreshTaskDetails
    } = useFetch(
        selectedTaskId ? 'STUDENT_EXAM_TASK_DETAILS' : null,
        selectedTaskId ? [examId, selectedTaskId] : null
    );

    const { postData } = usePost();

    // Używamy hooka do snapshotów egzaminacyjnych
    const { sendSnapshot } = useExamSnapshotSender(attemptId, selectedTaskId, editorContent, {
        enabled: !!attemptId && !!selectedTaskId
    });

    const handleTimeEnd = async () => {
        try {
            await postData('STUDENT_EXAM_COMPLETE', {}, [attemptId]);
            navigate('/student/exams');
        } catch (error) {
            console.error('Error completing exam:', error);
        }
    };

    const handleRunCode = async () => {
        setConsoleLogCount(0);
        const isJavaScript = taskDetails?.language === 'javascript';

        try {
            if (isJavaScript) {
                setCodeOutput(editorContent);
                setRunCounter(prev => prev + 1);
            } else {
                const response = await postData('EXECUTE_CODE', {
                    code: editorContent,
                    language: taskDetails?.language
                });
                setCodeOutput(response.output || response.error || 'No output');
                setRunCounter(prev => prev + 1);
            }
            // Zapisz snapshot po wykonaniu kodu
            await sendSnapshot(true);
        } catch (error) {
            console.error('Error executing code:', error);
            setCodeOutput(`Error: ${error.message}`);
            setRunCounter(prev => prev + 1);
        }
    };

    const handleTaskSelect = (taskId) => {
        if (taskId === selectedTaskId) return;
        setSelectedTaskId(taskId);
        setCodeOutput('');
        setConsoleLogCount(0);
    };

    const handleExamEnd = async () => {
        try {
            // Zapisz ostatni snapshot przed zakończeniem
            await sendSnapshot(true);
            await postData('STUDENT_EXAM_COMPLETE', {}, [attemptId]);
            navigate('/student/exams');
        } catch (error) {
            console.error('Error ending exam:', error);
        }
    };

    useEffect(() => {
        if (selectedTaskId && examId) {
            refreshTaskDetails();
        }
    }, [selectedTaskId, examId, refreshTaskDetails]);

    useEffect(() => {
        if (examId) {
            refreshTasksList();
        }
    }, [examId, refreshTasksList]);

    useEffect(() => {
        if (taskDetails && 'code' in taskDetails) {
            setEditorContent(taskDetails.code || '');
        }
    }, [taskDetails]);

    useEffect(() => {
        setTheme(darkMode ? 'monokai' : 'github');
    }, [darkMode]);

    useEffect(() => {
        if (attemptData?.is_completed) {
            navigate('/student/exams');
        }
    }, [attemptData, navigate]);

    return (
        <StudentTasksView
            tasksList={tasksList}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={handleTaskSelect}
            taskDetails={taskDetails}
            taskLoading={taskLoading}
            taskError={taskError}
            theme={theme}
            setTheme={setTheme}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            outputMode={outputMode}
            setOutputMode={setOutputMode}
            codeOutput={codeOutput}
            onRunCode={handleRunCode}
            runCounter={runCounter}
            showConsole={showConsole}
            setShowConsole={setShowConsole}
            consoleLogCount={consoleLogCount}
            setConsoleLogCount={setConsoleLogCount}
            isExam={true}
            examStartTime={attemptData?.start_time}
            examTimeLimit={examData?.time_limit}
            onExamTimeEnd={handleTimeEnd}
            onExamEnd={handleExamEnd}
            examId={examId}
            attemptId={attemptId}
        />
    );
};

export default StudentExamTasksContainer;
