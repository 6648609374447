import React, { useState } from 'react';
import { useFetch, usePost } from '../../../hooks';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import ExamsView from '../components/ExamsView';
import StudentExamStartModal from './StudentExamStartModal';
import StudentExamResultsModal from './StudentExamResultsModal';

const StudentExamsContainer = () => {
    const { t } = useTranslation();
    const { data, loading, error } = useFetch('STUDENT_EXAM_SCHEDULES');
    const { postData, loading: actionLoading, error: actionError } = usePost();
    const [searchQuery, setSearchQuery] = useState('');
    const [filterType, setFilterType] = useState('current');
    const [selectedExam, setSelectedExam] = useState(null);
    const [showStartModal, setShowStartModal] = useState(false);
    const navigate = useNavigate();
    const [showResultsModal, setShowResultsModal] = useState(false);
    const [selectedExamForResults, setSelectedExamForResults] = useState(null);

    const handleStartExam = async (exam) => {
        setSelectedExam(exam);
        setShowStartModal(true);
    };

    const handleConfirmStart = async () => {
        try {
            const response = await postData('STUDENT_EXAM_START', {}, [selectedExam.id]);
            if (response.attempt_id) {
                navigate(`/student/exam/${selectedExam.id}/attempt/${response.attempt_id}`);
            }
        } catch (error) {
            console.error('Error starting exam:', error);
        } finally {
            setShowStartModal(false);
            setSelectedExam(null);
        }
    };

    const handleCloseModal = () => {
        setShowStartModal(false);
        setSelectedExam(null);
    };

    const handleContinueExam = (exam) => {
        if (exam.current_attempt_id) {
            navigate(`/student/exam/${exam.id}/attempt/${exam.current_attempt_id}`);
        }
    };

    const handleViewResults = (exam) => {
        setSelectedExamForResults(exam);
        setShowResultsModal(true);
    };

    const getExamStatus = (exam) => {
        const now = new Date();
        const startDate = new Date(exam.start_date);
        const endDate = new Date(exam.end_date);

        if (!exam.is_active) return 'inactive';

        // Jeśli egzamin został wykonany (ma zakończoną próbę), traktujemy go jako przeszły
        if (exam.has_completed_attempt) {
            return 'past';
        }

        // Jeśli egzamin jeszcze się nie rozpoczął
        if (startDate > now) {
            return 'upcoming';
        }

        // Jeśli egzamin się już zakończył czasowo
        if (endDate < now) {
            return 'past';
        }

        // W przeciwnym razie jest bieżący
        return 'current';
    };

    const renderCustomActions = (exam) => {
        const status = getExamStatus(exam);
        const hasActiveAttempt = exam.current_attempt_id != null;

        switch (status) {
            case 'past':
                // Dla przeszłych egzaminów - tylko wyniki jeśli są dostępne i egzamin został wykonany
                if (exam.can_see_results && exam.has_completed_attempt) {
                    return (
                        <Button
                            variant="success"
                            size="sm"
                            onClick={() => handleViewResults(exam)}
                        >
                            {t('viewResults')}
                        </Button>
                    );
                }
                return null;

            case 'current':
                // Dla bieżących egzaminów:
                if (hasActiveAttempt) {
                    // Jeśli jest aktywna próba - tylko przycisk kontynuacji
                    return (
                        <Button
                            variant="warning"
                            size="sm"
                            onClick={() => handleContinueExam(exam)}
                        >
                            {t('continueExam')}
                        </Button>
                    );
                } else if (exam.has_completed_attempt) {
                    // Jeśli egzamin został zakończony i można zobaczyć wyniki
                    if (exam.can_see_results) {
                        return (
                            <Button
                                variant="success"
                                size="sm"
                                onClick={() => handleViewResults(exam)}
                            >
                                {t('viewResults')}
                            </Button>
                        );
                    }
                    // Jeśli egzamin zakończony ale wyniki niedostępne - brak przycisku
                    return null;
                } else if (exam.can_start) {
                    // Jeśli można rozpocząć egzamin (nie ma żadnych prób)
                    return (
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => handleStartExam(exam)}
                        >
                            {t('startExam')}
                        </Button>
                    );
                }
                return null;

            case 'upcoming':
                // Dla przyszłych egzaminów - brak przycisków
                return null;

            default:
                return null;
        }
    };

    const filteredExams = data?.schedules?.filter(exam => {
        if (searchQuery) {
            const searchLower = searchQuery.toLowerCase();
            if (!exam.topic_name.toLowerCase().includes(searchLower)) {
                return false;
            }
        }

        const status = getExamStatus(exam);
        switch (filterType) {
            case 'current':
                return status === 'current';
            case 'upcoming':
                return status === 'upcoming';
            case 'past':
                return status === 'past';
            default:
                return true;
        }
    });

    return (
        <>
            <ExamsView
                loading={loading || actionLoading}
                error={error || actionError}
                exams={filteredExams || []}
                searchQuery={searchQuery}
                filterType={filterType}
                onSearchChange={setSearchQuery}
                onFilterChange={setFilterType}
                isTeacher={false}
                renderCustomActions={renderCustomActions}
            />

            <StudentExamStartModal
                show={showStartModal}
                onClose={handleCloseModal}
                onConfirm={handleConfirmStart}
                exam={selectedExam}
            />

            <StudentExamResultsModal
                show={showResultsModal}
                handleClose={() => {
                    setShowResultsModal(false);
                    setSelectedExamForResults(null);
                }}
                examId={selectedExamForResults?.id}
            />
        </>
    );
};

export default StudentExamsContainer;
