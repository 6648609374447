import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch, usePost } from '../../hooks';
import DepartmentUsersView from './DepartmentUsersView';

const DepartmentUsersContainer = () => {
    const { departmentId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    // Fetche
    const { data, loading, error, fetchData } = useFetch('ADMIN_DEPARTMENT_USERS', [departmentId]);
    const { data: rolesData, loading: rolesLoading } = useFetch('ADMIN_ROLES');
    const { postData, response: actionResponse, error: actionError, loading: actionLoading } = usePost();

    // Get schoolId from URL if it exists
    const schoolId = location.pathname.includes('/schools/')
        ? location.pathname.split('/schools/')[1].split('/')[0]
        : null;

    // Stan sortowania
    const [sortConfig, setSortConfig] = useState({
        key: 'id',
        direction: 'asc'
    });

    // Stany modala i formularza
    const [showDelete, setShowDelete] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        name: '',
        surname: '',
        role_id: '',
        active: true,
        department_id: departmentId
    });

    // Funkcja sortująca
    const sortData = (users, sortKey, direction) => {
        if (!users) return [];

        return [...users].sort((a, b) => {
            let aValue = a[sortKey];
            let bValue = b[sortKey];

            if (sortKey === 'active') {
                return direction === 'asc' ?
                    Number(aValue) - Number(bValue) :
                    Number(bValue) - Number(aValue);
            }

            if (typeof aValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setEditMode(false);
        setFormData({
            username: '',
            password: '',
            email: '',
            name: '',
            surname: '',
            role_id: '',
            active: true,
            department_id: departmentId
        });
    };

    const handleShowForm = (user = null) => {
        if (user) {
            setFormData({
                username: user.username,
                email: user.email,
                name: user.name,
                surname: user.surname,
                role_id: user.role_id,
                active: user.active,
                department_id: departmentId
            });
            setSelectedUserId(user.id);
            setEditMode(true);
        } else {
            setFormData({
                username: '',
                password: '',
                email: '',
                name: '',
                surname: '',
                role_id: '',
                active: true,
                department_id: departmentId
            });
            setEditMode(false);
        }
        setShowForm(true);
    };

    const handleChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFormData({ ...formData, [event.target.name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (editMode) {
                await postData('ADMIN_USER_UPDATE', formData, [selectedUserId]);
            } else {
                await postData('ADMIN_USER_ADD', formData);
            }
            handleCloseForm();
            fetchData();
        } catch (error) {
            console.error('Błąd podczas zapisywania:', error);
        }
    };

    const handleShowDelete = (userId) => {
        setSelectedUserId(userId);
        setShowDelete(true);
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
        setSelectedUserId(null);
    };

    const confirmDelete = async () => {
        try {
            await postData('ADMIN_USER_DELETE', null, [selectedUserId]);
            handleCloseDelete();
            fetchData();
        } catch (error) {
            console.error('Błąd podczas usuwania:', error);
        }
    };

    useEffect(() => {
        if (actionResponse) {
            fetchData();
        }
    }, [actionResponse, fetchData]);

    const deleteModalTexts = {
        title: t('confirmDeletion'),
        body: t('confirmUserDeletionMessage'),
        cancel: t('cancel'),
        confirm: t('delete'),
    };

    const sortedUsers = sortData(data?.users, sortConfig.key, sortConfig.direction);

    const handleNavigateBack = () => {
        if (schoolId) {
            navigate(`/admin/schools/${schoolId}/departments`);
        } else {
            navigate('/admin/departments-table');
        }
    };

    return (
        <DepartmentUsersView
            loading={loading || rolesLoading || actionLoading}
            error={error}
            actionError={actionError}
            actionResponse={actionResponse}
            departmentName={data?.department?.name}
            users={sortedUsers}
            showDelete={showDelete}
            showForm={showForm}
            editMode={editMode}
            formData={formData}
            roles={rolesData?.roles || []}
            sortConfig={sortConfig}
            deleteModalTexts={deleteModalTexts}
            onSort={handleSort}
            onShowForm={handleShowForm}
            onCloseForm={handleCloseForm}
            onShowDelete={handleShowDelete}
            onCloseDelete={handleCloseDelete}
            onConfirmDelete={confirmDelete}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onNavigateBack={handleNavigateBack}
            departmentId={departmentId}
        />
    );
};

export default DepartmentUsersContainer;
