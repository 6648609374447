import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import AceEditor from 'react-ace';
import { useFetch } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';

const ProgressModal = ({
                           show,
                           handleClose,
                           taskId,
                           studentId,
                           userRole = 'student',
                           isExam = false,
                           examId,
                           attemptId
                       }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { darkMode } = useTheme();

// Wybieramy odpowiedni endpoint w zależności od typu (egzamin/zwykłe zadanie)
    const endpointName = isExam
        ? (userRole === 'teacher' ? 'TEACHER_EXAM_STUDENT_SNAPSHOTS' : 'EXAM_SNAPSHOTS')
        : (userRole === 'teacher' && studentId ? 'TEACHER_STUDENT_PROGRESS' : 'STUDENT_PROGRESS_SNAPSHOTS');

// Poprawione parametry dla endpointów
    const endpointParams = isExam && userRole === 'teacher'
        ? [examId, studentId, taskId]  // Dla egzaminu nauczyciela używamy studentId zamiast attemptId
        : isExam
            ? [examId, attemptId, taskId]  // Dla egzaminu studenta używamy attemptId
            : (userRole === 'teacher' && studentId ? [taskId, studentId] : [taskId]); // Dla zwykłych zadań

    const {
        data: snapshotsData,
        loading,
        error,
        fetchData: refreshSnapshots
    } = useFetch(
        taskId ? endpointName : null,
        taskId ? endpointParams : null
    );

    const snapshots = snapshotsData?.snapshots || [];

    // Resetujemy index kiedy otwieramy modal
    useEffect(() => {
        if (show && snapshots.length > 0) {
            setCurrentIndex(snapshots.length - 1);
        }
    }, [show, snapshots.length]);

    // Odświeżamy dane gdy zmienia się taskId lub show
    useEffect(() => {
        if (show && taskId) {
            refreshSnapshots();
        }
    }, [taskId, show, refreshSnapshots, studentId]);

    const handleSliderChange = (event) => {
        setCurrentIndex(parseInt(event.target.value));
    };

    const modalClasses = darkMode ? {
        content: {
            backgroundColor: '#212529',
            color: '#fff'
        },
        header: {
            backgroundColor: '#2c3034',
            borderBottom: '1px solid #373b3e',
            color: '#fff'
        },
        footer: {
            backgroundColor: '#2c3034',
            borderTop: '1px solid #373b3e'
        }
    } : {};

    const renderExamInfo = (snapshot) => {
        if (!isExam) return null;

        return (
            <div className="mt-2 small">
                <div>Przełączenia zakładek: {snapshot.tab_switches}</div>
                <div>Okno aktywne: {snapshot.window_focused ? 'Tak' : 'Nie'}</div>
                <div>Stan widoczności: {snapshot.tab_visibility}</div>
            </div>
        );
    };

    if (loading) {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                contentClassName={darkMode ? 'bg-dark text-light' : ''}
            >
                <Modal.Header
                    closeButton
                    style={modalClasses.header}
                    closeVariant={darkMode ? 'white' : undefined}
                >
                    <Modal.Title>Historia zmian</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalClasses.content}>
                    Ładowanie historii...
                </Modal.Body>
            </Modal>
        );
    }

    if (error) {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                contentClassName={darkMode ? 'bg-dark text-light' : ''}
            >
                <Modal.Header
                    closeButton
                    style={modalClasses.header}
                    closeVariant={darkMode ? 'white' : undefined}
                >
                    <Modal.Title>Historia zmian</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalClasses.content}>
                    <div className="text-danger">Nie udało się pobrać historii zmian.</div>
                </Modal.Body>
            </Modal>
        );
    }

    const currentSnapshot = snapshots[currentIndex] || { progress: '', timestamp: '' };
    const timestamp = new Date(currentSnapshot.timestamp).toLocaleString();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            contentClassName={darkMode ? 'bg-dark text-light' : ''}
        >
            <Modal.Header
                closeButton
                style={modalClasses.header}
                closeVariant={darkMode ? 'white' : undefined}
            >
                <Modal.Title>
                    Historia zmian
                    {userRole === 'teacher' && studentId && ` (Student ID: ${studentId})`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={modalClasses.content}>
                <div className="mb-3">
                    {snapshots.length > 0 ? (
                        <>
                            <AceEditor
                                mode="html"
                                theme={darkMode ? "monokai" : "github"}
                                name="progress-viewer"
                                value={currentSnapshot.progress}
                                readOnly={true}
                                width="100%"
                                height="400px"
                                fontSize={14}
                                showPrintMargin={false}
                                showGutter={true}
                                highlightActiveLine={false}
                                useWorker={false}
                                setOptions={{
                                    showLineNumbers: true,
                                    tabSize: 2,
                                    showGutterIcons: false,
                                    showFoldWidgets: false,
                                    fadeFoldWidgets: false,
                                    enableBasicAutocompletion: false,
                                    enableLiveAutocompletion: false,
                                    enableSnippets: false
                                }}
                                style={{
                                    borderRadius: '4px',
                                }}
                            />
                            <div className="mt-3">
                                <Form.Group>
                                    <Form.Label
                                        className={darkMode ? 'text-light' : ''}
                                    >
                                        Historia zmian ({timestamp})
                                        {currentSnapshot && isExam && renderExamInfo(currentSnapshot)}
                                    </Form.Label>
                                    <Form.Range
                                        min={0}
                                        max={snapshots.length - 1}
                                        value={currentIndex}
                                        onChange={handleSliderChange}
                                        className={darkMode ? 'form-range-dark' : ''}
                                    />
                                </Form.Group>
                                <div className={darkMode ? 'text-light-50' : 'text-muted'}>
                                    Snapshot {currentIndex + 1} z {snapshots.length}
                                </div>
                            </div>
                        </>
                    ) : (
                        <p className={darkMode ? 'text-light' : ''}>
                            Brak historii zmian dla tego zadania.
                        </p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer style={modalClasses.footer}>
                <Button
                    variant={darkMode ? "outline-light" : "secondary"}
                    onClick={handleClose}
                >
                    Zamknij
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProgressModal;
