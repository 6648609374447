import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/useAuth';
import useApiStatus from '../hooks/useApiStatus';
import LoginPageView from './LoginPageView';

const LoginPageContainer = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login, isAuthenticated, loading: authLoading, error: authError } = useAuth();
    const { apiVersion, error: apiError, checkApiStatus } = useApiStatus();
    const navigate = useNavigate();
    const { darkMode } = useTheme();
    const { i18n } = useTranslation();

    // Sprawdź status API przy montowaniu
    useEffect(() => {
        const initialCheck = async () => {
            await checkApiStatus();
        };
        initialCheck();
    }, [checkApiStatus]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isApiOk = await checkApiStatus();
        if (isApiOk) {
            await login(username, password);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            window.location = '/';
        }

        document.body.style.backgroundColor = darkMode ? '#1a1d20' : '#f8f9fa';
        document.body.style.transition = 'background-color 0.3s ease';

        return () => {
            document.body.style.backgroundColor = '';
            document.body.style.transition = '';
        };
    }, [isAuthenticated, navigate, darkMode]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <LoginPageView
            username={username}
            password={password}
            onUsernameChange={setUsername}
            onPasswordChange={setPassword}
            onSubmit={handleSubmit}
            onLanguageChange={changeLanguage}
            authLoading={authLoading}
            authError={authError}
            apiError={apiError}
            apiVersion={apiVersion}
            darkMode={darkMode}
        />
    );
};

export default LoginPageContainer;
