import React from 'react';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../hooks';

const TopicForm = ({
                       show,
                       handleClose,
                       formData,
                       handleChange,
                       handleSubmit,
                       error,
                       loading,
                       darkMode
                   }) => {
    const { t } = useTranslation();
    const { data: languagesData } = useFetch('LANGUAGES');
    const languages = languagesData || [];

    const isEditMode = Boolean(formData.id);
    const modalTitle = isEditMode ? t('editTopic') : t('addTopic');
    const submitButtonText = isEditMode ? t('save') : t('add');

    return (
        <Modal
            show={show}
            onHide={handleClose}
            className={darkMode ? 'modal-dark' : ''}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {error && <Alert variant="danger">{error}</Alert>}

                    <Form.Group className="mb-3">
                        <Form.Label>{t('topicType')}</Form.Label>
                        <div className="d-flex justify-content-center">
                            <Button
                                variant="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const newType = formData.topic_type === 'topic' ? 'exam' : 'topic';
                                    handleChange({
                                        target: {
                                            name: 'topic_type',
                                            value: newType
                                        }
                                    });
                                }}
                                className="w-100"
                            >
                                {formData.topic_type === 'topic' ? t('regularTopic') : t('examTopic')}
                            </Button>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t('name')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t('description')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t('programmingLanguage')}</Form.Label>
                        <Form.Select
                            name="language"
                            value={formData.language}
                            onChange={handleChange}
                            className={darkMode ? 'bg-dark text-light' : ''}
                        >
                            <option value="">{t('selectLanguage')}</option>
                            {languages.map(lang => (
                                <option key={lang.value} value={lang.value}>
                                    {lang.label}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check
                            type="switch"
                            id="visibility-switch"
                            label={t('isVisible')}
                            name="is_visible"
                            checked={formData.is_visible}
                            onChange={(e) => handleChange({
                                target: {
                                    name: 'is_visible',
                                    value: e.target.checked
                                }
                            })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t('visibleFrom')}</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            name="visible_from"
                            value={formData.visible_from}
                            onChange={handleChange}
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t('visibleUntil')}</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            name="visible_until"
                            value={formData.visible_until}
                            onChange={handleChange}
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                    </Form.Group>

                    {formData.topic_type === 'EXAM' && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('timeLimit')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="time_limit"
                                    value={formData.time_limit}
                                    onChange={handleChange}
                                    placeholder={t('minutesOptional')}
                                    className={darkMode ? 'bg-dark text-light' : ''}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t('attemptsAllowed')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="attempts_allowed"
                                    value={formData.attempts_allowed}
                                    onChange={handleChange}
                                    min="1"
                                    required
                                    className={darkMode ? 'bg-dark text-light' : ''}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="switch"
                                    id="can-see-results-switch"
                                    label={t('canSeeResults')}
                                    name="can_see_results"
                                    checked={formData.can_see_results}
                                    onChange={(e) => handleChange({
                                        target: {
                                            name: 'can_see_results',
                                            value: e.target.checked
                                        }
                                    })}
                                />
                            </Form.Group>
                        </>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {submitButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TopicForm;
