import React from 'react';
import { ListGroup, Spinner, Alert } from 'react-bootstrap';
import styled from 'styled-components';

const ListContainer = styled.div`
    margin-bottom: 10px;
`;

const StyledListGroupItem = styled(ListGroup.Item)`
    background-color: var(--bs-body-bg);
    color: ${props => props.type === 'task' ? 'var(--bs-primary)' : 'var(--bs-secondary)'};
    border-left: 4px solid ${props => props.type === 'task' ? 'var(--bs-primary)' : 'var(--bs-secondary)'};
    transition: all 0.2s ease-in-out;
    padding: 0;

    &:hover:not(.active) {
        background-color: ${props => props.type === 'task'
                ? 'var(--bs-primary-bg-subtle)'
                : 'var(--bs-secondary-bg-subtle)'};
    }

    &.active, &[aria-selected="true"], ${props => props.isSelected && `
        background-color: var(--bs-primary-bg-subtle);
        border-left-color: var(--bs-primary);
    `}
`;

const TaskContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? 0.7 : 1};
    width: 100%;
`;

const TaskMainContent = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
`;

const TaskIcon = styled.span`
    font-size: 1.1em;
    flex-shrink: 0;
    margin-right: 0.5rem;
`;

const TaskName = styled.span`
    word-break: break-word;
    font-weight: ${props => props.isSelected ? '600' : 'normal'};
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TasksListLayout = ({
                             loading,
                             error,
                             items,
                             selectedId,
                             onSelect,
                             renderControls,
                             bottomContent,
                             disabled
                         }) => {
    if (loading) {
        return <Spinner animation="border" role="status" />;
    }

    if (error || !Array.isArray(items)) {
        return <Alert variant="danger">
            {error || 'Błąd: Nieprawidłowy format danych'}
        </Alert>;
    }

    const getTaskIcon = (type) => type === 'task' ? '✏️' : '📖';

    const handleClick = (itemId) => {
        if (!disabled && onSelect) {
            onSelect(itemId);
        }
    };

    return (
        <>
            <ListContainer>
                <ListGroup>
                    {items.map((item) => (
                        <StyledListGroupItem
                            key={item.id}
                            action={!disabled}
                            active={item.id === selectedId}
                            type={item.type}
                            isSelected={item.id === selectedId}
                            className="mb-1"
                        >
                            <TaskContent
                                onClick={() => handleClick(item.id)}
                                disabled={disabled}
                            >
                                <TaskMainContent>
                                    <TaskIcon>{getTaskIcon(item.type)}</TaskIcon>
                                    <TaskName isSelected={item.id === selectedId}>
                                        {item.name}
                                    </TaskName>
                                </TaskMainContent>
                                {!disabled && renderControls && renderControls(item)}
                            </TaskContent>
                        </StyledListGroupItem>
                    ))}
                </ListGroup>
            </ListContainer>
            {bottomContent}
        </>
    );
};

export default TasksListLayout;
