import React from 'react';
import TopicsTable from '../components/TopicsTable';

const StudentTopicsView = ({
                               topics,
                               loading,
                               error,
                               darkMode,
                               actions
                           }) => {
    return (
        <div className="container mt-4">
            <TopicsTable
                topics={topics}
                loading={loading}
                error={error}
                darkMode={darkMode}
                userRole="student"
                actions={actions}
            />
        </div>
    );
};

export default StudentTopicsView;
