import { useState, useEffect } from 'react';
import usePost from './usePost';

const useAuth = () => {
    const { response, loading, error, postData } = usePost();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authError, setAuthError] = useState(null);  // Dodajemy osobny stan dla błędów autoryzacji

    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        setIsAuthenticated(!!token);
    }, []);

    const login = async (username, password) => {
        try {
            setAuthError(null);  // Czyścimy poprzednie błędy
            await postData('LOGIN', { username, password });
        } catch (err) {
            // Tutaj możemy odpowiednio sformatować komunikat błędu
            setAuthError(err.response?.data?.message || 'Invalid username or password');
            // Nie propagujemy błędu dalej
        }
    };

    const logout = async () => {
        try {
            const refreshToken = sessionStorage.getItem('refresh_token');
            await postData('logout', { refresh_token: refreshToken });
            sessionStorage.clear();
            setIsAuthenticated(false);
            setAuthError(null);
        } catch (err) {
            console.error('Logout error:', err);
            // Mimo błędu i tak czyścimy sesję
            sessionStorage.clear();
            setIsAuthenticated(false);
        }
    };

    useEffect(() => {
        if (response && response.access_token) {
            sessionStorage.setItem('access_token', response.access_token);
            sessionStorage.setItem('refresh_token', response.refresh_token);
            sessionStorage.setItem('username', response.username);
            sessionStorage.setItem('role', response.role);
            setIsAuthenticated(true);
            setAuthError(null);
        }
    }, [response]);

    return {
        login,
        logout,
        isAuthenticated,
        loading,
        error: authError || error  // Zwracamy albo błąd autoryzacji albo ogólny błąd
    };
};

export default useAuth;
