import React from 'react';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CourseInstanceForm = ({
                                show,
                                handleClose,
                                formData,
                                handleChange,
                                handleSubmit,
                                error,
                                loading,
                                darkMode,
                                courseName // nazwa z kursu jako placeholder/fallback
                            }) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            className={darkMode ? 'modal-dark' : ''}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('editCourseInstance')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('courseInstanceName')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name || ''}
                            onChange={handleChange}
                            placeholder={courseName}
                            className={darkMode ? 'bg-dark text-light' : ''}
                        />
                        <Form.Text className="text-muted">
                            {t('courseInstanceNameHint')}
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {t('save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CourseInstanceForm;
