import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../hooks';
import MenuView from './MenuView';
import TeacherSyncContainer from '../features/sync/TeacherSyncContainer';

const MenuContainer = () => {
    const { i18n } = useTranslation();
    const [timeLeft, setTimeLeft] = useState('');
    const navigate = useNavigate();
    const [showSyncModal, setShowSyncModal] = useState(false);

    const token = sessionStorage.getItem('access_token');
    const username = token ? jwtDecode(token)?.username : '';
    const role = token ? jwtDecode(token)?.role : '';

    //const { data: examStatus, error: examStatusError } = useFetch(
    //    role === 'student' ? 'STUDENT_ACTIVE_EXAM_STATUS' : null
    //);

    const handleTokenExpiration = useCallback(() => {
        sessionStorage.removeItem('access_token');
        setTimeLeft('Token expired');
        window.location.href = '/';
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            const token = sessionStorage.getItem('access_token');
            if (token) {
                try {
                    const decoded = jwtDecode(token);
                    const currentTime = Date.now() / 1000;

                    if (decoded.exp <= currentTime) {
                        handleTokenExpiration();
                        return;
                    }

                    const timeRemaining = Math.floor(decoded.exp - currentTime);
                    const minutes = Math.floor(timeRemaining / 60);
                    const seconds = timeRemaining % 60;
                    setTimeLeft(`${minutes} min ${seconds} sec`);
                } catch (err) {
                    console.error('Error decoding token:', err);
                    handleTokenExpiration();
                }
            } else {
                setTimeLeft('No token');
                window.location.href = '/';
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [handleTokenExpiration]);

    const navigateTo = useCallback((path) => {
        if (!token) {
            handleTokenExpiration();
            return;
        }

        // Przekierowanie na podstawie roli
        if (path === '/student/courses') {
            if (role === 'admin') {
                navigate('/admin/courses');
            } else if (role === 'teacher') {
                navigate('/teacher/courses');
            } else {
                navigate(path);
            }
            return;
        }

        navigate(path);
    }, [token, navigate, handleTokenExpiration, role]);

    const handleLogout = useCallback(() => {
        sessionStorage.removeItem('access_token');
        window.location.href = '/';
    }, []);

    const changeLanguage = useCallback((lng) => {
        i18n.changeLanguage(lng);
    }, [i18n]);

    useEffect(() => {
        if (!token) {
            window.location.href = '/';
        }
    }, [token]);

    if (!token) {
        return null;
    }

    return (
        <>
            <MenuView
                username={username}
                role={role}
                timeLeft={timeLeft}
                shouldShowCourses={true}
                onNavigate={navigateTo}
                onLogout={handleLogout}
                onChangeLanguage={changeLanguage}
                onCheckChanges={() => setShowSyncModal(true)}
            />
            {role === 'teacher' && (
                <TeacherSyncContainer
                    show={showSyncModal}
                    onHide={() => setShowSyncModal(false)}
                />
            )}
        </>
    );
};

export default MenuContainer;
