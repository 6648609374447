import React from 'react';
import TasksListLayout from '../components/TasksListLayout';

const TeacherTasksList = ({
                              tasksList,
                              selectedTaskId,
                              onSelect
                          }) => {
    if (!tasksList) return null;

    return (
        <TasksListLayout
            items={tasksList}
            selectedId={selectedTaskId}
            onSelect={onSelect}
            loading={!Array.isArray(tasksList)}
            error={!Array.isArray(tasksList) ? 'Błąd pobierania listy zadań' : null}
        />
    );
};

export default TeacherTasksList;

