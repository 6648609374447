import React, { useState } from 'react';
import { useFetch, usePost } from '../../hooks';
import { useTheme } from '../../context/ThemeContext';
import TeacherSyncModal from './TeacherSyncModal';

const TeacherSyncContainer = ({ show, onHide }) => {
    const { darkMode } = useTheme();
    const [syncError, setSyncError] = useState(null);
    const [isSyncing, setIsSyncing] = useState(false);

    // Hook do pobierania zmian dla wszystkich kursów nauczyciela
    const {
        data: changesData,
        loading: changesLoading,
        error: changesError,
        fetchData: refreshChanges
    } = useFetch(
        show ? 'TEACHER_SYNC_CHECK_ALL_CHANGES' : null
    );

    // Hook do synchronizacji
    const { postData } = usePost();

    // Funkcja do synchronizacji zmian
    const handleSync = async (courseIds) => {
        setIsSyncing(true);
        setSyncError(null);

        try {
            await Promise.all(
                courseIds.map(courseId =>
                    postData('TEACHER_SYNC_APPLY', null, [courseId])
                )
            );
            await refreshChanges();
            onHide();
        } catch (error) {
            setSyncError('Błąd podczas synchronizacji zmian');
        } finally {
            setIsSyncing(false);
        }
    };

    return (
        <TeacherSyncModal
            show={show}
            onHide={onHide}
            courses={changesData || []}
            loading={changesLoading}
            error={changesError || syncError}
            onSync={handleSync}
            isSyncing={isSyncing}
            darkMode={darkMode}
        />
    );
};

export default TeacherSyncContainer;
