import React from 'react';
import styled from 'styled-components';

const ContentWrapper = styled.div`
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
`;

const TaskHeader = styled.div`
    margin-bottom: 1.5rem;
`;

const TaskTitle = styled.h4`
    margin-bottom: 0.5rem;
    color: var(--bs-heading-color);
`;

const TaskMeta = styled.div`
    font-size: 0.9rem;
    color: var(--bs-secondary);
    margin-bottom: 1rem;
`;

const TaskSection = styled.div`
    margin-bottom: 1.5rem;

    h5 {
        color: var(--bs-heading-color);
        margin-bottom: 0.75rem;
    }

    p {
        color: var(--bs-body-color);
        white-space: pre-wrap;
    }
`;

const TimerWrapper = styled.div`
    margin-bottom: 1rem;
`;

const TasksContentLayout = ({
                                title,
                                language,
                                description,
                                content,
                                form,
                                isEditing,
                                examTimer,  // Nowy prop dla timera
                                children
                            }) => {
    if (isEditing && form) {
        return form;
    }

    return (
        <ContentWrapper>
            {/* Timer dla egzaminów */}
            {examTimer && (
                <TimerWrapper>
                    {examTimer}
                </TimerWrapper>
            )}

            <TaskHeader>
                <TaskTitle>{title}</TaskTitle>
                {language && (
                    <TaskMeta>
                        <strong>Język:</strong> {language}
                    </TaskMeta>
                )}
            </TaskHeader>

            {description && (
                <TaskSection>
                    <h5>Opis:</h5>
                    <p>{description}</p>
                </TaskSection>
            )}

            {content && (
                <TaskSection>
                    <h5>Treść:</h5>
                    <p>{content}</p>
                </TaskSection>
            )}

            {children}
        </ContentWrapper>
    );
};

export default TasksContentLayout;
