import React from 'react';
import { Modal, Button, Spinner, Alert, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TaskModelModal = ({ show, handleClose, modelData, darkMode, loading, error }) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            className={darkMode ? 'modal-dark' : ''}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('topicTasks')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && (
                    <div className="text-center">
                        <Spinner animation="border" />
                    </div>
                )}
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                {!loading && !error && modelData && (
                    <Form.Control
                        as="textarea"
                        rows={20}
                        value={JSON.stringify(modelData, null, 2)}
                        readOnly
                        className={`font-monospace ${darkMode ? 'bg-dark text-light' : ''}`}
                        style={{
                            fontSize: '0.9rem',
                            whiteSpace: 'pre',
                            overflowWrap: 'normal',
                            overflowX: 'scroll',
                        }}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={darkMode ? "outline-light" : "secondary"} onClick={handleClose}>
                    {t('close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskModelModal;
