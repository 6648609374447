import React from 'react';
import { Card, Table, Alert, Spinner, Button, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TopicsTable = ({
                         topics = [],
                         actions = {},
                         userRole,
                         loading = false,
                         error = null,
                         darkMode = false
                     }) => {
    const { t } = useTranslation();

    if (loading) {
        return <Spinner animation="border" className="d-block mx-auto mt-4" />;
    }

    if (error) {
        return <Alert variant="danger" className="mt-4">{error}</Alert>;
    }

    const getExamRowStyle = (isExam) => {
        if (!isExam) return {};

        return darkMode ? {
            backgroundColor: 'rgba(44, 48, 52, 0.6)',
        } : {
            backgroundColor: 'rgba(248, 249, 250, 0.7)'
        };
    };

    const getVisibilityBadge = (topic) => {
        if (!topic.is_visible) {
            return (
                <Badge bg={darkMode ? "dark" : "secondary"} className="me-2">
                    {t('hidden')}
                </Badge>
            );
        }

        const now = new Date();
        const visibleFrom = topic.visible_from ? new Date(topic.visible_from) : null;
        const visibleUntil = topic.visible_until ? new Date(topic.visible_until) : null;

        if (visibleFrom && visibleFrom > now) {
            return (
                <Badge bg="warning" text="dark" className="me-2">
                    {t('notVisibleYet')}
                </Badge>
            );
        }

        if (visibleUntil && visibleUntil < now) {
            return (
                <Badge bg="secondary" className="me-2">
                    {t('noLongerVisible')}
                </Badge>
            );
        }

        return (
            <Badge bg="success" className="me-2">
                {t('visible')}
            </Badge>
        );
    };

    const renderActions = (topic) => {
        return (
            <div className="d-flex gap-2">
                <Button
                    size="sm"
                    variant={darkMode ? "outline-info" : "info"}
                    onClick={() => actions.onViewTasks?.(topic.id)}
                >
                    {t('tasks')}
                </Button>

                {userRole === 'admin' && (
                    <>
                        <Button
                            size="sm"
                            variant={darkMode ? "outline-light" : "secondary"}
                            onClick={() => actions.onEdit?.(topic)}
                        >
                            {t('edit')}
                        </Button>
                        <Button
                            size="sm"
                            variant={darkMode ? "outline-light" : "outline-primary"}
                            onClick={() => actions.onMoveUp?.(topic.id)}
                            disabled={!topic.prev_id}
                        >
                            ↑
                        </Button>
                        <Button
                            size="sm"
                            variant={darkMode ? "outline-light" : "outline-primary"}
                            onClick={() => actions.onMoveDown?.(topic.id)}
                            disabled={!topic.next_id}
                        >
                            ↓
                        </Button>
                        <Button
                            size="sm"
                            variant={darkMode ? "outline-info" : "info"}
                            onClick={() => {
                                actions.onViewModel?.(topic.id);
                            }}
                        >
                            {t('model')}
                        </Button>
                        <Button
                            size="sm"
                            variant={darkMode ? "outline-danger" : "danger"}
                            onClick={() => actions.onDelete?.(topic.id)}
                        >
                            {t('delete')}
                        </Button>
                    </>
                )}

                {userRole === 'teacher' && (
                    <Button
                        size="sm"
                        variant={darkMode ? "outline-light" : "secondary"}
                        onClick={() => actions.onEdit?.(topic)}
                    >
                        {t('edit')}
                    </Button>
                )}
            </div>
        );
    };

    return (
        <Card className={darkMode ? 'bg-dark text-light' : ''}>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <h5>{t('topics')}</h5>
                <div className="d-flex gap-2">
                    {userRole === 'admin' && (
                        <Button
                            variant={darkMode ? "outline-primary" : "primary"}
                            onClick={actions.onAdd}
                        >
                            {t('addTopic')}
                        </Button>
                    )}
                    <Button
                        variant={darkMode ? "outline-light" : "secondary"}
                        onClick={actions.onBackToCourses}
                    >
                        {t('backToCourses')}
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <div style={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}>
                    <Table responsive bordered className={darkMode ? 'table-dark' : ''}>
                        <thead style={{ position: 'sticky', top: 0, backgroundColor: darkMode ? 'var(--bs-dark)' : 'var(--bs-table-bg)' }}>
                        <tr>
                            <th>{t('Lp')}</th>
                            <th>{t('name')}</th>
                            <th>{t('description')}</th>
                            <th>{t('language')}</th>
                            {userRole !== 'student' && (
                                <>
                                    <th>{t('type')}</th>
                                    <th>{t('visibility')}</th>
                                </>
                            )}
                            <th style={{ width: userRole === 'admin' ? '400px' : '200px' }}>
                                {t('actions')}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {topics.map((topic, index) => (
                            <tr
                                key={topic.id}
                                style={getExamRowStyle(topic.topic_type === 'exam')}
                            >
                                <td>{index + 1}</td>
                                <td>{topic.name}</td>
                                <td>{topic.description}</td>
                                <td>{topic.language}</td>
                                {userRole !== 'student' && (
                                    <>
                                        <td>
                                            <Badge
                                                bg={topic.topic_type === 'exam' ? 'warning' : 'info'}
                                                text={topic.topic_type === 'exam' ? 'dark' : undefined}
                                            >
                                                {topic.topic_type === 'exam' ? t('exam') : t('topic')}
                                            </Badge>
                                        </td>
                                        <td>{getVisibilityBadge(topic)}</td>
                                    </>
                                )}
                                <td>{renderActions(topic)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
};

export default TopicsTable;
